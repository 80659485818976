import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../store/actions/authActions";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const useStyles = makeStyles((theme) => ({
  typo: {
    fontFamily: "Porter Sans Block",
    color: "#3CAFE4",
    fontSize: "96px",
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "normal",
    textAlign: "center",
  },
  card: {
    width: "550px",
      border: '5px solid #fff',
      borderRadius: '16px',
      color: '#fff',
      boxShadow: '0 0 50px rgba(0, 0, 0, 0.5)',
      backgroundImage: 'url("/assets/images/bgLogin.png")',
      backgroundPosition: 'center', // Center the background image
      // display: 'flex',
      padding:"15px",
      justifyContent: 'center', 
      alignItems: 'center', // Center content vertically and horizontally
    },
  content: {
    padding: '16px',
    background: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '0 0 16px 16px',
    width: '100%',
    boxSizing: 'border-box',
  },
  input: {
    border: "1px solid #fff",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
    borderRadius: "10px",
    backgroundColor: "#000",
    color: "#fff",
    "& .MuiInputBase-root": {
      backgroundColor: "#000",
      borderRadius: "10px",
      color: "#fff",
    },
    "& fieldset": {
      border: "none",
    },
  },
  label:{
    mb: 1,
    fontSize: "14px",
    fontWeight: 700,
    color: "#fff",
    textShadow: "5px 5px 10px #000",
    marginBlock:'10px'
  },
  btn: {
    height:"40px",
    // width:"230px",
    fontWeight:'700',
    borderRadius: "10px",
    marginTop: "10px",
    marginRight: "20px",
    color: "#000",
    fontVariant: "small-caps",
    background: "#E3A700",
    border: "2px solid #fff",
    fontSize: "14px",
    boxShadow: "5px 5px 10px #000",
    fontVariant: "small-caps",
    marginBottom: "10px",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#E3A700",
      cursor: "pointer",
    },
  },
  forgot: {
    color: "#FFBC00",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    textDecorationLine: "underline",
  },
}));
const initialValues = {
  email: "",
  password: "",
};
const Login = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const [error, setError] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handlePasswordVisibilityToggle = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError(false);
    setMessage("");

    try {
      const response = await dispatch(login(formValues));
      // console.log(response)
      if (response.data.status === 200) {
        navigate("/dashboard/dash", { replace: true });
      } else {
        setError(true);
        setMessage(response.data.data.message);
      }
    } catch (error) {
      setError(true);
      setMessage(error.message);
    }
  };
  return (
    <Card className={classes.card} >
    <CardContent>
      <form onSubmit={handleSubmit}>
      <Box sx={{display:'flex',justifyContent:'center' ,marginLeft:'50px'}}>
      <Typography  sx={{
              fontSize: "30.92px",
              marginBottom:'-40px',
              textAlign: "center" ,
              fontWeight: "600",
              marginRight:'-165px',
              fontFamily: "Nunito Sans",
              color: "#FAFF00",
              textShadow:
                "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
            }}>KUDU</Typography>  
        <img  src="/assets/images/logo1.png"  height="288.62px"
            width="201px" />
       </Box>
        <Box sx={{ mt: 2 }}>
          <Box>
            <Typography
             className={classes.label}
            >
              {" "}
              Email address
            </Typography>
            <TextField
              fullWidth
              required
              type="email"
              className={classes.input}
              placeholder="Enter Your Email"
              autoComplete="off"
              name="email"
              value={formValues.email}
              onChange={handleChange}
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            <Typography
             className={classes.label}
            >
              {" "}
              Password
            </Typography>
            <TextField
              fullWidth
              required
              type={showPassword ? "text" : "password"}
              className={classes.input}
              placeholder="Enter Your Password"
              autoComplete="off"
              name="password"
              value={formValues.password}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityIcon sx={{ color: "#fff" }} />
                      ) : (
                        <VisibilityOffIcon sx={{ color: "#fff" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box>
              <FormControlLabel
              sx={{m:1,mt:2}}
                control={
                  <Checkbox
                    // checked={showPassword}
                    // onChange={handlePasswordVisibilityToggle}
                  />
                }
                label="Remember Password"
              />
            </Box>

            {error && <Alert severity="error">{message}</Alert>}
          </Box>
        </Box>
        <Box sx={{ mt: 1, mb: 1,mx: 10  }}>
          <Button
            variant="contained"
            fullWidth
            className={classes.btn}
            // component={Link}
            // to="/dashboard/users"
            type="submit"
          >
            Log In
          </Button>
        </Box>
      </form>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          className={classes.forgot}
          component={Link}
          to="/forgot-password"
        >
          {" "}
          Forgotten password?
        </Typography>
        <Typography
          component={Link}
          to="/sign-up"
          sx={{
            ml: 0.5,
            color: "#FFBC00",
            fontWeight: 800,
            fontSize: "15px",
            textDecoration: "none",
          }}
        >
          {" "}
          Create Account
        </Typography>
      </Box>
    </CardContent>
  </Card>
  );
};

export default Login;
