import React, { useState } from "react";
import {
  Box,
  Button,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Alert,
  Grid,
  TextareaAutosize,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import RatingInput from "../../../components/Rating/RatingInput";
import FileViewerComponent from "../../../components/FileViewer/FileViewerComponent";
// import { Document, Page } from 'react-pdf'
import MyPdfViewer from "./MyPdfViewer";
import Snack from "../../../components/snackbar/Snack";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";

const useStyles = makeStyles((theme) => ({
  input: {
    border: "1px solid yellow",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
    borderRadius: "10px",
    "& .MuiInputBase-root": {
      backgroundColor: "#161A24",
      borderRadius: "10px",
      color: "#fff",
    },
    "& fieldset": {
      border: "none",
    },
    "& .MuiSvgIcon-root": {
      color: "#fff",
    }
  },
  backbtn: {
    borderRadius: "10px",
    fontSize: "14px",
    color: "#E3A700",
    height: "40px",
    marginTop: "10px",
    fontVariant: "small-caps",
    background: "#404040",
    border: "2px solid #fff",
    marginRight: "20px",
    boxShadow: "5px 5px 10px #000",
    width: "230px",
    fontWeight: "700",
    "&:hover": {
      backgroundColor: "#404040",
      cursor: "pointer",
    },
  },
  btn: {
    height: "40px",
    width: "230px",
    fontWeight: "700",
    borderRadius: "10px",
    marginTop: "10px",
    marginRight: "20px",
    color: "#000",
    fontVariant: "small-caps",
    background: "#E3A700",
    border: "2px solid #fff",
    fontSize: "14px",
    boxShadow: "5px 5px 10px #000",
    fontVariant: "small-caps",
    marginBottom: "10px",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#E3A700",
      cursor: "pointer",
    },
  },
  //  label: {
  //     fontSize: "14px",
  //     fontWeight: 600,
  //     mb: 1,
  //     color:"#fff",
  //     marginTop:"10px"
  //   }
  label: {
    marginTop: "10px",
    fontSize: "14px",
    fontWeight: "bold",
    color: "white",
    paddingBottom: "5px",
    textShadow: "5px 5px 10px #000",
  },
}));

const UpdateContract = (props) => {
  console.log("satate", props);
  const { state } = props;
  // const {state} = useLocation();
  console.log(state);
  const navigate = useNavigate();
  const goBack = () => {
    props.closeDialog(false);
  };
  const camp = [{ val: "CCA" }, { val: "CP" }, { val: "PA" }, { val: "REF" }];
  const [client, setClient] = useState(state.client || "");
  const [signedDate, setSignedDate] = useState(
    moment(state.signedDate).format("YYYY-MM-DD") || ""
  );
  const [startDate, setStartDate] = useState(
    moment(state.startDate).format("YYYY-MM-DD") || ""
  );
  const [endDate, setEndDate] = useState(
    moment(state.endDate).format("YYYY-MM-DD") || ""
  );
  const [services, setServices] = useState(state.services || "");
  const [compaign, setCompaign] = useState(state.campaign || "");
  const [monthlySpend, setMonthlySpend] = useState(state.monthlySpend || "");

  const [message, setMessage] = useState("");

  const [notifyOpen, setNotifyOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  const handleClosePop = () => {
    setNotifyOpen(false);
  };

  const [file, setFile] = useState(
    process.env.REACT_APP_URL + (state.fileUrl || "")
  );
  // //////
  console.log(" (state.fileUrl || ",process.env.REACT_APP_URL + state.fileUrl)

  const inputDateString = "2023-10-07T00:00:00.000Z";
  const date = new Date(inputDateString);

  const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 to the month because January is 0
  const day = String(date.getDate()).padStart(2, "0");
  const year = String(date.getFullYear()).slice(-2); // Get the last two digits of the year

  const formattedDate = `${month}-${day}-${year}`;

  /////////

  const handleClientChange = (event) => {
    setClient(event.target.value);
  };
  const handleSignedDateChange = (event) => {
    setSignedDate(event.target.value);
  };
  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };
  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };
  const handleServicesChange = (event) => {
    setServices(event.target.value);
  };
  const handleCompaignChange = (event) => {
    setCompaign(event.target.value);
  };
  const handleMonthlySpendChange = (event) => {
    if (event.target.value >= 0) {
      setMonthlySpend(event.target.value);
    }
  };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileType = selectedFile.type;
      // Check if the selected file type is either PDF or Word document
      if (fileType === "application/pdf" || fileType === "application/msword" || fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
        setFile(selectedFile);
      } else {
        // Show error message
        setNotifyOpen(true);
        setMessage("Please select a PDF  or Word document file.");
        setSeverity("error");
        event.target.value = null;
      }
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Client :", client);
    console.log("Signed Date :", signedDate);
    console.log("Start Date :", startDate);
    console.log("End Date :", endDate);
    console.log("Services :", services);
    console.log("Compaign :", compaign);
    console.log("MonthlySpend :", monthlySpend);

    const body = {
      client,
      signedDate,
      startDate,
      endDate,
      services,
      campaign: compaign,
      monthlySpend,
      file,
    };

    console.log(body);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_URL}contracts/${state._id}`,
        body,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        setSeverity("success");
        setMessage("Contract updated successfully");
        console.log("Contract updated successfully");
        setNotifyOpen(true);
        setTimeout(() => {
          props.closeDialog(false);
        }, 3000);        // props.closeDialog(false);
      } else {
        setSeverity("error");
        setMessage("Failed to Update contract");
        setNotifyOpen(true);
        console.error("Failed to Update contract");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const classes = useStyles();
  return (
    <div style={{ marginTop: "-40px" }}>
     
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 2 }}>
      
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <div
            style={{
              width: "495px",
              height: "660px",
              border: "3px solid #fff",

              borderRadius: "0px",
              marginTop: "40px",
            }}
          >
            {file !== null ? (
              <MyPdfViewer pdf={file} /> // Render MyPdfViewer with the selected file
            ) : (
              <p>Please select a PDF file to view.</p> // Render a message if no file is selected
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
        <form onSubmit={handleSubmit}>
          <Box sx={{ mt: 5 }}></Box>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 2 }}>
            <Grid item xs={12} md={12} lg={12}>
              <Box>
                <Typography className={classes.label}>Client</Typography>
                <TextField
                  fullWidth
                  className={classes.input}
                  name="Client"
                  placeholder="Client"
                  value={client}
                  onChange={handleClientChange}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 2 }}>
            <Grid item xs={12} md={6} lg={4}>
              <Box>
                <Typography className={classes.label}>
                  Send & Signed Date
                </Typography>
                <TextField
                  fullWidth
                  type="date"
                  value={signedDate}
                  className={classes.input}
                  name="SignedDate"
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      colorScheme: "dark", // Corrected property name and value
                    },
                  }}
                  onChange={handleSignedDateChange}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Box>
                <Typography className={classes.label}>Start Date</Typography>
                <TextField
                  fullWidth
                  type="date"
                  className={classes.input}
                  name="EndDate"
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      colorScheme: "dark", // Corrected property name and value
                    },
                  }}
                  value={startDate}
                  onChange={handleStartDateChange}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={4}>
              <Box>
                <Typography className={classes.label}>End Date</Typography>
                <TextField
                  fullWidth
                  type="date"
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      colorScheme: "dark", // Corrected property name and value
                    },
                  }}
                  className={classes.input}
                  name="EndDate"
                  value={endDate}
                  onChange={handleEndDateChange}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 2 }}>
            <Grid item xs={12} md={6} lg={4}>
              <Box>
                <Typography className={classes.label}>Services</Typography>
                <TextField
                  fullWidth
                  className={classes.input}
                  name="Services"
                  placeholder="Services"
                  value={services}
                  onChange={handleServicesChange}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Box>
                <Typography className={classes.label}>Compaign</Typography>
                <FormControl className={classes.input} fullWidth>
                  <Select
                    id="demo-simple-select"
                    value={compaign}
                    name="Compaign"
                    required
                    placeholder="Compaign"
                    onChange={handleCompaignChange}
                  >
                    {camp.map((categoryItem) => (
                      <MenuItem key={categoryItem.val} value={categoryItem.val}>
                        {categoryItem.val}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={4}>

                <Box sx={{ position: "relative", width: "100%" }}>
                <Typography className={classes.label}>
                  {" "}
                  Monthly Spend ($)
                </Typography>
                <TextField
                  fullWidth
                  type="number"
                  className={classes.input}
                  name="MonthlySpend"
        

                  sx={{
                    "& .MuiInputBase-root": {paddingLeft:"15px"}
                  }}
                  value={monthlySpend}
                  onChange={handleMonthlySpendChange}
                />
                  <Box sx={{ position: "absolute", bottom: 18, left: 15 }}>
                    <Typography color={"#fff"}>$</Typography>
                  </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 2 }}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography className={classes.label}> Upload</Typography>
              <div>
                {/* <Button className={classes.btn3}>
                                      Choose File to Upload
                                  </Button> */}
                <TextField
                  fullWidth
                  // required
                  className={classes.input}
                  multiple={false}
                  type="file"
                  onChange={handleFileChange}
                />
              </div>
            </Grid>
          </Grid>

          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              variant="outlined"
              className={classes.backbtn}
              onClick={goBack}
            >
              Back{" "}
            </Button>
            <Button
              variant="contained"
              type="submit"
              className={classes.btn}
              sx={{ ml: 2 }}
              // onClick={handleSubmit}
            >
              Update Contract
            </Button>
          </Box>
          </form>
        </Grid>
      
      </Grid>

      <Snack
        open={notifyOpen}
        message={message}
        severity={severity}
        handleClose={handleClosePop}
      />
    </div>
  );
};

export default UpdateContract;
