import {
    Avatar, Box, Stack, Table,
    styled, TableBody, TableCell, TableHead,
    TableRow, Typography, TableContainer, Button
} from '@mui/material'
import { grey } from '@mui/material/colors'
import { makeStyles } from '@mui/styles'
import React, { useEffect } from 'react'
import Gradients from '../../../utils/Gradients'
import axios from 'axios';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
const useStyles = makeStyles((theme) => ({
    typo1: {
        color: '#9A9A9A',
        fontWeight: 600,
        fontSize: '14px'
    },
    typo2: {
        color: '#238FC1',
        fontWeight: 700,
        fontSize: '14px'
    },
    head: {
        fontSize: '14px',
        fontWeight: 800,
        color: '#fff',
        fontStyle: 'normal',
        lineHeight: 'normal'
    },
    cell: {
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: 'normal'
    }, box: {
        borderRadius: '12px',
        border: '0.3px solid rgba(32,138,187,0.25)',
        background: '#E6F6FE',
        boxShadow: ' 0px 14px 25px 0px rgba(141, 112, 112, 0.12)',
    },
    btn: {
        textTransform: 'none',
        borderRadius: '12px',
        border: '1px solid #52A5CF',
        background: '#FFF',
        width: '190px',
        fontSize: '1.2rem',
        height: '56px',
        marginRight: '5px',
        boxShadow: '0px 5px 4px 0px rgba(244, 244, 244, 0.25) inset, 0px -2px 4px 0px rgba(0, 0, 0, 0.25) inset',
    },
    btn2: {
        textTransform: 'none',
        borderRadius: '12px',
        fontSize: '1.2rem',
        width: '190px',
        height: '56px',
        marginRight: '5px',
        boxShadow: '0px 5px 4px 0px rgba(244, 244, 244, 0.25) inset, 0px -2px 4px 0px rgba(0, 0, 0, 0.25) inset',
    },
    btn3: {
        textTransform: 'none',
        borderRadius: '12px',
        fontSize: '1.2rem',
        width: '190px',
        height: '56px',
        marginRight: '5px',
        boxShadow: '0px 5px 4px 0px rgba(244, 244, 244, 0.25) inset, 0px -2px 4px 0px rgba(0, 0, 0, 0.25) inset',
    },
    avatar: {
        cursor: 'pointer', // Change cursor to pointer for the avatar
        transition: 'transform 0.3s ease',
        '&:hover': {
            transform: 'scale(1.2)',
        },
    },
}))
const tableData = [
    { avtar: '/assets/images/1.png', name: 'john Doe', title: 'founder', email: 'abc@gmail.com' },
    { avtar: '/assets/images/2.png', name: 'john Doe', title: 'founder', email: 'abc@gmail.com' },
    { avtar: '/assets/images/3.png', name: 'john Doe', title: 'founder', email: 'abc@gmail.com' },

]
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    borderRadius: '20px',
    border: '0.3px solid rgba(32,138,187,0.25)',
    overflow: 'hidden',
}));

const ContactDetails = () => {
    const navigate = useNavigate()
    const classes = useStyles()
    const { state } = useLocation();
    console.log(state)


    const handleDeleteClick = async () => {
        // Handle the delete logic here
        console.log('Delete button clicked', state._id);
        const res = await axios.delete(`${process.env.REACT_APP_URL}companies/delete/${state._id}`)
        console.log(res)
        navigate('/dashboard/contacts')
        // You can perform your delete operation or show a confirmation dialog here
    };

    const goToCRM = async () => {
        const res = await axios.get(`${process.env.REACT_APP_URL}companies/firstContact/${state._id}`)
        console.log(res.data)
        navigate('/dashboard/company-crm', { state: res.data.data })
    }
    const goToUpdateDetail = async (id) => {
        console.log('update contacts')
        const res = await axios.get(`${process.env.REACT_APP_URL}companies/Read/${state._id}`);
        navigate(`/dashboard/update-contact/${id}`, { state: res.data.data })
    }
    const goBack = () => {
        navigate('/dashboard/contacts')
    }
    console.log('ContactDetails state:', state);
    return (
        <div>
            <Box className={classes.box} sx={{
                minHeight: '100vh',
                p: 8
            }}>
                <Stack direction='row' spacing={5}>
                    <Box>
                        <Typography className={classes.typo1}> Company Name</Typography>
                        <Typography className={classes.typo2}> {state?.companyName}</Typography>
                    </Box>
                    <Box>
                        <Typography className={classes.typo1}> Category</Typography>
                        <Typography className={classes.typo2}>{state?.industry}</Typography>
                    </Box>
                    <Box>
                        <Typography className={classes.typo1}> Phone Number</Typography>
                        <Typography className={classes.typo2}>{state?.contact}</Typography>
                    </Box>

                    <Box>
                        <Typography className={classes.typo1}> Website Link</Typography>
                        <Typography className={classes.typo2}> {state?.websiteLink}</Typography>
                    </Box>
                    <Box>
                        <Typography className={classes.typo1}> Social Link</Typography>
                        <Typography className={classes.typo2}> {state?.instagramLink}</Typography>
                        {/*<Box sx={{ display: 'flex' }}>
                            <Avatar src="/assets/images/IG.png"
                                sx={{ height: '30px', width: '30px', mr: 2 }}
                            />

        </Box>*/}
                    </Box>
                    <Box>
                        <Avatar src='/assets/images/delete.png' className={classes.avatar} onClick={handleDeleteClick} />
                    </Box>
                </Stack>
                <Typography variant='h6' sx={{ fontWeight: 'bold', mt: 5 }}> Company Contacts </Typography>
                <Box>
                    <StyledTableContainer>

                        <Table >
                            <TableHead>
                                <TableRow sx={{ background: Gradients.cAp }}>
                                    <TableCell className={classes.head}> Name </TableCell>
                                    <TableCell className={classes.head}> Title </TableCell>
                                    <TableCell className={classes.head}> Email </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{ background: '#fff' }}>
                                {state?.contacts.map((contact, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell className={classes.cell} sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Avatar src="/assets/images/3.png" sx={{ mr: 2 }} />
                                                {contact.name}
                                            </TableCell>
                                            <TableCell className={classes.cell}>{contact?.title}</TableCell>
                                            <TableCell className={classes.cell}>{contact?.email}</TableCell>
                                        </TableRow>
                                    );
                                })}

                            </TableBody>
                        </Table>
                    </StyledTableContainer>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3, p: 2 }}>
                    <Typography sx={{ color: '#202224', fontWeight: 600, opacity: 0.6 }}>
                        Showing 3-3 of 1
                    </Typography>
                    <Box sx={{ display: 'flex', mt: -2 }}>
                        <Avatar src="/assets/images/b.png" sx={{ height: '95px', width: '95px', mr: -3 }} />
                        <Avatar src="/assets/images/f.png" sx={{ height: '95px', width: '95px' }} />

                    </Box>
                </Box>
                <Box sx={{ float: 'right', }}>
                    <Button className={classes.btn}
                        startIcon={<ArrowBackIcon />}
                        onClick={goBack}
                    > Back</Button>
                    <Button
                        className={classes.btn2}
                        variant='outlined'
                        onClick={() => goToUpdateDetail(state?._id)}
                    >
                        Update Details
                    </Button>

                    {/*  */}
                    <Button className={classes.btn3} variant='contained'
                        onClick={goToCRM}
                        sx={{ background: Gradients.cAp }}
                    > Go to CRM </Button>
                </Box>
            </Box>

        </div>
    )
}

export default ContactDetails
