import React, { useEffect } from "react";
import {
  Box,
  Button,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import moment from 'moment';
import { useNavigate } from "react-router";
import { Label } from "@mui/icons-material";
import Snack from "../../../components/snackbar/Snack";
const useStyles = makeStyles((theme) => ({
  input: {
    border: "1px solid yellow",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
    borderRadius: "10px",
    marginTop: "6px",
    "& .MuiInputBase-root": {
      backgroundColor: "#161A24",
      borderRadius: "10px",
      color: "#fff",
    },
    "& fieldset": {
      border: "none",
    },
    "& .MuiSvgIcon-root": {
      color: "#fff",
    }
  },
  backbtn: {
    borderRadius: "10px",
    fontSize: "14px",
    color: "#E3A700",
    height: "40px",
    marginTop: "10px",
    fontVariant: "small-caps",
    background: "#404040",
    border: "2px solid #fff",
    marginRight: "20px",
    boxShadow: "5px 5px 10px #000",
    width: "230px",
    fontWeight: "700",
    "&:hover": {
      backgroundColor: "#404040",
      cursor: "pointer",
    },
  },
  btn: {
    height: "40px",
    width: "230px",
    fontWeight: "700",
    borderRadius: "10px",
    marginTop: "10px",
    marginRight: "20px",
    color: "#000",
    fontVariant: "small-caps",
    background: "#E3A700",
    border: "2px solid #fff",
    fontSize: "14px",
    boxShadow: "5px 5px 10px #000",
    fontVariant: "small-caps",
    marginBottom: "10px",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#E3A700",
      cursor: "pointer",
    },
  },
  disp: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "flex-end",
  },
  label: {
    fontSize: "14px",
    fontWeight: "bold",
    // marginBottom:"10px",
    color: "white",
    paddingBottom: "5px",
    textShadow: "5px 5px 10px #000",
  },
}));

const CreateCompany = (props) => {
  console.log("date", props.state);

  const initialValues = {
    companyName: props.state?.companyName,
    category: props.state?.industry,
    lastContacted:  moment(props.state?.lastContacted).format('YYYY-MM-DD'),
  };
  const classes = useStyles();
  const [formValues, setFormValues] = React.useState(initialValues);
  const [data, setData] = React.useState([]);
  const [error, setError] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const [response, setResponse] = React.useState("");
  const [severity, setSeverity] = React.useState("");
  const [notifyOpen, setNotifyOpen] = React.useState(false);
  const handleClosePop = () => {
    setNotifyOpen(false);
  };
  const getData = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_URL}companies/dropdown`
    );

    setData(res.data.data);
  };
  useEffect(() => {
    getData();
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (Object.values(formValues).some((value) => value.trim() === "")) {
    //   setError(true);
    //   setMessage("Please fill all the data fields.");
    //   return;
    // }
    const body = formValues;
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_URL}companies/update/${props.state?._id}`,body
      );
      if(res.data.status === "success"){
        setFormValues({ ...formValues, companyName:'',
          category:'',
          lastContacted:""});
        setResponse(res.data.message);
        setSeverity("success");
        setNotifyOpen(true);
        setTimeout(() => {
          props.closeDialog(false);
        }, 3000);
      }else{
        setResponse(res.data.message);
        setSeverity("error");
        setNotifyOpen(true);
      }
      // goBack()
    } catch (error) {
      console.error("Error fetching users data:", error);
    }
    console.log(body);
  };
  const goBack = () => {

    console.log("data close")
    
    props.closeDialog(false);
  };

  return (
    <div style={{ padding: "20px", display: "flex", justifyContent: "center" }}>
      <Box>
        <form onSubmit={handleSubmit}>
          <Box sx={{ display: "flex" }}>
            <Box>
              <label className={classes.label}>Company Name</label>
              <TextField required
                fullWidth
                name="companyName"
                value={formValues.companyName}
                onChange={handleChange}
                // required={true}
                placeholder="Type Here"
                className={classes.input}
              />
            </Box>

            <Box sx={{ mx: 3, display: "flex", flexDirection: "column" }}>
              <label className={classes.label}>Category</label>
              <FormControl
                fullWidth
                sx={{ width: "350px" }}
                className={classes.input}
              >
                <Select
                  sx={{ width: "350px" }}
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // label="Industry"
                  name="category"
                  value={formValues.category}
                  onChange={handleChange}
                >
                  {data.map((item, ind) => (
                    <MenuItem key={ind} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box>
              <label className={classes.label}>Last Contacted</label>
              <TextField required
                fullWidth
                type="date"
                className={classes.input}
                sx={{
                  "& .MuiOutlinedInput-input": {
                    colorScheme: "dark", // Corrected property name and value
                  },
                }}
                name="lastContacted"
                value={formValues.lastContacted}
                onChange={handleChange}
              />
            </Box>

            {/* {error && (
              <Alert severity="error">{message}</Alert>
            )} */}
            {error && <Alert severity="error">{message}</Alert>}
          </Box>
          <div className={classes.disp}>
            <Button
              variant="outlined"
              className={classes.backbtn}
              onClick={goBack}
            >
              Back
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit}
              className={classes.btn}
            >
              {" "}
              Update Company{" "}
            </Button>
          </div>
        </form>
        <Snack
        open={notifyOpen}
        message={response}
        severity={severity}
        handleClose={handleClosePop}
      />
      </Box>
    </div>
  );
};

export default CreateCompany;
