import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Typography,
  TableCell,
  TableRow,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  IconButton,
  TextField,
  InputLabel,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Card,
  OutlinedInput,
  Grid,
  CardContent,
  Pagination,
} from "@mui/material";
import { styled } from "@mui/system";
import { Edit, Delete, Label } from "@mui/icons-material";
import Gradients from "../../utils/Gradients";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FormControl, Select, MenuItem } from "@mui/material";
import Snack from "../../components/snackbar/Snack";
import Retainer from "./Retainer";
import AddSheet from "./components/AddSheet";
const TableHeader = styled(TableHead)(({ theme }) => ({
  "& th": {
    marginButtom: "10px",
    borderBottom: "0px",
    border: "none",
    "&:first-child": {
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
    },
    "&:last-child": {
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "10px",
    },
  },
}));
const categoryMonthData = [
  { val: "January", name: "January" },
  { val: "February", name: "February" },
  { val: "March", name: "March" },
  { val: "April", name: "April" },
  { val: "May", name: "May" },
  { val: "June", name: "June" },
  { val: "July", name: "July" },
  { val: "August", name: "August" },
  { val: "September", name: "September" },
  { val: "October", name: "October" },
  { val: "November", name: "November" },
  { val: "December", name: "December" },
];

const categoryWeekData = [
  { val: "1st week", name: "1st week" },
  { val: "2nd week", name: "2nd week" },
  { val: "3rd week", name: "3rd week" },
  { val: "4th week", name: "4th week" },
];
const useStyles = makeStyles((theme) => ({
  evenRow: {
    color: "#ffffff",
    backgroundColor: "#787676",
    "&:hover": {
      transform: "scale(1.01)", 
      boxShadow: " 0 0 10px rgba(0, 0, 0, 0.5)", // Add shadow on hover
      backgroundColor: "#6B6B6B",

    },
  },
  oddRow: {
    // backgroundColor: "#6B6B6B",
    backgroundColor: "#787676",
    borderRadius: "20px",
    color: "#ffffff",
    // Odd row color
    "&:hover": {
      transform: "scale(1.01)", 
      boxShadow: " 0 0 10px rgba(0, 0, 0, 0.5)", // Add shadow on hover
      backgroundColor: "#6B6B6B",

    },
  },
  tableCell: {
    color: "#ffffff",
  },
  row: {
    color: "#ffffff",
    "&:hover $cell": {
      color: "#ffffff",
    },
  },
  cell: {
    fontSize: "1.2rem",
    fontWeight: 500,
    letterSpacing: 1,
    color: "#FFF",
    border: "none",
  },

  card: {
    marginTop: "10px",
    border: "2px solid #fff",
    background: "#787676",
    borderRadius: "16px",
    color: "#fff",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
  },

  innercard: {
    border: "3px solid #FAFF00",
    background: " linear-gradient(#30344A, #9EA6DD,#34374D)",
    borderRadius: "16px",
    color: "#FAFF00",
    boxShadow: "0px 0px 15px #FAFF00, 0px 0px 15px #FAFF00 inset",


  },
  label: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "white",
    paddingBottom: "5px",
    textShadow: "5px 5px 10px #000",
  },

  Stextfield: {
    "& .MuiInputBase-root": {
      backgroundColor: "#161A24",
    },
    "& .MuiSelect-select": {
      paddingLeft: "12px", // Adjust padding as needed
    },
  },

  cardtextHeader: {
    color: "FAFF00",
    // textStroke: "1px solid black",
    textShadow:
      "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
    fontSize: "18px",
    fontWeight: "bolder",
    // textShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
  },
  cardtext: {
    color: "FAFF00",
    // textStroke: "1px solid black",
    textShadow:
      "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
    fontSize: "25px",
    // textShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
  },
  select: {
    border: "1px solid yellow",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
    borderRadius: "10px",
    textShadow: "5px 5px 10px #000",
    "& .MuiInputBase-root": {
      backgroundColor: "#161A24",
      borderRadius: "10px",
      color: "#fff",
    },
    "& fieldset": {
      border: "none",
    },
    "& .MuiSvgIcon-root": {
      color: "#fff",
    }
  },
  btn: {
    borderRadius: "10px",
    marginTop: "10px",
    marginRight: "20px",
    color: "#000",
    fontWeight: 'bold',
    fontVariant: "small-caps",
    background: "#E3A700",
    border: "1px solid #fff",
    fontSize: "14px",
    boxShadow: "5px 5px 10px #000",
    fontVariant: "small-caps",
    marginBottom: "10px",

    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#E3A700",
      cursor: "pointer",
    },
  },
  backbtn: {
    // width:"260px",
    borderRadius: "8px",
    fontSize: "1rem",
    color: "#E3A700",
    fontVariant: "small-caps",
    background: "#404040",
    fontWeight: "700",
    border: "2px solid #fff",
    marginRight: "20px",
    boxShadow: "5px 5px 10px #000",
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  // borderRadius: "10px",
  // border: "0.3px solid rgba(32, 138, 187, 0.25)",
  overflow: "hidden",
}));

const Sheets = () => {
  const form = {
    companyName: undefined,
    week: "1st week",
    month: "January",
  };
  const [formDatac, setFormDataC] = useState({
    companyName: [],
  });
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_URL}api/retainerinfo/${user.organization._id}`
      )
      .then((res) => {
        setFormDataC({
          ...formDatac,
          companyName: res.data.companies,
        });
        // setCompanyName(res.data.companies[0])
      });
    // setFormValue(...formValue, res.data.companies[0].companyName)
    // console.log(formDatac.companyName,'++++++++++++++')
  }, []);

  const navigate = useNavigate();
  const [isloading, setIsloading] = useState(true);
  const user = useSelector((state) => state.auth.user);
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openRetainerDialog, setOpenRetainerDialog] = useState(false);
  const [productName, setProductName] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [Check, setCheck] = useState("1");
  const [companyName, setCompanyName] = useState(undefined);
  const [week, setWeek] = useState("1st week");
  const [Month, setMonth] = useState("January");
  const handleDialogOpen = () => {
    setOpenDialog(true);
  };
  const handleRetainerDialogOpen = () => {
    setOpenRetainerDialog(true);
  };
  const [notifyOpen, setNotifyOpen] = React.useState(false);
  const handleClosePop = () => {
    setNotifyOpen(false);
  };
  const [formValue, setFormValue] = useState(form);
  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  const handleRetainerDialogClose = (data) => {
    setOpenRetainerDialog(data);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "week") {
      setWeek(value);
      handletable(companyName, value, Month);
    }
    if (name === "month") {
      setMonth(value);
      handletable(companyName, week, value);
    }
    if (name === "companyName") {
      setCompanyName(value);
      handletable(value, week, Month);
    }

    // setFormValue({ ...formValue, [name]: value });
    console.log("hit change function", name, value);
    console.log(formValue, "formValue");

    // handletable()
  };
  const handleProductNameChange = (event) => {
    setProductName(event.target.value);
  };

  const handleAddProduct = () => {

    const body = {
      userId: user.organization._id,
      productName: productName,
    };
    axios
      .post(`${process.env.REACT_APP_URL}api/retainers/product`, body)
      .then((res) => {

        setSuccess("Product added successfully!");
        setNotifyOpen(true);
        setProductName("")
      })
      .catch((error) => {
        setCheck("2")
        setNotifyOpen(true);
        setError("Failed to add Product. Please try again.");
      });
  };
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const itemsPerPage = 5;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const [weekfield, setWeekfield] = useState("");
  const [Monthfield, setMonthfield] = useState("");
  const [header, setHeader] = useState("");
  const handletable = (companyName, week, month) => {
    console.log("formValue.companyName", formValue.companyName);
    if (companyName === undefined) {
      // setNotifyOpen(true);
      setError("Select The Company Name field");
    } else {
      const body = {
        companyName: companyName,
        week: week,
        month: month,
      };
      axios
        .post(
          `${process.env.REACT_APP_URL}api/retainers/all/${user.organization._id}`,
          body
        )
        .then((response) => {
          // setData(response.data.dailySignedData);
          setHeader(response.data.headers);
          setWeekfield(response.data.weeklyStats);
          setMonthfield(response.data.monthlyStats);
          const totalUsers = response.data.dailySignedData.length;
          setTotalPages(Math.ceil(totalUsers / itemsPerPage));

          // Slice the data based on pagination
          const startIndex = (currentPage - 1) * itemsPerPage;
          const endIndex = startIndex + itemsPerPage;
          setData(response.data.dailySignedData.slice(startIndex, endIndex));

          setIsloading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  };
  useEffect(() => {
    handletable(companyName, week, Month);
  }, [currentPage]);

  const postData = async (data) => {
    // console.log(data)
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_URL}api/readsheet`,
        data
      );
      // console.log(res.data);
      navigate(`/dashboard/retainer`, { state: res.data });
    } catch (error) {
      console.error("Error fetching contacts data:", error);
      // Optionally, show an error message to the user
    }
  };
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  const handleEdit = (id) => {
    // Handle edit logic here, you can navigate to an edit page or show a modal form
    console.log(`Edit button clicked for ID: ${id}`);
  };

  const handleDelete = (id) => {
    // Handle delete logic here, you can show a confirmation dialog and delete the item if confirmed
    console.log(`Delete button clicked for ID: ${id}`);
  };
  return (
    <div style={{ height: "90vh", width: "100%", }}>
      <Card className={classes.card}>
        <CardContent>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 2 }}>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <InputLabel className={classes.label}>Company Name</InputLabel>
              <FormControl className={classes.select} fullWidth>
                <Select
                  id="demo-simple-select"
                  value={companyName}
                  name="companyName"
                  // required
                  // placeholder="Select an Item"
                  onChange={handleChange}
                  sx={{
                    "& .MuiInputBase-root": {
                      borderColor: "red", // Change border color to red
                    },
                    "& .MuiSelect-select": {
                      paddingLeft: "12px", // Adjust padding as needed
                    },
                  }}
                >
                  {formDatac.companyName.map((categoryItem) => (
                    <MenuItem
                      key={categoryItem._id}
                      value={categoryItem.companyName}
                    >
                      {categoryItem.companyName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={4}>
              <InputLabel className={classes.label} id="Week-simple-select">
                Week
              </InputLabel>
              <FormControl className={classes.select} fullWidth>
                <Select
                  id="Week-simple-select"
                  value={week}
                  name="week"
                  onChange={handleChange}
                >
                  {categoryWeekData.map((categoryItem) => (
                    <MenuItem key={categoryItem.val} value={categoryItem.name}>
                      {categoryItem.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <InputLabel className={classes.label} id="Week-simple-select">
                Month
              </InputLabel>
              <FormControl className={classes.select} fullWidth>
                <Select
                  id="Week-simple-select"
                  value={Month}
                  name="month"
                  // label="Week"
                  onChange={handleChange}
                >
                  {categoryMonthData.map((categoryItem) => (
                    <MenuItem key={categoryItem.val} value={categoryItem.name}>
                      {categoryItem.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid
            container
            rowSpacing={1}
            sx={{ marginTop: "10px" }}
            columnSpacing={{ xs: 1, md: 2 }}
          >
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Card className={classes.innercard}>
                <CardContent style={{ textAlign: "center" }}>
                  <Typography className={classes.cardtextHeader}>
                    Weekly Signed
                  </Typography>
                  <Typography className={classes.cardtext}>
                    {weekfield.length > 0 ? weekfield[0].totalSigned : "-"}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Card className={classes.innercard}>
                <CardContent style={{ textAlign: "center" }}>
                  <Typography className={classes.cardtextHeader}>
                    Monthly Signed
                  </Typography>
                  <Typography className={classes.cardtext}>
                    {Monthfield.length > 0 ? Monthfield[0].totalSigned : "-"}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Card className={classes.innercard}>
                <CardContent style={{ textAlign: "center" }}>
                  <Typography className={classes.cardtextHeader}>
                    Weekly DQ'ed
                  </Typography>
                  <Typography className={classes.cardtext}>
                    {weekfield.length > 0 ? weekfield[0].totalDQed : "-"}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Card className={classes.innercard}>
                <CardContent style={{ textAlign: "center" }}>
                  <Typography className={classes.cardtextHeader}>
                    Monthly DQ'ed
                  </Typography>
                  <Typography className={classes.cardtext}>
                    {Monthfield.length > 0 ? Monthfield[0].totalDQed : "-"}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <div style={{ display: "flex", alignItems: "center" }}>
        <Dialog
          fullWidth
          maxWidth="lg"
          PaperProps={{
            style: {
              background: " linear-gradient(#4F3B03, #E3A700,#503D06)",
              border: "3px solid #fff",
              borderRadius: "24px",
            },
          }}
          open={openRetainerDialog}
          onClose={handleRetainerDialogClose}
        >
          <DialogTitle
            sx={{
              color: "#FAFF00",
              textShadow:
                "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
            }}
          >
            Add Retainer
          </DialogTitle>
          <DialogContent>
            <AddSheet closeDialog={handleRetainerDialogClose} />
          </DialogContent>
        </Dialog>

        {/* Dialog for adding a product */}
        <Dialog
          PaperProps={{
            style: {
              background: " linear-gradient(#4F3B03, #E3A700,#503D06)",
              border: "3px solid #fff",
              borderRadius: "24px",
            },
          }}
          open={openDialog}
          onClose={handleDialogClose}
          fullWidth
        >
          <DialogTitle sx={{
            fontWeight: "bold", color: "#FAFF00",
            textShadow:
              "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
          }}>
            Add Product
          </DialogTitle>
          <DialogContent>
            <label style={{ color: "white", textShadow: "0px 5px 10px #fff" }}> Product Name</label>
            <TextField
              className={classes.select}
              autoFocus
              required
              margin="dense"
              id="productName"
              type="text"
              fullWidth
              value={productName}
              onChange={handleProductNameChange}
            />
          </DialogContent>
          <DialogActions>
            <Button className={classes.backbtn} onClick={handleDialogClose}>
              Cancel
            </Button>
            <Button
              className={classes.btn}
              onClick={handleAddProduct}
              color="primary"
            >
              Add Product
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {isloading ? (
        <div
          style={{ display: "flex", justifyContent: "center", margin: "30px" }}
        >
          {" "}
          Please select the Company Name{" "}
        </div>
      ) : data.length === 0 ? (
        <div
          style={{ display: "flex", justifyContent: "center", margin: "30px" }}
        >
          No data found
        </div>
      ) : (
        <div style={{ overflowX: 'auto' }}>
          <Card
            className={classes.card}
            style={{ marginTop: "20px", padding: "10px" }}
          >
            <StyledTableContainer>
              <Table>
                <TableHeader sx={{ background: Gradients.cApU }}>
                  <TableRow>
                    <TableCell style={{
                      color: "#fff", textShadow: "0px 5px 10px #fff", border: "none",
                      borderTopLeftRadius: "8px",
                      borderBottomLeftRadius: "8px",
                    }}>
                      {header[0].label}
                    </TableCell>
                    <TableCell style={{ color: "#fff", textShadow: "0px 5px 10px #fff", }}>
                      {header[1].label}
                    </TableCell>
                    <TableCell style={{ color: "#fff", textShadow: "0px 5px 10px #fff", }}>
                      {header[2].label}
                    </TableCell>
                    <TableCell style={{ color: "#fff", textShadow: "0px 5px 10px #fff", }}>
                      {header[3].label}
                    </TableCell>
                    <TableCell style={{ color: "#fff", textShadow: "0px 5px 10px #fff", }}>
                      {header[4].label}
                    </TableCell>
                    <TableCell style={{ color: "#fff", textShadow: "0px 5px 10px #fff", }}>
                      {header[5].label}
                    </TableCell>
                    <TableCell style={{ color: "#fff", textShadow: "0px 5px 10px #fff", }}>
                      {header[6].label}
                    </TableCell>
                    <TableCell style={{
                      color: "#fff", textShadow: "0px 5px 10px #fff", borderTopRightRadius: "8px",
                      borderBottomRightRadius: "8px",
                    }}>
                      {header[7].label}
                    </TableCell>
                  </TableRow>
                </TableHeader>
                <TableBody sx={{}}>
                  {data.map((row, index) => (
                    <TableRow
                      key={index}
                      className={
                        index % 2 === 0 ? classes.evenRow : classes.oddRow
                      }
                    >
                      <TableCell className={classes.cell} sx={{
                        // borderTopLeftRadius: "8px",
                        // borderBottomLeftRadius: "8px",
                      }}>
                        {row.typeOfTort}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {row.dailyValues[0]}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {row.dailyValues[1]}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {row.dailyValues[2]}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {row.dailyValues[3]}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {row.dailyValues[4]}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {row.dailyValues[5]}
                      </TableCell>
                      <TableCell className={classes.cell} sx={{
                        // borderTopRightRadius: "8px",
                        // borderBottomRightRadius: "8px"
                      }}>
                        {row.dailyValues[6]}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
            <Box
              sx={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Pagination
                count={totalPages}
                // count={10}
                page={currentPage}
                onChange={(event, page) => setCurrentPage(page)}
                color="primary"
                sx={{
                  mt: 2,

                  "& .MuiPaginationItem-root": {
                    background: "#A4A4A4",
                    border: "1px solid #fff",
                    boxShadow: "5px 5px 10px #000",
                    color: "#000",
                    "&:hover": {
                      color: "#FFF",
                      background: "#E1A800",
                    },
                    "&.Mui-selected": {
                      background: "#E1A800",
                    },
                  },

                  "& .MuiPaginationItem-icon": {

                    color: "#000", // Set color of the pagination icon to white
                  },
                }}
              />
            </Box>
          </Card>
        </div>
      )}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button className={classes.btn} onClick={handleRetainerDialogOpen}>
          + New Retainer
        </Button>
        <Button className={classes.btn} onClick={handleDialogOpen}>
          + New Product
        </Button>


        <Snack
          open={notifyOpen}
          message={Check == "1" ? success : error}
          severity={Check == "1" ? "success" : "error"}
          handleClose={handleClosePop}
        />
      </div>
    </div>
  );
};

export default Sheets;
