import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Alert,
  Grid,
  TextareaAutosize,
} from "@mui/material";
import categoryData from "../../../utils/CountryCode";
import axios from "axios";
import { useNavigate } from "react-router";
import Star from "../../../components/Rating/Star";
import { makeStyles } from "@mui/styles";
import RatingInput from "../../../components/Rating/RatingInput";
import Snack from "../../../components/snackbar/Snack";

import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  input: {
    border: "1px solid yellow",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
    borderRadius: "10px",
    "& .MuiInputBase-root": {
      backgroundColor: "#161A24",
      borderRadius: "10px",
      color: "#fff",
    },
    "& fieldset": {
      border: "none",
    },
    "& .MuiSvgIcon-root": {
      color: "#fff",
    }
  },
  ratingbg: {
    paddingLeft: "20px",
    display:'flex',
    justifyContent:'space-evenly',
    height: "60px",
    background: "#000",
    border: "1px solid yellow",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
    borderRadius: "10px",
    width: "100%",
  },
  backbtn: {
    borderRadius: "10px",
    fontSize: "14px",
    color: "#E3A700",
    height: "40px",
    marginTop: "10px",
    fontVariant: "small-caps",
    background: "#404040",
    border: "2px solid #fff",
    marginRight: "20px",
    boxShadow: "5px 5px 10px #000",
    width: "230px",
    fontWeight: "700",
    "&:hover": {
      backgroundColor: "#404040",
      cursor: "pointer",
    },
  },
  btn: {
    height: "40px",
    width: "230px",
    fontWeight: "700",
    borderRadius: "10px",
    marginTop: "10px",
    marginRight: "20px",
    color: "#000",
    fontVariant: "small-caps",
    background: "#E3A700",
    border: "2px solid #fff",
    fontSize: "14px",
    boxShadow: "5px 5px 10px #000",
    fontVariant: "small-caps",
    marginBottom: "10px",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#E3A700",
      cursor: "pointer",
    },
  },
  label: {
    marginBlock:"7px",
    fontSize: "14px",
    fontWeight: "bold",
    color: "white",
    paddingBottom: "5px",
    textShadow: "5px 5px 10px #000",
  },
}));

const initialValues = {
  Name: "",
  baseCpm: "",
  budget: "",
  override: "",
  impressions: "",
  clientCpm: "",
  zenonCommission: "",
};

const CreateVendor = (props) => {
  const user = useSelector((state) => state.auth.user);
  const [name, setName] = useState("");
  const [serviceProvided, setServiceProvided] = useState("");
  const [email, setEmail] = useState("");
  // const [phone, setPhone] = useState("");
  const [price, setPrice] = useState("");
  const [notes, setNotes] = useState("");
  const [rating, setRating] = useState();

  const navigate = useNavigate();
  const goBack = () => {
    props.closeDialog(false);
  };
  const classes = useStyles();
  // const [code, setCode] = React.useState("+1");
  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   if (name == "code") {
  //     setCode(value);
  //   } 
  // };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handleServiceChange = (event) => {
    setServiceProvided(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  // const handlePhoneChange = (event) => {
  //   setPhone(event.target.value);
  // };

  const handlePriceChange = (event) => {
    if(event.target.value >= 0){
      setPrice(event.target.value);
    }
  
  };

  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  };

  // const handleRatingChange = (event) => {
  //   setRating(event.target.value);
  // };

  // const [response, setResponse] = React.useState('');
  // const [severity,setSeverity] =React.useState('')
  const [notifyOpen, setNotifyOpen] = React.useState(false);
  const handleClosePop = () => {
    setNotifyOpen(false);
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Name :", name);
    console.log("Service Provided :", serviceProvided);
    console.log("Email :", email);
    // console.log("Phone :", phone);
    console.log("Price :", price);
    console.log("Notes :", notes);
    console.log("Rating :", rating);

    setName("");
    setServiceProvided("");
    setEmail("");
    // setPhone("");
    setPrice("");
    setNotes("");
    setRating(0);

    const body = {
      name,
      services: serviceProvided,
      email,
      phone:null,
      phoneCode : null,
      price,
      notes,
      rating,
      userId: user.organization._id,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}vendors/`,
        body
      );

      if (response.status === 201) {
        console.log("Vendor created successfully");
        setNotifyOpen(true);
        // props.closeDialog(false);
        // Clear form fields or navigate to another page
      } else {
        console.error("Failed to create vendor");
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };
  // useEffect(() => {
  //   console.log('rating:', rating);
  // }, [rating]);
  const handleStarClick = (selectedRating) => {
    setRating(selectedRating);
  };

  return (
    <div>
      <Box>
        <form onSubmit={handleSubmit}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 2 }}>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            {" "}
            <Box>
              <Typography className={classes.label}>Name</Typography>
              <TextField required
                fullWidth
                className={classes.input}
                name="Name"
                placeholder="Type here..."
                value={name}
                onChange={handleNameChange}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box>
              <Typography className={classes.label}>
                Services Provided
              </Typography>
              <TextField required
                fullWidth
                className={classes.input}
                name="ServiceProvided"
                placeholder="Type here..."
                value={serviceProvided}
                onChange={handleServiceChange}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 2 }}>
          <Grid item xs={12} md={12} lg={12}>
            <Box>
              <Typography className={classes.label}>Email</Typography>
              <TextField required
                fullWidth
                className={classes.input}
                name="Email"
                type="email"
                placeholder="Type here..."
                value={email}
                onChange={handleEmailChange}
              />
            </Box>
          </Grid>
          {/* <Grid item xs={12} md={12} lg={6}>
            <Box>
              <Typography className={classes.label}>Phone</Typography>
              <Box display={"flex"}>
                <FormControl
                  className={classes.input}
                  sx={{ marginRight: "10px", width: "120px" }}
                >
                  <Select
                    id="demo-simple-select"
                    value={code}
                    name="code"
                    // label="Age"
                    onChange={handleChange}
                  >
                    {categoryData.map((categoryItem) => (
                      <MenuItem
                        key={categoryItem.dial_code}
                        value={categoryItem.dial_code}
                      >
                        {categoryItem.dial_code}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField required
                  fullWidth
                  className={classes.input}
                  name="Phone"
                  placeholder="Type here..."
                  value={phone}
                  onChange={handlePhoneChange}
                />
              </Box>
            </Box>
          </Grid> */}
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 2 }}>
          <Grid item xs={12} sm={6} md={6}>
          <Box sx={{ position: "relative", width: "100%" }}>
              <Typography className={classes.label}> Price $</Typography>
              <TextField required
                fullWidth
                type="number"
                className={classes.input}
                name="Price"
                placeholder="Type here..."
                value={price}
                sx={{
                  "& .MuiInputBase-root": {paddingLeft:"15px"}
                }}
                onChange={handlePriceChange}
              />
                 <Box sx={{ position: "absolute", bottom: 18, left: 15 }}>
                    <Typography color={"#fff"}>$</Typography>
                  </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Typography className={classes.label}> Rating</Typography>

            <div className={classes.ratingbg}>
              {/* <RatingInput value={rating} onChange={(e)=> setRating(e.target.value)} /> */}
              {[1, 2, 3, 4, 5].map((number) => (
                <Star
                  key={number}
                  selected={number <= rating}
                  onClick={() => handleStarClick(number)}
                  value={rating}
                  onChange={(e) => setRating(e.target.value)}
                />
              ))}
            </div>
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 2 }}>
          <Grid item xs={12} md={12} lg={12}>
            <Box>
              <Typography className={classes.label}>Notes</Typography>
              <TextField required
                fullWidth
                type="textarea"
                className={classes.input}
                name="Notes"
                placeholder="Type here..."
                multiline
                rows={4}
                value={notes}
                onChange={handleNotesChange}
              />
            </Box>
          </Grid>
        </Grid>
    
          <Stack>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
              <Button
                variant="outlined"
                className={classes.backbtn}
                onClick={goBack}
              >
                {" "}
                Back{" "}
              </Button>
              <Button
                variant="contained"
                type="submit"
                className={classes.btn}
                sx={{ ml: 2 }}
                // onClick={handleSubmit}
              >
                {" "}
                Create Vendor{" "}
              </Button>
            </Box>
          </Stack>
    
        </form>
      </Box>
      <Snack
        open={notifyOpen}
        message={"Vendor Creaated Successfully"}
        severity="success"
        handleClose={handleClosePop}
      />
    </div>
  );
};

export default CreateVendor;
