import {
  Box,
  Grid,
  TextField,
  Typography,
  Avatar,
  Button,
  CardContent,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState, useEffect } from "react";
import CustomNoteBook from "./CustomNoteBook/CustomNoteBook";
// import BookComponent from './CustomNoteBook/CustomNoteBook'
// import {CustomNoteBook} from './CustomNoteBook/CustomNoteBook'
import { useNavigate, useLocation } from "react-router-dom";
import { ConstructionSharp } from "@mui/icons-material";
import CreateIcon from "@mui/icons-material/Create";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import axios from "axios";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: "15px",

    border: "5px solid #fff",
    background: "#746E6E",
    borderRadius: "16px",
    color: "#fff",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
  },
  innerCard: {
    border: "3px solid #F3F3F3",
    padding: "13px, 16px, 13px, 16px",
    background: " linear-gradient(#30344A, #9EA6DD,#34374D)",
    borderRadius: "16px",
    boxShadow: "inset 0px 1px 5px #fff, 0px 0px 5px black",
    color: "#fff",
  },
  input: {
    border: "1px solid yellow",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
    borderRadius: "10px",
    backgroundColor: "#000",
    color: "#fff",
    "& .MuiInputBase-root": {
      backgroundColor: "#000",
      borderRadius: "10px",
      color: "#fff",
    },
    "& fieldset": {
      border: "none",
    },
  },
  label: {
    mb: 1,
    fontSize: "14px",
    fontWeight: 700,
    color: "#fff",
    textShadow: "5px 5px 10px #000",
    marginBlock: "10px",
  },
  txt: {
    fontSize: "24px",
    fontWeight: "800",
    fontFamily: "Nunito Sans",
    color: "#FAFF00",
    textShadow:
      "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
  },
  btn: {
    height: "45px",
    // width:"511px",
    fontWeight: "700",
    borderRadius: "10px",
    marginTop: "40px",
    color: "#000",
    fontVariant: "small-caps",
    background: "#E3A700",
    border: "2px solid #fff",
    fontSize: "14px",
    boxShadow: "5px 5px 10px #000",
    fontVariant: "small-caps",
    marginBottom: "10px",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#E3A700",
      cursor: "pointer",
    },
  },
  backbtn: {
    boxShadow: "5px 5px 10px #000",
    borderRadius: "8px",
    fontSize: "14px",
    height: "45px",
    width: "181.5px",
    color: "#E3A700",
    fontVariant: "small-caps",
    fontWeight: "700",
    background: "#404040",
    border: "1px solid #fff",
    marginRight: "20px",
    // boxShadow: "5px 5px 10px #000",
    "&:hover": {
      backgroundColor: "#404040", // Changing background color on hover
      cursor: "pointer", // Showing pointer cursor on hover
      // Add any additional styles for hover state here
    },
  },

  disp: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },

  innercard: {
    border: "3px solid #FAFF00",
    background: " linear-gradient(#30344A, #9EA6DD,#34374D)",
    borderRadius: "16px",
    color: "#FAFF00",
    boxShadow: "0px 0px 15px #FAFF00, 0px 0px 15px #FAFF00 inset",
  },
}));
const Scheduling = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const goBack = () => {
    props.closeDialog(false);
  };
  console.log(state);
  const classes = useStyles();
  const [lead, setLead] = useState("");
  const [notes, setNotes] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [scheduling, setScheduling] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [schedules, setSchedules] = useState([]);

  // const date =new Date().toLocaleDateString()
  // console.log(date)
  const handleBackBtn = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_URL}companies/firstContact/${state._id}`
    );
    console.log(res.data);
    navigate("/dashboard/company-crm", { state: res.data.data });
  };
  const handleSubmit = async (e) => {
    const body = {
      date: date,
      time: time,
      typeOfLead: lead,
      notes: notes,
      companyId: state._id,
    };
    console.log("schedule click", body);
    const res = await axios.post(
      `${process.env.REACT_APP_URL}api/schedules`,
      body
    );
    console.log(res);
    setScheduling((prevData) => [...prevData, res.data.data]);
    setDate("");
    setTime("");
    setLead("");
    setNotes("");
  };

  async function fetchScheduling() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}api/schedules/company/${state._id}`
      );

      if (response.status === 200) {
        console.log(response.data.data);
        setScheduling(response.data.data);
        setRefreshKey(refreshKey + 1);
      } else {
        console.error("Failed to fetch scheduling");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const handleEditClick = (receivedData) => {
    // Handle the edited data here
    console.log("Edited data received:", receivedData);
    const localDate = moment(receivedData.date).format("YYYY-MM-DD");
    setLead(receivedData.typeOfLead);
    setNotes(receivedData.notes);
    setDate(localDate);
    setTime(receivedData.time);
    setSchedules(receivedData);
    setIsEditMode(true);
  };

  useEffect(() => {
    // Fetch contracts when the component mounts
    fetchScheduling();
  }, []);
  const updateHandler = async () => {
    const body = {
      date: date,
      time: time,
      typeOfLead: lead,
      notes: notes,
      companyId: schedules.company,
    };
    console.log("edit schedule", body, schedules);
    const res = await axios.put(
      `${process.env.REACT_APP_URL}api/schedules/${schedules._id}`,
      body
    );
    console.log(res);
    setScheduling(res.data.data);
    setDate("");
    setTime("");
    setLead("");
    setNotes("");

    setIsEditMode(false);
    setRefreshKey(refreshKey + 1);
  };
  return (
    <Box className={classes.box} sx={{overflow:'none'}}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={5}>
          <Typography className={classes.txt} mb={3}> Contact History </Typography>

          <CustomNoteBook
            data={scheduling}
            onEditClick={handleEditClick}
            key={refreshKey}
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 15,
              p: 2,
            }}
          ></Box>
        </Grid>
        <Grid item xs={12} md={12} lg={7}>
          <Typography className={classes.txt}>
            {" "}
            Schedule Contact Date
          </Typography>
          <Box>
            <Typography className={classes.label}> Date </Typography>
            <TextField required
              type="date"
              fullWidth
              className={classes.input}
              value={date}
              sx={{
                "& .MuiOutlinedInput-input": {
                  colorScheme: "dark", // Corrected property name and value
                },
              }}
              onChange={(e) => setDate(e.target.value)}
            />
          </Box>
          <Box>
            <Typography className={classes.label}> Time </Typography>
            <TextField required
              type="time"
              fullWidth
              className={classes.input}
              value={time}
              sx={{
                "& .MuiOutlinedInput-input": {
                  colorScheme: "dark", // Corrected property name and value
                },
              }}
              onChange={(e) => setTime(e.target.value)}
            />
          </Box>
          <Box>
            <Typography className={classes.label}> Type of Lead </Typography>
            <TextField required
              fullWidth
              className={classes.input}
              value={lead}
              onChange={(e) => setLead(e.target.value)}
            />
          </Box>
          <Box>
            <Typography className={classes.label}> Notes </Typography>
            <TextField required
              placeholder="Start working from here"
              fullWidth
              multiline
              rows={8}
              className={classes.input}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </Box>
          <div className={classes.disp}>
            <Button
              variant="contained"
              className={classes.backbtn}
              sx={{ mt: 4 }}
              onClick={goBack}
            >
              {" "}
              Back{" "}
            </Button>
            {isEditMode ? (
              <Button
                variant="contained"
                onClick={updateHandler}
                className={classes.btn}
              >
                {" "}
                Update Meeting{" "}
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={handleSubmit}
                className={classes.btn}
              >
                {" "}
                Schedule Meeting{" "}
              </Button>
            )}
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Scheduling;
