import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Card,Box,Button
} from "@mui/material";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  card: {
    // marginLeft: '10px',
    // marginTop: '10px',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "311px",
    height: "261px",
    borderRadius: "25px",
    // flexShrink: '0',
    background:
      "var(--prime-blue-gradiant, linear-gradient(180deg, #3CAFE4 0%, #046593 100%))",
    "& img": {
      width: "100%",
      height: "auto",
      maxWidth: "100%",
      borderRadius: theme.spacing(1),
    },
  },
  cardContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    justifyContent: "space-evenly",
    backgroundColor: "#E6F6FE",
  },
  maintypo: {
    display: "flex",
    justifyContent: "center",
    color: "#979191",
    fontFamily: "Nunito Sans",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    letterSpacing: "-0.114px",
  },
  image: {
    width: "311px",
    height: "261px",
  },
  btn: {
    borderRadius: "10px",
    marginTop: "20px",
    marginRight: "20px",
    color: "#E3A700",
    fontVariant: "small-caps",
    background: "#404040",
    border: "2px solid #fff",
    fontSize: "14px",
    boxShadow: "5px 5px 10px #000",
    fontVariant: "small-caps",
    marginBottom: "10px",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#404040",
      cursor: "pointer",
    },
  },
  btn1: {
    borderRadius: "10px",
    marginTop: "20px",
    marginRight: "20px",
    color: "#404040",
    fontVariant: "small-caps",
    background: "#E3A700",
    border: "2px solid #fff",
    fontSize: "14px",
    boxShadow: "5px 5px 10px #000",
    fontVariant: "small-caps",
    marginBottom: "10px",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#E3A700",
      cursor: "pointer",
    },
  },
  card: {
    padding: "25px",
    border: "2px solid #fff",
    background: "#9A9A9A",
    borderRadius: "16px",
    color: "#fff",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
  },
}));
const Videos = () => {
  const user = useSelector((state) => state.auth.user);
  const [mediaItems, setMediaItems] = useState([]);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState({
    url: "",
    name: "",
    entryDate: "",
  });
  const classes = useStyles();
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_URL}api/files/video/${user.organization._id}`
      )
      .then((response) => {
        console.log(response);
        setMediaItems(response.data);
      })
      .catch((error) => {
        console.error("Error fetching videos:", error);
      });
  }, []);

  const openFullScreen = (url, name, entryDate) => {
    setSelectedMedia({ url, name, entryDate });
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  const navigate = useNavigate();
  const goBack = () => {
    navigate("/dashboard/library");
  };
  return (
    <Box sx={{ height:'90vh'}}>
    <Card className={classes.card}>
   
      <div style={{ display: "flex", flexWrap: "wrap" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button 
            onClick={goBack}
            className={classes.btn}>Back to Library</Button>
            <Typography
              sx={{
                mt: 2,
                color: "#FAFF00",
                fontWeight: "700",
                fontSize: "27px",
                fontFamily: "Nunito Sans",
                textShadow:
                  "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
              }}
            >
              Videos
            </Typography>
          </Box>
        <Grid container spacing={1} sx={{ mt: 1 }}>
          {mediaItems.map((video) => (
            <Grid
              item
              xs={12}
              md={3}
              lg={2}
              key={video._id}
              style={{ cursor: "pointer" }}
              onClick={() =>
                openFullScreen(
                  `${process.env.REACT_APP_URL}${video.filePath}`,
                  video.name,
                  video.entryDate
                )
              }
            >
              <video width="206px" height="215px"      
                style={{
                      objectFit: "cover",
                      height: "215px",
                      width: "206px",
                      borderRadius: "16px",
                      border: "3px solid #fff",
                    }}>
                <source
                  src={`${process.env.REACT_APP_URL}${video.filePath}`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
              {/* <p style={{ marginTop: "5px", textAlign: "center" }}>
              {video.name}
            </p>
            <p style={{ marginTop: "5px", textAlign: "center" }}>
              {moment(video.entryDate).format("DD/MM/YYYY")}
            </p> */}
            </Grid>
          ))}
        </Grid>
      </div>
      <Dialog open={lightboxOpen} onClose={closeLightbox} fullScreen>
        <DialogTitle>
          <button
            onClick={closeLightbox}
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              background: "none",
              border: "none",
              color: "#000",
              fontSize: "20px",
              cursor: "pointer",
            }}
          >
            X
          </button>
        </DialogTitle>
        <DialogContent>
          <video controls width="100%" height="100%">
            <source src={selectedMedia.url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <Typography variant="h6" style={{ marginTop: "10px" }}>
            {selectedMedia.name}
          </Typography>
          <Typography variant="subtitle1">
            {moment(selectedMedia.entryDate).format("DD/MM/YYYY")}
          </Typography>
        </DialogContent>
      </Dialog>
    </Card>
    </Box>  
  );
};

export default Videos;
