//  import React, { useState } from "react";
import "./updstyle.css";
import {
  Avatar,
  Box,
  Button,
  Typography,
  CardContent,
  Card,
} from "@mui/material";
import moment from "moment";
import { FlippingPages } from "flipping-pages";
import "flipping-pages/dist/style.css";
import { useState } from "react";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import CreateIcon from "@mui/icons-material/Create";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
const useStyles = makeStyles((theme) => ({
  card: {
    padding: "15px",

    border: "5px solid #fff",
    background: "#110000",
    borderRadius: "16px",
    color: "#fff",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
  },
  innerCard: {
 
    border: "3px solid #F3F3F3",
    padding: "13px, 16px, 13px, 16px",
    background: " linear-gradient(#30344A, #9EA6DD,#34374D)",
    borderRadius: "16px",
    boxShadow: "inset 0px 1px 5px #fff, 0px 0px 5px black",
    color: "#fff",
  },
  input: {
    border: "1px solid yellow",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
    borderRadius: "10px",
    backgroundColor: "#000",
    color: "#fff",
    "& .MuiInputBase-root": {
      backgroundColor: "#000",
      borderRadius: "10px",
      color: "#fff",
    },
    "& fieldset": {
      border: "none",
    },
  },
  label: {
    mb: 1,
    fontSize: "14px",
    fontWeight: 700,
    color: "#fff",
    textShadow: "5px 5px 10px #000",
    marginBlock: "10px",
  },
  txt: {
    fontSize: "24px",
    fontWeight: "800",
    fontFamily: "Nunito Sans",
    color: "#FAFF00",
    textShadow:
      "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
  },
  btn: {
    height: "45px",
    // width:"511px",
    fontWeight: "700",
    borderRadius: "10px",
    marginTop: "40px",
    color: "#000",
    fontVariant: "small-caps",
    background: "#E3A700",
    border: "2px solid #fff",
    fontSize: "14px",
    boxShadow: "5px 5px 10px #000",
    fontVariant: "small-caps",
    marginBottom: "10px",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#E3A700",
      cursor: "pointer",
    },
  },
  backbtn: {
    boxShadow: "5px 5px 10px #000",
    borderRadius: "8px",
    fontSize: "14px",
    height: "45px",
    width: "181.5px",
    color: "#E3A700",
    fontVariant: "small-caps",
    fontWeight: "700",
    background: "#404040",
    border: "1px solid #fff",
    marginRight: "20px",
    // boxShadow: "5px 5px 10px #000",
    "&:hover": {
      backgroundColor: "#404040", // Changing background color on hover
      cursor: "pointer", // Showing pointer cursor on hover
      // Add any additional styles for hover state here
    },
  },

  disp: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },

  innercard: {
    border: "3px solid #FAFF00",
    background: " linear-gradient(#30344A, #9EA6DD,#34374D)",
    borderRadius: "16px",
    color: "#FAFF00",
    boxShadow: "0px 0px 15px #FAFF00, 0px 0px 15px #FAFF00 inset",
  },
  cardtextHeader:{
    textShadow:
    "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
    fontSize:'14px',
    fontFamily:'Nunito Sans',
    fontWeight:'700'
  },
  cardtext:{
    textShadow:
    "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
    fontSize:'19px',
    fontFamily:'Nunito Sans',
    fontWeight:'500'
  }
}));
const CustomNoteBook = ({ data, onEditClick, keyProp }) => {
  console.log(data);
  const [key, setKey] = useState(keyProp);
  const [flipped, setFlipped] = useState(false);
  const [selected, setSelected] = useState(0);
  // const [schedules, setSchedules] = useState(data);
  // const [isNotFirstPage,setIsNotFirstPage] =useState(false)
  // if (selected !== 0){
  //   setIsNotFirstPage(true)
  // }
  // console.log(data[selected])
  const handleDel = async () => {
    console.log("deleted");
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_URL}api/schedules/${data[selected]._id}`
      );
      window.location.reload();
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleUpdate = () => {
    console.log("updated", data[selected]);
    onEditClick(data[selected]);
  };

  
  const back = () => {
    setSelected((selected) => Math.max(selected - 1, 0));
  };

  const next = () => {
    setSelected((selected) => Math.min(selected + 1, data.length - 1));
  };

  // if (data.length === 0) {
  //   return <div>No meeting scheduled.</div>;
  // }
  const classes = useStyles();
  const page = data[selected];
  console.log(page, "Custom", selected)

  return (
    <div>
      {data.length === 0 ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1>No meeting scheduled.</h1>
        </div>
      ) : (
        <>
            <div style={{}} key={page._id}>
              <div style={{}}>
                {/* <div id="cover"> */}

                <Card className={classes.card}>
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb:5
                      }}
                    >
                      <h1>Contact {selected + 1}</h1>
                      <Box sx={{ display: "flex" }}>
                        <Avatar
                          sx={{
                            color: "#000",
                            background: "#E3A700",
                            boxShadow: "5px 5px 10px #000",
                            border: "1px solid #fff",
                          }}
                          
                          onClick={() => handleUpdate()}
                        >
                          <CreateIcon sx={{ color: "#000" }} />
                        </Avatar>

                        <Avatar
                          sizes="sm"
                          sx={{
                            color: "#E3A700",
                            background: "#000",
                            marginLeft: "10px",
                            boxShadow: "5px 5px 10px #000",
                            border: "1px solid #fff",
                          }}
                          onClick={() => handleDel()}
                        >
                          <DeleteForeverIcon fontSize="sm" />
                        </Avatar>
                      </Box>
                    </Box>
                    <Box mt={2}/>
                    <Card className={classes.innercard}>
                      <CardContent style={{ textAlign: "center" }}>
                        <Typography className={classes.cardtextHeader}>
                          Date:
                        </Typography>
                        <Typography className={classes.cardtext}>
                          { moment(page.date).format("YYYY-MM-DD")}
                        </Typography>
                      </CardContent>
                    </Card>
                    <Box mt={2}/>
                    <Card className={classes.innercard}>
                      <CardContent style={{ textAlign: "center" }}>
                        <Typography className={classes.cardtextHeader}>
                          Time:
                        </Typography>
                        <Typography className={classes.cardtext}>
                          {page?.time}
                        </Typography>
                      </CardContent>
                    </Card>   <Box mt={2}/>
                    <Card className={classes.innercard}>
                      <CardContent style={{ textAlign: "center" }}>
                        <Typography className={classes.cardtextHeader}>
                          Type of Lead:
                        </Typography>
                        <Typography className={classes.cardtext}>
                          {page.typeOfLead}
                        </Typography>
                      </CardContent>
                    </Card>
                    <Box mt={2}/>
                    <Card className={classes.innercard}>
                      <CardContent style={{ textAlign: "center" }}>
                        <Typography className={classes.cardtextHeader}>
                          Notes:
                        </Typography>
                        <Typography className={classes.cardtext}>
                          {page.notes}
                        </Typography>
                      </CardContent>
                    </Card>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mt: 3,
                      }}
                    >
                      <Avatar
                        sx={{
                          color: "#000",
                          background: "#A4A4A4",
                          boxShadow: "5px 5px 10px #000",
                          border: "1px solid #fff",
                        }}
                        onClick={back}
                      >
                        <KeyboardDoubleArrowLeftIcon sx={{ color: "#000" }} />
                      </Avatar>

                      <Avatar
                        sizes="sm"
                        sx={{
                          color: "#000",
                          background: "#A4A4A4",
                          boxShadow: "5px 5px 10px #000",
                          border: "1px solid #fff",
                          marginLeft: "20px",
                        }}
                        onClick={next}
                      >
                        <KeyboardDoubleArrowRightIcon fontSize="sm" />
                      </Avatar>
                    </Box>
            
                  </CardContent>
                </Card>
              </div>
            </div>
          </>
      )}
    </div>
  );
};

export default CustomNoteBook;
