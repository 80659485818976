const initialState = {
    isAuthenticated: false,
    isLoading: false,
    token : localStorage.getItem('token'),
    isRegistered: null,
    error: null,
    user: null, 
}

const authReducer = ( state=initialState, action) => {
    switch(action.type) {
        case 'LOGIN_SUCCESS' : {
            localStorage.setItem('token', action.payload.token)
            console.log('auth reducer', action.payload)
            return{
                ...state,
                ...action.payload,
                isAuthenticated: true
            }
        }
        case 'userUpdate' : {
            return{
                ...state,
                user:action.payload,
        
            }
        }
        case 'LOGOUT_SUUCCESS' : {
        localStorage.removeItem('token')
        return {
            token: null,
            user: null,
            isAuthenticated: false,
            isLoading: false
          };
    };
        default :  return state
    }
}

export default authReducer