import { Box, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import { Typography, Button, Paper } from '@mui/material';
import React from 'react'
import { useLocation } from 'react-router-dom';


import CompanyDetails from './CompanyDetails';
import AddContacts from './AddContacts';
import NotesAndLeads from './NotesAndLeads';
import Finish from './Finish';
import { useState } from 'react';
const useStyles = makeStyles((theme) => ({
  box: {
    borderRadius: '12px',
    // border: '0.3px solid #208ABB',
    border: '0.3px solid rgba(32,138,187,0.25)',
    background: '#E6F6FE',
    boxShadow: ' 0px 14px 25px 0px rgba(141, 112, 112, 0.12)',
  },
  sub: {
    fontFeatureSettings: "'liga' off",
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
  },
  btn: {
    marginTop: '50px',
    borderRadius: '12px',
    background: 'linear-gradient(180deg, #3CAFE4 0%, #046593 100%)',
    width: '281.211px',
    height: '56px',
  }
}))



const steps = [
  {
    label: 'Step 1',
    sub: 'Company Details',
    description: `Enter Company Details and contact details`,
  },
  {
    label: 'Step 2',
    sub: 'Add Contacts',
    description:
      'Enter company employee details and their roles',
  },
  {
    label: 'Step 3',
    sub: 'Notes & Lead',
    description: `Add Additional Notes and type of leads`,
  },
  {
    label: 'Step 4',
    sub: 'Finish',
    description: `Review your information`,
  },
];

const UpdateDetails = () => {
  const { state } = useLocation();
  const [company, setCompany] = useState('')
  const handleCompanyDataChange = (updatedData) => {
    console.log('Company data changed:', updatedData);
    setCompany(updatedData)
    console.log(company)
  };

  const [contacts, setContacts] = useState([]);
  const handleContactDataChange = (newContacts) => {
    setContacts(newContacts);
    console.log('Contacts changed:', newContacts);
    console.log(contacts)
  }

  const handleNotesAndLeadsChange = (newData) => {
    console.log('Notes and leads changed:', newData);
  };


  const [activeStep, setActiveStep] = React.useState(0);
  const [activeStepo, setActiveStepo] = React.useState(0);



  console.log('UpdateDetails state:', state);


  const classes = useStyles()
  return (
    <Box sx={{ minHeight: '100vh', p: 8 }} className={classes.box}>
      <Box sx={{ display: 'flex' }}>
        <Grid container
          spacing={5}
        >
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
          >
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel
                    optional={
                      index === 2 ? (
                        <Typography variant="caption">Last step</Typography>
                      ) : null
                    }
                  >
                    {step.label}
                    <Typography
                      className={classes.sub}
                      sx={{ mt: 1 }}
                    > {step.sub} </Typography>
                  </StepLabel>
                  <StepContent>
                    <Typography>{step.description}</Typography>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={8}

          >

            {activeStep == 0 ? <CompanyDetails companyData={state}
              setActiveStep={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}
              onCompanyChange={handleCompanyDataChange} />
              : activeStep == 1 ? <AddContacts companyData={state} setActiveStep={(action) => {
                if (action === 'increment') {
                  setActiveStep((prevActiveStep) => prevActiveStep + 1);
                } else if (action === 'decrement') {
                  setActiveStep((prevActiveStep) => prevActiveStep - 1);
                }
              }} onContactsChange={handleContactDataChange} />
                : activeStep == 2 ? <NotesAndLeads companyData={state} setActiveStep={(action) => {
                  if (action === 'increment') {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                  } else if (action === 'decrement') {
                    setActiveStep((prevActiveStep) => prevActiveStep - 1);
                  }
                }} />
                  : <Finish companyData = {company} contactsData = {contacts} />
            }
          </Grid>
        </Grid>

        <Box>
        </Box>
      </Box>

    </Box>
  )
}

export default UpdateDetails
