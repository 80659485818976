import React from "react";
import Schedule from "../../MainDash/components/Schedule";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux/es/hooks/useSelector";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import MyPdfViewer from "../../contracts/components/MyPdfViewer";
import Snack from "../../../components/snackbar/Snack";
import { Label } from "@mui/icons-material";
import CreateInvoces from "./createInvoces";
const useStyles = makeStyles((theme) => ({
  card: {
    padding: "25px",
    border: "2px solid #fff",
    background: "#9A9A9A",
    borderRadius: "16px",
    color: "#fff",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
  },
  input: {
    border: "1px solid yellow",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
    borderRadius: "10px",
    backgroundColor: "#000",
    color: "#fff",
    "& .MuiInputBase-root": {
      backgroundColor: "#000",
      borderRadius: "10px",
      color: "#fff",
    },
    "& fieldset": {
      border: "none",
    },
    "& .MuiSvgIcon-root": {
      color: "#fff",
    },
  },
  backbtn: {
    borderRadius: "10px",
    fontSize: "1rem",
    color: "#E3A700",
    fontVariant: "small-caps",
    background: "#404040",
    border: "1px solid #fff",
    marginRight: "20px",
    // boxShadow: "5px 5px 10px #000",
    "&:hover": {
      backgroundColor: "#404040", // Changing background color on hover
      cursor: "pointer", // Showing pointer cursor on hover
      // Add any additional styles for hover state here
    },
  },
  btn: {
    borderRadius: "10px",
    color: "#000",
    fontWeight: "bold",
    fontVariant: "small-caps",
    background: "#E3A700",
    border: "1px solid #fff",
    fontSize: "1.2rem",
    fontVariant: "small-caps",
    transition: "background-color 0.3s ease", // Adding transition for smooth effect
    "&:hover": {
      backgroundColor: "#E3A700", // Changing background color on hover
      cursor: "pointer", // Showing pointer cursor on hover
      // Add any additional styles for hover state here
    },
  },
  secCont: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    margin: "15px 25px 0 0px",
    height: "60vh",
    // padding: "10px",
  },
  secone: {
    height: "160vh",
    width: "64%",
    display: "flex",
    flexDirection: "column",
  },

  cardheaderText: {
    fontSize: "20px",
    color: "#FAFF00",
    fontWeight: "bold",
    textShadow:
      "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
    // WebkitTextStroke: "1px black",
    // textShadow: "5px 5px 10px #FAFF00",
  },
  label: {
    marginTop: "6px",
    fontSize: "14px",
    fontWeight: "bold",
    color: "white",
    paddingBottom: "5px",
    textShadow: "5px 5px 10px #000",
  },
}));
const Invoice = (props) => {
  const user = useSelector((state) => state.auth.user);
  const [formDatac, setFormDataC] = useState({
    companyName: [],
  });
  const [formData, setFormData] = useState({
    // userId: user._id,
    companyName: "",
    date: "",
  });
  const [res, setRes] = useState("");
  const [success, setSuccess] = useState("");
  const [invoicePaidDate, setInvoicePaidDate] = useState(""); // Added state for invoicePaidDate
  const [openDialog, setOpenDialog] = useState(false);
  const [file, setFile] = useState("");
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_URL}api/retainerinfo/${user.organization._id}`
      )
      .then((res) => {
        setFormDataC({
          ...formDatac,
          companyName: res.data.companies,
        });
        setFormData({
          ...formData,
          companyName: res.data.companies[0].companyName,
        });
        // getData(formData.companyName,formData.date)
      });
  }, []);
  const [amount, setAmount] = useState("");
  const [notifyOpen, setNotifyOpen] = React.useState(false);
  const [error, setError] = useState("");
  const handleDialogSubmit = (e) => {
    e.preventDefault();

    console.log("Invoice Paid Date:", invoicePaidDate);
    const body = {
      amount: amount,
      InvoicePaidDate: invoicePaidDate,
    };
    console.log("Body++++++++++++:", body);
    axios
      .put(`${process.env.REACT_APP_URL}api/invoices/${res._id}`, body)
      .then((res) => {
        setSuccess("Invoice Paid Time added successfully!");
        setNotifyOpen(true);
        setAmount('');
        setInvoicePaidDate('')
        setTimeout(() => {
          setNotifyOpen(false);
        }, 10000);
      })
      .catch((error) => {
        setNotifyOpen(true);
        setAmount('');
        setInvoicePaidDate('')
        setError("Failed to Update Invoice Paid Time . Please try again.");
        setTimeout(() => {
          setNotifyOpen(false);
        }, 10000);
      });

    // setOpenDialog(false); // Close the dialog after submitting
  };

  const handleDateChange = (e) => {
    const formattedDate = e.format("YYYY-MM-DD");
    console.log("date", formattedDate);
    getData(formData.companyName, formattedDate);
  };
  const handleCompanyChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    getData(value, formData.date);
  };

  // amount
  const getData = (companyName, invoiceDate) => {
    axios
      .get(
        `${process.env.REACT_APP_URL}api/invoices/${user.organization._id}/${companyName}/${invoiceDate}`
      )
      .then((res) => {
        setRes(res.data);
        setFile((prevFile) => process.env.REACT_APP_URL + res.data.filePath);
        console.log("filePath", file);
      })
      .catch((err) => console.log(err));
  };
  const updateInvoice = () => {
    setOpenDialog(true);
  };
  const [openDialog_, setOpenDialog_] = useState(false);

  const handleDialogClose = (data) => {
    setOpenDialog_(data);
    getData();
  };
  const createInvoice = () => {
    setOpenDialog_(true);
  };
  const classes = useStyles();
  return (
    <Box>
      <div style={{}}>
        <div>
          <Card className={classes.card}>
            <CardContent>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 2 }}>
                <Grid item xs={12} md={12} lg={5}>
                  <InputLabel
                    className={classes.cardheaderText}
                    id="demo-simple-select-label"
                  >
                    {" "}
                    Company Name
                  </InputLabel>
                  <FormControl fullWidth className={classes.textfield}>
                    <Select
                      id="demo-simple-select"
                      value={formData.companyName}
                      name="companyName"
                      required
                      // placeholder="Select an Item"
                      onChange={handleCompanyChange}
                      className={classes.input}
                    >
                      {formDatac.companyName.map((categoryItem) => (
                        <MenuItem
                          key={categoryItem._id}
                          value={categoryItem.companyName}
                        >
                          {categoryItem.companyName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <Schedule onDateChange={handleDateChange} />
                  <Box display={"flex"} justifyContent={"center"}>
                    <Button
                      variant="contained"
                      type="button"
                      sx={{ alignItems: "center" }}
                      className={classes.btn}
                      onClick={updateInvoice}
                    >
                      UpDate Invoice
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} md={12} lg={7}>
                  <div
                    style={{
                      margin: "10px",
                      width: "490px",
                      height: "654px",
                      border: "2px solid #fff",
                      marginTop: "30px",
                      // borderRadius: file? "0px":"20px",
                      background: "#000",
                    }}
                  >
                    {
                      props.file !== null ? <MyPdfViewer pdf={file} /> : "" // Render a message if no file is selected
                    }
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>

        <Dialog
          fullWidth={true}
          PaperProps={{
            style: {
              background: " linear-gradient(#4F3B03, #E3A700,#503D06)",
              border: "3px solid #fff",
              borderRadius: "24px",
            },
          }}
          open={openDialog}
          onClose={() => setOpenDialog(false)}
        >
          {/* <DialogTitle >Enter Invoice Paid Date</DialogTitle> */}
          <form onSubmit={handleDialogSubmit}>
            <DialogContent>
              <label className={classes.label}>Enter Invoice Paid Date</label>
              <TextField
                required
                className={classes.input}
                sx={{
                  mb: "10px",
                  mt: "10px",
                  "& .MuiOutlinedInput-input": {
                    colorScheme: "dark", // Corrected property name and value
                  },
                }}
                type="date"
                value={invoicePaidDate}
                onChange={(e) => setInvoicePaidDate(e.target.value)}
                fullWidth
              />
              <Box sx={{ position: "relative", width: "100%" }}>
                <label className={classes.label}>Amount</label>
                <TextField
                  className={classes.input}
                  // type="amount"
                  sx={{
                    "& .MuiInputBase-root": {paddingLeft:"15px"}
                  }}
                  required
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  fullWidth
                />
                <Box sx={{ position: "absolute", bottom: 18, left: 15 }}>
                  <Typography color={"#fff"}>$</Typography>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions sx={{ mr: 3, mb: 2 }}>
              <Button
                className={classes.backbtn}
                onClick={() => setOpenDialog(false)}
              >
                Cancel
              </Button>
              <Button className={classes.btn} type="submit">
                Update
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
      <Box sx={{ pt: 3, justifyContent: "center", display: "flex" }}>
        <Button
          variant="contained"
          type="button"
          sx={{ marginLeft: "20%" }}
          className={classes.btn}
          onClick={createInvoice}
        >
          Upload Invoice
        </Button>
      </Box>
      <Snack
        open={notifyOpen}
        message={success ? success : error}
        severity={success ? "success" : "error"}
        // handleClose={handleClosePop}
      />
      <Dialog
        fullWidth
        maxWidth="lg"
        PaperProps={{
          style: {
            background: " linear-gradient(#4F3B03, #E3A700,#503D06)",
            border: "3px solid #fff",
            borderRadius: "24px",
          },
        }}
        open={openDialog_}
        onClose={handleDialogClose}
      >
        <DialogTitle
          sx={{
            color: "#FAFF00",
            textShadow:
              "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
          }}
        >
          Upload Invoice
        </DialogTitle>
        <DialogContent>
          <CreateInvoces closeDialog={handleDialogClose} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Invoice;
