import React, { useEffect, useState } from "react";

import { makeStyles } from "@mui/styles";
import {
  Avatar,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Card,
  Grid,
  Box,
  Button,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
// import RatingInput from '../../components/Rating/RatingInput'
const useStyles = makeStyles((theme) => ({
  card: {
    // marginLeft: '10px',
    // marginTop: '10px',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "311px",
    height: "261px",
    borderRadius: "25px",
    // flexShrink: '0',
    background:
      "var(--prime-blue-gradiant, linear-gradient(180deg, #3CAFE4 0%, #046593 100%))",
    "& img": {
      width: "100%",
      height: "auto",
      maxWidth: "100%",
      borderRadius: theme.spacing(1),
    },
  },
  cardContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    justifyContent: "space-evenly",
    backgroundColor: "#E6F6FE",
  },
  maintypo: {
    display: "flex",
    justifyContent: "center",
    color: "#979191",
    fontFamily: "Nunito Sans",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    letterSpacing: "-0.114px",
  },
  image: {
    width: "311px",
    height: "261px",
  },
  btn: {
    borderRadius: "10px",
    marginTop: "20px",
    marginRight: "20px",
    color: "#E3A700",
    fontVariant: "small-caps",
    background: "#404040",
    border: "2px solid #fff",
    fontSize: "14px",
    boxShadow: "5px 5px 10px #000",
    fontVariant: "small-caps",
    marginBottom: "10px",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#404040",
      cursor: "pointer",
    },
  },
  btn1: {
    borderRadius: "10px",
    marginTop: "20px",
    marginRight: "20px",
    color: "#404040",
    fontVariant: "small-caps",
    background: "#E3A700",
    border: "2px solid #fff",
    fontSize: "14px",
    boxShadow: "5px 5px 10px #000",
    fontVariant: "small-caps",
    marginBottom: "10px",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#E3A700",
      cursor: "pointer",
    },
  },
  card: {
    padding: "25px",
    border: "2px solid #fff",
    background: "#9A9A9A",
    borderRadius: "16px",
    color: "#fff",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
  },
}));

// commenting from ahmed account

const Images = () => {
  const classes = useStyles();
  const user = useSelector((state) => state.auth.user);

  const [images, setImages] = useState([]);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState({ url: "", name: "" });

  useEffect(() => {
    // Fetch data from the API endpoint
    axios
      .get(
        `${process.env.REACT_APP_URL}api/files/image/${user.organization._id}`
      )
      .then((response) => {
        console.log(response);
        setImages(response.data);
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
      });
  }, []);

  const openFullScreen = (url, name) => {
    setSelectedImage({ url, name });
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  const navigate = useNavigate();
  const goBack = () => {
    navigate("/dashboard/library");
  };
  return (
    <Box sx={{ height:'90vh'}}>
    <Card className={classes.card}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button 
            onClick={goBack}
            className={classes.btn}>Back to Library</Button>
            <Typography
              sx={{
                mt: 2,
                color: "#FAFF00",
                fontWeight: "700",
                fontSize: "27px",
                fontFamily: "Nunito Sans",
                textShadow:
                  "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
              }}
            >
              IMAGES
            </Typography>
          </Box>
      {images.length === 0 ? (
        <Typography variant="h6">No images available</Typography>
      ) : (
        <div>
        

          <Grid container spacing={1} sx={{ mt: 5 }}>
            {images.map((image) => (
              <>
                <Grid
                  key={image._id}
                //   sx={{border:"1px solid"}}
                  onClick={() =>
                    openFullScreen(
                      `${process.env.REACT_APP_URL}${image.filePath}`,
                      image.name
                    )
                  }
                  item
                  xs={12}
                  md={4}
                  lg={2}
                >
                  <img
                    src={`${process.env.REACT_APP_URL}${image.filePath}`}
                    // alt={image.name}
                    style={{
                      objectFit: "cover",
                      height: "215px",
                      width: "206px",
                      borderRadius: "16px",
                      border: "3px solid #fff",
                    }}
                  />
                </Grid>
              </>
            ))}
          </Grid>
        </div>
      )}
      {/* Lightbox modal */}
      <Dialog open={lightboxOpen} onClose={closeLightbox}>
        <DialogTitle>{selectedImage.name}</DialogTitle>
        <DialogContent>
          <img
            src={selectedImage.url}
            alt={selectedImage.name}
            style={{ width: "100%", height: "auto", maxWidth: "100%" }}
          />
        </DialogContent>
      </Dialog>
    </Card>
    </Box>
  );
};

export default Images;
