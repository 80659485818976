import { Box, TextField, Typography } from '@mui/material'

import {

    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Button,
    Select,
} from "@mui/material";
import { makeStyles } from '@mui/styles'
import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
const useStyles = makeStyles((theme) => ({

    input: {
        background: '#FFF',
        width: '70%',
        strokeWidth: '1px',
        stroke: '#52A5CF',
        boxShadow: '0px 5px 4px 0px rgba(244, 244, 244, 0.25) inset, 0px -2px 4px 0px rgba(0, 0, 0, 0.25) inset',

    },
    btn: {
        marginTop: '50px',
        borderRadius: '12px',
        background: 'linear-gradient(180deg, #3CAFE4 0%, #046593 100%)',
        width: '281.211px',
        height: '56px',
    }
}))
const initialValues = {
    companyName: '',
    category: '',
    contact: '',
    websiteLink: '',
    instagramLink: ''
}
const CompanyDetails = ({ companyData, setActiveStep, onCompanyChange }) => {
    const navigate = useNavigate()
    const [selectedValue, setSelectedValue] = React.useState("");
    const [data, setData] = React.useState([]);
    const [formValues, setFormValues] = React.useState(initialValues)
    console.log(companyData)
    const getData = async () => {
        const res = await axios.get(
            `${process.env.REACT_APP_URL}companies/dropdown`
        );
        // console.log(res.data.data)
        setData(res.data.data);
    };

    React.useEffect(() => {
        getData();
        setFormValues({
            companyName: companyData?.companyName,
            category: companyData?.industry,
            contact: companyData?.contact,
            websiteLink: companyData?.websiteLink,
            instagramLink: companyData?.instagramLink

        });
    }, []);



    const classes = useStyles()
    const [companyName, setCompanyName] = useState(companyData.companyName || null);
    const [category, setCategory] = useState(companyData.industry || null);
    const [contact, setContact] = useState(companyData.contact || null);
    const [websiteLink, setWebsiteLink] = useState(companyData.websiteLink || null);
    const [instagramLink, setInstagramLink] = useState(
        companyData.instagramLink || null
    );
    const handleUpdate = async () => {
        console.log("Update 1:", companyName, category, contact, websiteLink, instagramLink)
        const body = {
            companyName, category, contact, websiteLink, instagramLink
        }
        const res = await axios.put(
            `${process.env.REACT_APP_URL}companies/update/${companyData._id}`, body
        );
        console.log(res.data)
        onCompanyChange(res.data.data)
        setActiveStep()
    };
    const handleBack = () => {
        navigate('/dashboard/contacts')
    }

    return (
        <div style={{ padding: 15 }}>
            <form >
                <Box>
                    <Typography sx={{
                        fontSize: '14px',
                        fontWeight: 600,
                        mb: 1
                    }}> Company Name</Typography>
                    <TextField
                        value={companyName}
                        className={classes.input}
                        onChange={(e) => setCompanyName(e.target.value)}
                    />
                </Box>

                <Box sx={{ mt: 3 }}>
                    <Typography
                        sx={{ fontSize: "14px", fontWeight: 600, mb: 1 }}
                    >
                        {" "}
                        Category
                    </Typography>
                    <FormControl fullWidth>
                        <Select
                            className={classes.input}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            // label="Industry"
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                        >
                            {data.map((item, ind) => (
                                <MenuItem key={ind} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>


                <Box sx={{ mt: 3 }}>
                    <Typography sx={{
                        fontSize: '14px',
                        fontWeight: 600,
                        mb: 1
                    }}> Phone Number</Typography>
                    <TextField
                        value={contact}
                        className={classes.input}
                        onChange={(e) => setContact(e.target.value)
                        }
                    />
                </Box>
                <Box sx={{ mt: 3 }}>
                    <Typography sx={{
                        fontSize: '14px',
                        fontWeight: 600,
                        mb: 1
                    }}> Website Link</Typography>
                    <TextField
                        value={websiteLink}
                        className={classes.input}
                        onChange={(e) => setWebsiteLink(e.target.value)}
                    />
                </Box>
                <Box sx={{ mt: 3 }}>
                    <Typography sx={{
                        fontSize: '14px',
                        fontWeight: 600,
                        mb: 1
                    }}> Instagram Link</Typography>
                    <TextField
                        value={instagramLink}
                        className={classes.input}
                        onChange={(e) => setInstagramLink(e.target.value)}
                    />
                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Button className={classes.btn} variant='contained'
                        onClick={() => handleBack()}
                        sx={{ mr: 2 }}
                    > Go back </Button>
                    <Button className={classes.btn} variant='contained'
                        onClick={handleUpdate}
                    > Save & Proceed </Button>
                </Box>

            </form>
        </div>
    )
}

export default CompanyDetails
