  import React from "react";
  import {
    Box,
    Button,
    Stack,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Alert,
    Typography,
    Chip,
    Grid,
  } from "@mui/material";
  import Checkbox from "@mui/material/Checkbox"; // ...
  import { useState } from "react";
  import { makeStyles } from "@mui/styles";
  import axios from "axios";
  import { useNavigate } from "react-router";
  import Snack from "../../../components/snackbar/Snack";
  import { useSelector } from "react-redux/es/hooks/useSelector";
  import CircularProgress  from '@mui/material/CircularProgress';
  const i = require("./logo1.png");
  const useStyles = makeStyles((theme) => ({
    textfield: {
      border: "1px solid yellow",
      boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
      borderRadius: "10px",
      "& .MuiInputBase-root": {
        backgroundColor: "#161A24",
        borderRadius: "10px",
        color: "#fff",
      },
      "& fieldset": {
        border: "none",
      },
      "& .MuiSvgIcon-root": {
        color: "#fff",
      }
    },
    backbtn: {
      borderRadius: "10px",
      fontSize: "14px",
      color: "#E3A700",
      height: "40px",
      marginTop: "10px",
      fontVariant: "small-caps",
      background: "#404040",
      border: "2px solid #fff",
      marginRight: "20px",
      boxShadow: "5px 5px 10px #000",
      width: "230px",
      fontWeight: "700",
      "&:hover": {
        backgroundColor: "#404040",
        cursor: "pointer",
      },
    },
    btn: {
      height: "40px",
      width: "230px",
      fontWeight: "700",
      borderRadius: "10px",
      marginTop: "10px",
      marginRight: "20px",
      color: "#000",
      fontVariant: "small-caps",
      background: "#E3A700",
      border: "2px solid #fff",
      fontSize: "14px",
      boxShadow: "5px 5px 10px #000",
      fontVariant: "small-caps",
      // marginBottom: "10px",marginTop:"2px",
      transition: "background-color 0.3s ease",
      "&:hover": {
        backgroundColor: "#E3A700",
        cursor: "pointer",
      },
    },
    label: {
      marginTop: "6px",
      fontSize: "14px",
      fontWeight: "bold",
      color: "white",
      paddingBottom: "5px",
      textShadow: "5px 5px 10px #000",
    },
  }));
  export default function CreateInvoces(props) {
    const initialValues = {
      companyName: "",
      products: [], // Initialize as an array
      invoiceSentDate: "",
    };
    const [products, setProducts] = useState([]);
    const handleChangeProduct = (event) => {
      const {
        target: { value },
      } = event;
      setProducts(typeof value === "string" ? value.split(",") : value);
      console.log("hir", products);
    };
    const user = useSelector((state) => state.auth.user);
    const [error, setError] = React.useState(false);
    const [file, setPdfUrl] = React.useState(null);
    const [message, setMessage] = React.useState("");
    // snack
    const [response, setResponse] = React.useState("");
    const [severity, setSeverity] = React.useState("");
    const [notifyOpen, setNotifyOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const handleClosePop = () => {
      setNotifyOpen(false);
    };
    const navigate = useNavigate();
    const [_formData, setFormData] = React.useState(initialValues);
    const [formDatac, setFormDataC] = useState({
      companyName: [],
      retainerProducts: [],
    });
    // const img1   =require("")
    const handleChange = (e) => {
      const { name, value } = e.target;

      setFormData({ ..._formData, [name]: value });
    };

    const categoryData = [
      {
        _id: "659008e39db2202de0e44507",
        userId: "654235f9ee800e53b4155849",
        productName: "Premium Connected TV ONLY (CTV)",
      },
      {
        _id: "659008e39db0902de0e44569",
        userId: "654235f9ee800e53b5155849",
        productName: "Digital Video Blend",
      },
      {
        _id: "655608e39db2202de0e44569",
        userId: "654235f9ee800e53b5155849",
        productName: "Online Video",
      },
      {
        _id: "159008e39db2202de0e44569",
        userId: "654235f9ee800e53b5155849",
        productName: "Streaming Radio",
      },
      {
        _id: "099008e39db2202de0e44569",
        userId: "654235f9ee800e53b5155849",
        productName: "Geofencing",
      },
      {
        _id: "659055e39db2202de0e4476h6",
        userId: "659008e39db2202de1e44569",
        productName: "Geofencing + Retargeting",
      },
      {
        _id: "659055e39db2202de0e44512",
        userId: "656608e39db2202de0e44569",
        productName: "Geofencing + Data bits+ Retargeting",
      },
      {
        _id: "659055e39db2202de0e44569",
        userId: "654235f9ee800e53b5155849",
        productName: "Geofencing Video",
      },
      {
        _id: "659118e39db2202de0e44569",
        userId: "654235f9ee800e53b5155849",
        productName: "Advanced Email 30k - 49,999",
      },
      {
        _id: "659009739db2202de0e44569",
        userId: "654235f9ee800e53b5155849",
        productName: "Advanced Email 50k+",
      },
      {
        _id: "609008e39db2202de0e44569",
        userId: "654235f9ee800e53b5155849",
        productName: "Banners Awareness",
      },
      {
        _id: "659008e39db2202de0e44560",
        userId: "654235f9ee800e53b5155849",
        productName:
          "Banners Keyword, Contextual, Demo/Behavioral Site Retargeting",
      },
    ];
    React.useEffect(() => {
      axios
        .get(
          `${process.env.REACT_APP_URL}api/retainerinfo/${user.organization._id}`
        )
        .then((res) => {
          const mergedProducts = [...categoryData, ...res.data.retainerProducts];

          setFormDataC({
            ...formDatac,
            companyName: res.data.companies,
            retainerProducts: mergedProducts,
          });
          
        });
    }, []);

    const [_file, setFile] = useState("");
    const handleFileChange = (event) => {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        const fileType = selectedFile.type;
        // Check if the selected file type is either PDF or Word document
        if (fileType === "application/pdf" || fileType === "application/msword" || fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
          setFile(selectedFile);
        } else {
          // Show error message
          setNotifyOpen(true);
          setResponse("Please select a PDF  or Word document file.");
          setSeverity("error");
          event.target.value = null;
        }
      }

    };
    const _handleSub = async (event) => {
      event.preventDefault();
  
      setIsLoading(true);
      const formData = new FormData();
      formData.append("userId", user.organization._id);
      formData.append("companyName", _formData.companyName);
      formData.append("productName", products);
      formData.append("invoiceSentDate", _formData.invoiceSentDate);
      formData.append("file", _file, "invoice.pdf");
      await axios
        .post(`${process.env.REACT_APP_URL}api/invoices`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          setResponse("Invoice Uploaded Successfully");
          setSeverity("success");
          setNotifyOpen(true);
          setFormData({ ..._formData, companyName: "",invoiceSentDate:'' });
          setProducts([])
          setFile('')
          setTimeout(() => {
            setNotifyOpen(false);
          }, 10000);
          setIsLoading(false);
          // props.closeDialog(false);
        })
        .catch((err) => {
          console.log(err);
          setResponse("Internal Server Error");
          setSeverity("error");
          setNotifyOpen(true);
          setIsLoading(false);
          setTimeout(() => {
            setNotifyOpen(false);
          }, 10000);
        });
    };
    const goBack = () => {
      props.closeDialog(false);
    };
    const classes = useStyles();
    return (
      <div>
        <Box>
          <form onSubmit={_handleSub}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 2 }}>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <InputLabel
                  className={classes.label}
                  id="demo-simple-select-label"
                >
                  {" "}
                  Company Name
                </InputLabel>
                <FormControl className={classes.textfield} fullWidth>
                  <Select
                    id="demo-simple-select"
                    value={_formData.companyName}
                    name="companyName"
                    required
                    onChange={handleChange}
                  >
                    {formDatac.companyName.map((categoryItem) => (
                      <MenuItem
                        key={categoryItem._id}
                        value={categoryItem.companyName}
                      >
                        {categoryItem.companyName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <InputLabel
                  className={classes.label}
                  id="demo-simple-select-label"
                >
                  Product Name
                </InputLabel>

                <FormControl className={classes.textfield} fullWidth>
                  <Select
                    required
                    multiple
                    value={products}
                    onChange={handleChangeProduct}
                    renderValue={(selected) => {
                      if (selected.length > 1) {
                        // Adjust '2' based on your preference for when to switch to summary view
                        return `${selected.length} items selected`;
                      }
                      return (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip
                              key={value}
                              label={value}
                              style={{ color: "white" }}
                            />
                          ))}
                        </Box>
                      );
                    }}
                  >
                    {formDatac.retainerProducts.map((categoryItem) => (
                      <MenuItem
                        key={categoryItem._id}
                        value={categoryItem.productName}
                      >
                        <Checkbox
                          checked={
                            products.indexOf(categoryItem.productName) > -1
                          }
                        />
                        {categoryItem.productName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <InputLabel
                  className={classes.label}
                  id="demo-simple-select-label"
                >
                  {" "}
                  invoice Sent Date
                </InputLabel>
                <TextField
                  required
                  name="invoiceSentDate"
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      colorScheme: "dark", // Corrected property name and value
                    },
                  }}
                  value={_formData.invoiceSentDate}
                  onChange={handleChange}
                  type="date"
                  //   placeholder="Enter Full Name here"
                  className={classes.textfield}
                />
              </Grid>
            </Grid>

            {/* <InputLabel id="demo-simple-select-label">
                {" "}
                invoice Paid Date
              </InputLabel>
              <TextField
                //   label="Full Name"
                name="invoicePaidDate"
                value={_formData.invoicePaidDate}
                onChange={handleChange}
                required={true}
                type="date"
                //   placeholder="Enter Full Name here"
                className={classes.textfield}
              /> */}
            <Box>
              <InputLabel
                className={classes.label}
                // sx={{ fontSize: "14px", fontWeight: 600, mb: 1 }}
              >
                Upload file
              </InputLabel>
              <div className={classes.input3} sx={{ padding: "20px" }}>
                <TextField
                  fullWidth
                  required
                  className={classes.textfield}
                  multiple={false}
                  type="file"
                  onChange={handleFileChange}
                  accept="image/*, video/*, .pdf, .doc, .docx"
                />
              </div>
            </Box>
            {error && <Alert severity="error">{message}</Alert>}
            <Box
              sx={{
                justifyContent: "flex-end",
                alignItems: "center",
                display: "flex",
                mt: 2,
              }}
            >
              <Button
                variant="contained"
                className={classes.backbtn}
                onClick={goBack}
              >
                {" "}
                Back{" "}
              </Button>
              <Button
                variant="contained"
                // type="button"
                disabled={isLoading}
                className={classes.btn}
                type="submit"
              >
                {
                  isLoading? (<CircularProgress size={24}/>) : ("Upload Invoice")
                }
                
              </Button>
            </Box>
          </form>
        </Box>
        <Box></Box>
        <Snack
          open={notifyOpen}
          message={response}
          severity={severity}
          handleClose={handleClosePop}
        />
      </div>
    );
  }
