import React from "react";
import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
  Typography,
  Box,
  TableContainer,
  Card,
  Pagination,
} from "@mui/material";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from "@mui/icons-material/Email";
import Gradients from "../../utils/Gradients";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Searchbar from "../../components/SearchBar/Searchbar";
const TableHeader = styled(TableHead)(({ theme }) => ({
  "& th": {
    marginButtom: "10px",
    borderBottom: "0px",
    border: "none",
    "&:first-child": {
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
    },
    "&:last-child": {
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "10px",
    },
  },
}));
const useStyles = makeStyles((theme) => ({
  evenRow: {
    color: "#ffffff",
   
    backgroundColor: "#979797",
    transition: "transform 0.2s ease-in-out",
    "&:hover": {
      transform: "scale(1.01)", 
      boxShadow: " 0 0 10px rgba(0, 0, 0, 0.5)", // Add shadow on hover
      backgroundColor: "#6B6B6B",

    },
  },
  oddRow: {
   
    backgroundColor: "#979797",
    // borderRadius: "20px",
    color: "#ffffff",
    // Odd row color
    transition: "transform 0.2s ease-in-out",
    "&:hover": {
      transform: "scale(1.01)", 
      boxShadow: " 0 0 10px rgba(0, 0, 0, 0.5)", // Add shadow on hover
      backgroundColor: "#6B6B6B",

    },
  },

  tableCell: {
    color: "#ffffff",
  },
  row: {
    color: "#ffffff",
        transition: "transform 0.2s ease-in-out",
        "&:hover": {
          transform: "scale(1.01)", 
          boxShadow: " 0 0 10px rgba(0, 0, 0, 0.5)", // Add shadow on hover

        },
  },
  card: {
    padding: "25px",
    border: "2px solid #fff",
    background: "#979797",
    borderRadius: "16px",
    color: "#fff",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
  },
  tableCell: {
    color: "#ffffff",
  },
  row: {
    color: "#ffffff",
    "&:hover $cell": {
      color: "#ffffff",
    },
  },
  cell: {
    fontSize: "1.2rem",
    fontWeight: 500,
    letterSpacing: 1,
    color: "#FFF",
    border: "none",
  },
  search: {
    border: "1px solid #fff",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
    borderRadius: "10px",
    "& .MuiInputBase-root": {
      backgroundColor: "#C9975C",
      borderRadius: "10px",
      color: "#fff",
    },
    "& fieldset": {
      border: "none",
    },
  },
  // card: {
  //   padding: "25px",
  //   border: "2px solid #fff",
  //   background: "#787676",
  //   borderRadius: "16px",
  //   color: "#fff",
  //   boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
  // },
  btn: {
    borderRadius: "10px",
    marginTop: "20px",
    marginRight: "20px",
    color: "#787676",
    fontVariant: "small-caps",
    background: "#E3A700",
    border: "1px solid #fff",
    fontSize: "14px",
    boxShadow: "5px 5px 10px #000",
    fontVariant: "small-caps",
    marginBottom: "10px",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#E3A700",
      cursor: "pointer",
    },
  },
}));
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  // borderRadius: "10px",
  overflow: "hidden",
}));

const Crm = () => {
  const classes = useStyles();
  const user = useSelector((state) => state.auth.user);

  const [contactsData, setContactsData] = React.useState([]);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [usersData, setUsersData] = React.useState([]);
  const itemsPerPage = 5;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const [filteredContacts, setFilteredContacts] = React.useState([]);

  const getContactsData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}companies/firstContact/all/${user.organization._id}`
      );
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      setContactsData(res.data.data);
      setFilteredContacts(res.data.data.slice(startIndex, endIndex)); // Initialize filteredContacts with all data
      const totalUsers = res.data.data.length;
      setTotalPages(Math.ceil(totalUsers / itemsPerPage));

      // Slice the data based on pagination
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  React.useEffect(() => {
    getContactsData();
  }, [currentPage]);

  const navigate = useNavigate();

  const postData = (data) => {
    navigate(`/dashboard/company-crm`, { state: data });
  };

  const handleChange = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    if (searchTerm === "") {
      // If search term is empty, reset pagination and display all contacts
      setCurrentPage(1); // Reset pagination to the first page
      const startIndex = 0;
      const endIndex = startIndex + itemsPerPage;
      setFilteredContacts(contactsData.slice(startIndex, endIndex));
    } else {
      // Filter contacts based on search term
      const filteredData = contactsData.filter(
        (data) =>
          data.companyName?.toLowerCase()?.includes(searchTerm) ||
          "" ||
          data.firstContact?.email?.toLowerCase()?.includes(searchTerm) ||
          ""
      );
      setFilteredContacts(filteredData);
    }
  };
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  return (
    <Box sx={{ height: "100vh" }}>
      <Card
        className={classes.card}
        sx={{ height: "600px", position: "relative" }}
      >
        <Searchbar search={classes.search} onChange={handleChange} />
        <StyledTableContainer>
          <Table>
            <TableHeader sx={{ background: Gradients.cApU }}>
              <TableRow sx={{ borderTopLeftRadius: "10px" }}>
                <TableCell
                  sx={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    color: "#fff",
                    letterSpacing: 1,
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    color: "#fff",
                    letterSpacing: 1,
                  }}
                >
                  Company
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    color: "#fff",
                    letterSpacing: 1,
                  }}
                >
                  Email
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    color: "#fff",
                    letterSpacing: 1,
                  }}
                >
                  Title
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    color: "#fff",
                    letterSpacing: 1,
                  }}
                >
                  Phone
                </TableCell>
              </TableRow>
            </TableHeader>

            <TableBody sx={{ color: "#fff" }}>
              {filteredContacts.map((data, index) => (
                <TableRow
                  key={index}
                  className={index % 2 === 0 ? classes.evenRow : classes.oddRow}
                  sx={{
                    cursor: "pointer",
                    borderRadius: "20px",
                    color: "#fff",
                  }}
                  // component={Link}
                  // to='/dashboard/company-crm'
                  onClick={() => postData(data)}
                >
                  <TableCell
                    sx={{ display: "flex", alignItems: "center" }}
                    className={classes.cell}
                  >
                    <Avatar src="/assets/images/2.png" />
                    <Typography sx={{ fontSize: "1.2rem" }}>
                      {data.firstContact.name}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {data.companyName}
                  </TableCell>
                  <TableCell className={classes.cell}>
                  <EmailIcon sx={{ margin: "-7px 5px" }} /> {data.firstContact.email}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {data.firstContact.title}
                  </TableCell>
                  <TableCell className={classes.cell}>   <LocalPhoneIcon sx={{ margin: "-7px 5px" }} />{data.contact}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box
            sx={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Pagination
              count={totalPages}
              // count={10}
              page={currentPage}
              onChange={(event, page) => setCurrentPage(page)}
              color="primary"
              sx={{
                position: "absolute",
                bottom: 20,
                "& .MuiPaginationItem-root": {
                  background: "#A4A4A4",
                  border: "1px solid #fff",
                  boxShadow: "5px 5px 10px #000",
                  color: "#000",
                  "&:hover": {
                    color: "#FFF",
                    background: "#E1A800",
                  },
                  "&.Mui-selected": {
                    background: "#E1A800",
                  },
                },

                "& .MuiPaginationItem-icon": {
                  color: "#000", // Set color of the pagination icon to white
                },
              }}
            />
          </Box>
        </StyledTableContainer>
      </Card>
    </Box>
  );
};

export default Crm;
