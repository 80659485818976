import { Box, Button, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useState } from 'react'
import { useEffect } from 'react'
import PropTypes from 'prop-types';
import axios from 'axios';
const useStyles = makeStyles((theme) => ({
    input: {
        background: '#FFF',
        width: '70%',
        strokeWidth: '1px',
        stroke: '#52A5CF',
        boxShadow: '0px 5px 4px 0px rgba(244, 244, 244, 0.25) inset, 0px -2px 4px 0px rgba(0, 0, 0, 0.25) inset',

    },
    btn: {
        marginTop: '50px',
        borderRadius: '12px',
        background: 'linear-gradient(180deg, #3CAFE4 0%, #046593 100%)',
        width: '281.211px',
        height: '56px',
    }
}))
const NotesAndLeads = ({ companyData, setActiveStep }) => {
    const classes = useStyles()
    const [leadType, setLeadType] = useState(companyData?.leads?.[0]?.type || '');
    const [leadNotes, setLeadNotes] = useState(companyData?.leads?.[0]?.notes || '');

    // NotesAndLeads.propTypes = {
    //     companyData: PropTypes.object.isRequired,
    //     onNotesAndLeadsChange: PropTypes.func.isRequired,
    //   };

    const handleLeadTypeChange = (event) => {
        setLeadType(event.target.value);
    };

    const handleLeadNotesChange = (event) => {
        setLeadNotes(event.target.value);
    };
    const handleBack = () => {
        setActiveStep('decrement');
    };

    const handleUpdate = async() => {
        console.log("Update 3:", leadType, leadNotes)
        const body = {
            type: leadType,
            notes: leadNotes,
            companyId: companyData._id
        }
        const res = await axios.post(`${process.env.REACT_APP_URL}companies/updatelead`, body);
        console.log(res.data.data, 'api response')
        setActiveStep('increment')
    };

    // useEffect(() => {
    //     // Trigger callback function when data changes
    //     onNotesAndLeadsChange({
    //         leadType,
    //         leadNotes,
    //     });
    // }, [leadType, leadNotes, onNotesAndLeadsChange]);
    return (
        <div>
            <Box>
                <Typography sx={{
                    fontSize: '14px',
                    fontWeight: 600,
                    mb: 1
                }}> Type of Lead</Typography>
                <TextField
                    // value={companyData.leads[0].type}
                    value={leadType}
                    placeholder='Working'
                    className={classes.input}
                    onChange={(e) => setLeadType(e.target.value)}
                />
            </Box>
            <Box sx={{ mt: 3 }}>
                <Typography sx={{
                    fontSize: '14px',
                    fontWeight: 600,
                    mb: 1
                }}> Notes</Typography>
                <TextField
                    // value={companyData.leads[0].notes}
                    value={leadNotes}
                    placeholder='Start Writing from here'
                    multiline
                    rows={10}
                    onChange={(e) => setLeadNotes(e.target.value)}
                    className={classes.input}
                />
            </Box>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Button className={classes.btn} variant='contained'
                    onClick={handleBack}
                    sx={{ mr: 2 }}
                > Go back </Button>
                <Button className={classes.btn} variant='contained'
                    onClick={() => handleUpdate()}
                > Save & Proceed </Button>
            </Box>
        </div>
    )
}

export default NotesAndLeads
