import React from "react";
import {
  Box,
  Button,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Alert,
  Grid,
} from "@mui/material";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { useNavigate } from "react-router";
import Snack from "../../../components/snackbar/Snack";
const useStyles = makeStyles((theme) => ({
  input: {
    border: "1px solid yellow",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
    borderRadius: "10px",
    "& .MuiInputBase-root": {
      backgroundColor: "#161A24",
      borderRadius: "10px",
      color: "#fff",
    },
    "& fieldset": {
      border: "none",
    },
    "& .MuiSvgIcon-root": {
      color: "#fff",
    },
  },
  label: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#fff",
    marginTop: "6px",
    marginBottom: "5px",
    textShadow: "5px 5px 10px #000 ",
  },
  btn: {
    height: "40px",
    width: "230px",
    fontWeight: "700",
    borderRadius: "10px",
    marginTop: "10px",
    marginRight: "20px",
    color: "#000",
    fontVariant: "small-caps",
    background: "#E3A700",
    border: "2px solid #fff",
    fontSize: "14px",
    boxShadow: "5px 5px 10px #000",
    fontVariant: "small-caps",
    marginBottom: "10px",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#E3A700",
      cursor: "pointer",
    },
  },
  backbtn: {
    borderRadius: "10px",
    fontSize: "14px",
    color: "#E3A700",
    height: "40px",
    marginTop: "10px",
    fontVariant: "small-caps",
    background: "#404040",
    border: "2px solid #fff",
    marginRight: "20px",
    boxShadow: "5px 5px 10px #000",
    width: "230px",
    fontWeight: "700",
    "&:hover": {
      backgroundColor: "#404040",
      cursor: "pointer",
    },
  },
}));
const initialValues = {
  // productName: "",
  baseCpm: "",
  budget: "",
  override: 0,
  // impressions: "",
  // clientCpm: "",
  // zenonCommission: "",
  value: "",
  category: "",
};
const CreateProduct = (props) => {
  const user = useSelector((state) => state.auth.user);
  const [error, setError] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [formValues, setFormValues] = React.useState(initialValues);
  const [impressions, setimpressions] = React.useState(0);
  const [zenon_Commission, setZenonCommission] = React.useState(0);
  const [clientCpm, setclientCpm] = React.useState(0);
  const [showAlert, setShowAlert] = React.useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setShowAlert(false);
    if (formValues.category == "") {
      if (name === "category") {
        Formula(formValues.budget, formValues.override, value);
        setFormValues({ ...formValues, [name]: value });
      } else {
        setShowAlert(true);
      }
    } else {
      if (name === "category") {
        Formula(formValues.budget, formValues.override, value);
      }
      if (name === "budget") {
        if (value > 0) {
          Formula(value, formValues.override, formValues.category);
        }
      }
      if (name === "override") {
        Formula(formValues.budget, value, formValues.category);
      }
      if (name === "budget") {
        if (value >= 0) {
          setFormValues({ ...formValues, [name]: value });
        }
      } else {
        if (value >= 0) {
          setFormValues({ ...formValues, [name]: value });
        }
      }
    }
  };

  // snack
  const [response, setResponse] = React.useState("");
  const [severity, setSeverity] = React.useState("");
  const [notifyOpen, setNotifyOpen] = React.useState(false);
  const handleClosePop = () => {
    setNotifyOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const body = {
        productName: formValues.category,
        // baseCpm: formValues.value,
        budget: formValues.budget,
        override: formValues.override,
        impressions: impressions,
        clientCpm: clientCpm,
        zenonCommission: zenon_Commission,
      };

      console.log("Body", body);
      const data = await axios.post(
        `${process.env.REACT_APP_URL}api/products/${user.organization._id}`,
        body
      );
      setResponse("Product submitted successfully!");
      // alert(res.data.message)
      setSeverity("success");
      setNotifyOpen(true);
      console.log(formValues);
      setFormValues(initialValues);
      setTimeout(() => {
        navigate("/dashboard/products");
      }, 2000);
    } catch (error) {
      console.log(error);
      setError(true);
      setSeverity("danger");
      setMessage("An error occurred while registering the Product.");
    }
  };
  const classes = useStyles();
  const navigate = useNavigate();

  const categoryData = [
    { val: 29, name: "Premium Connected TV ONLY (CTV)" },
    { val: 24, name: "Digital Video Blend" },
    { val: 13, name: "Online Video" },
    { val: 13, name: "Streaming Radio" },
    { val: 4, name: "Geofencing" },
    { val: 4.25, name: "Geofencing + Retargeting" },
    { val: 4.5, name: "Geofencing + Data bits+ Retargeting" },
    { val: 13, name: "Geofencing Video" },
    { val: 9, name: "Advanced Email 30k - 49,999" },
    { val: 8, name: "Advanced Email 50k+" },
    { val: 3.5, name: "Banners Awareness" },
    {
      val: 3.75,
      name: "Banners Keyword, Contextual, Demo/Behavioral Site Retargeting",
    },
  ];
  const findValue = (categoryName) => {
    const foundItem = categoryData.find((item) => item.name === categoryName);
    return foundItem; // Return the found item
  };
  const goBack = () => {
    props.closeDialog(false);
  };
  const Formula = (E4, C5, Selectvalue) => {
    const selectedCategory = findValue(Selectvalue);
    console.log("selectev value +>", selectedCategory.val);
    const Impressio = ((E4 * (100 - C5)) / 100 / selectedCategory.val) * 1000;
    const zenon = (E4 * C5) / 100;
    const clientCpm = (1000 * E4) / Impressio.toFixed(2);
    setimpressions(Impressio.toFixed(2));
    setZenonCommission(zenon);
    setclientCpm(clientCpm.toFixed(2));

    // const zenon_Commission=budget*percentage override/100
    // const Cleint_CPM
  };

  return (
    <div>
      <Box>
        <form onSubmit={handleSubmit}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 2 }}>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              {" "}
              <Box>
                <Typography className={classes.label}> Product Name</Typography>
                <InputLabel id="demo-simple-select-label">
                  {" "}
                  {formValues.value}{" "}
                </InputLabel>
                <FormControl className={classes.input} fullWidth>
                  <Select
                    required
                    id="demo-simple-select"
                    value={formValues.category}
                    name="category"
                    label="Age"
                    onChange={handleChange}
                  >
                    {categoryData.map((categoryItem) => (
                      <MenuItem
                        key={categoryItem.val}
                        value={categoryItem.name}
                      >
                        {categoryItem.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {showAlert && (
                  <Typography pt={1} color={"red"}>
                    Please Select the Product First
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <Box sx={{ position: "relative", width: "100%" }}>
                <Typography className={classes.label}> Budget ($)</Typography>
                <TextField
                  fullWidth
                  required
                  type="number"
                  className={classes.input}
                  name="budget"
                  value={formValues.budget}
                  onChange={handleChange}
                  sx={{
                    "& .MuiInputBase-root": { paddingLeft: "15px" },
                  }}
                />
                <Box sx={{ position: "absolute", bottom: 18, left: 15 }}>
                  <Typography color={"#fff"}>$</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
            <Box sx={{ position: "relative", width: "100%" }}>
                <Typography className={classes.label}> Override (%)</Typography>
                <TextField
                  fullWidth
                  type="number"
                  required
                  className={classes.input}
                  name="override"
                  value={formValues.override}
                  onChange={handleChange}
                />
                 <Box sx={{ position: "absolute", bottom: 18, right: 15 }}>
                    <Typography color={"#fff"}>%</Typography>
                  </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 2 }}>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <Box>
                <Typography className={classes.label}> Impressions</Typography>
                <TextField
                  fullWidth
                  type="number"
                  className={classes.input}
                  name="impressions"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={impressions}
                  onChange={handleChange}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              {" "}
              <Box>
                <Typography className={classes.label}> Cleint CPM</Typography>
                <TextField
                  fullWidth
                  type="number"
                  className={classes.input}
                  name="clientCpm"
                  value={clientCpm}
                  InputProps={{
                    readOnly: true,
                  }}
                  onChange={handleChange}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
            <Box sx={{ position: "relative", width: "100%" }}>
                <Typography className={classes.label}>
                  {" "}
                  Zenon Commission ($)
                </Typography>
                <TextField
                  fullWidth
                  type="number"
                  className={classes.input}
                  name="zenonCommission"
                  sx={{
                    "& .MuiInputBase-root": {paddingLeft:"15px"}
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={zenon_Commission}
                  onChange={handleChange}
                />
                 <Box sx={{ position: "absolute", bottom: 18, left: 15 }}>
                    <Typography color={"#fff"}>$</Typography>
                  </Box>
              </Box>
            </Grid>
          </Grid>

          {error && <Alert severity="error">{message}</Alert>}
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              variant="contained"
              className={classes.backbtn}
              onClick={goBack}
            >
              {" "}
              Back{" "}
            </Button>
            <Button
              variant="contained"
              type="submit"
              className={classes.btn}
              sx={{ ml: 2 }}
            >
              {" "}
              Create Product{" "}
            </Button>
          </Box>
        </form>
      </Box>
      <Snack
        open={notifyOpen}
        message={response}
        severity={severity}
        handleClose={handleClosePop}
      />
    </div>
  );
};

export default CreateProduct;
