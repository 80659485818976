import React from "react";
import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
  Typography,
  Box,
  TableContainer,
  Card,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Pagination,
} from "@mui/material";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from "@mui/icons-material/Email";
import MyDialog from "../../components/Dialog/MyDialog";
import { useDispatch, useSelector } from "react-redux";
import Gradients from "../../utils/Gradients";
import { makeStyles } from "@mui/styles";

import ButtonGroup from "@mui/material/ButtonGroup";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Searchbar from "../../components/SearchBar/Searchbar";
import { useState } from "react";
import CreateIcon from "@mui/icons-material/Create";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CreateContact from "./components/CreateContact";

const TableHeader = styled(TableHead)(({ theme }) => ({
  "& th": {
    marginButtom: "10px",
    borderBottom: "0px",
    border: "none",
    "&:first-child": {
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
    },
    "&:last-child": {
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "10px",
    },
  },
}));

const ColorGroup = ["#ce93d8", "#81c784", "#ffb74d"];
function ColorGroupMap() {
  let colors = ColorGroup.map((color) => {
    return color;
  });
  return colors;
}
console.log(ColorGroup.map((color) => color));
console.log(ColorGroupMap());
const backgroundStyles = ColorGroup.map((color) => ({
  background: color,
}));
const useStyles = makeStyles((theme) => ({
  evenRow: {
    color: "#ffffff",

    backgroundColor: "#979797",
    transition: "transform 0.2s ease-in-out",
    "&:hover": {
      transform: "scale(1.01)",
      boxShadow: " 0 0 10px rgba(0, 0, 0, 0.5)", // Add shadow on hover
      backgroundColor: "#6B6B6B",
    },
  },
  oddRow: {
    backgroundColor: "#979797",
    // borderRadius: "20px",
    color: "#ffffff",
    // Odd row color
    transition: "transform 0.2s ease-in-out",
    "&:hover": {
      transform: "scale(1.01)",
      boxShadow: " 0 0 10px rgba(0, 0, 0, 0.5)", // Add shadow on hover
      backgroundColor: "#6B6B6B",
    },
  },

  tableCell: {
    color: "#ffffff",
  },
  row: {
    color: "#ffffff",
    transition: "transform 0.2s ease-in-out",
    "&:hover": {
      transform: "scale(1.01)",
      boxShadow: " 0 0 10px rgba(0, 0, 0, 0.5)", // Add shadow on hover
    },
  },
  card: {
    padding: "25px",
    border: "2px solid #fff",
    background: "#979797",
    borderRadius: "16px",
    color: "#fff",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
  },
  cell: {
    fontSize: "1rem",
    // fontWeight: 500,
    // letterSpacing: 1,s
    color: "#FFF",
    border: "none",
  },
  btng: {
    marginBottom: "2rem",
  },
  // btn: {
  //   fontSize: "16px",
  //   fontWeight: 700,
  //   textTransform: "none",
  //   background: "#fff",
  //   color: "#000",
  //   boxShadow: "none",
  //   "&:hover": {
  //     background: "#E6F6FE",
  //   },
  // },

  search: {
    border: "1px solid #fff",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
    borderRadius: "10px",
    "& .MuiInputBase-root": {
      backgroundColor: "#C9975C",
      borderRadius: "10px",
      color: "#fff",
    },
    "& fieldset": {
      border: "none",
    },
  },
  btn: {
    width: "180px",
    borderRadius: "10px",
    marginTop: "20px",
    marginRight: "20px",
    color: "#000",
    fontWeight: "700",
    fontVariant: "small-caps",
    background: "#E3A700",
    border: "1px solid #fff",
    fontSize: "14px",
    boxShadow: "5px 5px 10px #000",
    fontVariant: "small-caps",
    marginBottom: "10px",
    fontWeight: "700",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#E3A700",
      cursor: "pointer",
    },
  },
}));
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  // borderRadius: "10px",
  // border: "2px solid #000",
  // padding: "5px",
  overflow: "hidden",
}));
const Contacts = () => {
  const user = useSelector((state) => state.auth.user);
  const [open, setOpen] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [contactsData, setContactsData] = React.useState([]);
  const [filteredContacts, setFilteredContacts] = React.useState([]);
  const [singleData, setSingleData] = useState("");
  const itemsPerPage = 5;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setFilteredContacts(contactsData.slice(startIndex, endIndex));
  };
  const [openDialog, setOpenDialog] = useState(false);
  const [delId, setDelId] = React.useState("");
  const [openUpdateDialog, setUpdateOpenDialog] = useState(false);
  const handleDialogClose = (data) => {
    setOpenDialog(data);
    getContactsData();
  };
  const handleUpdateDialogClose = (data) => {
    setUpdateOpenDialog(data);
    getContactsData();
  };
  const handleUpdateDialogOpen = (data) => {
    setSingleData(data);
    setUpdateOpenDialog(true);
  };
  const handleClickOpenPop = (id) => {
    setDelId(id);
    setOpen(true);
  };
  const handleClosePop = () => {
    setOpen(false);
    // setNotifyOpen(false);
  };
  const handleDeleteClick = async (data) => {
    // Handle the delete logic here
    console.log("Delete button clicked", data._id);
    const res = await axios.delete(
      `${process.env.REACT_APP_URL}companies/delete/${delId}`
    );
    console.log(res);
    handleClosePop();
    getContactsData();
    // You can perform your delete operation or show a confirmation dialog here
  };

  const getContactsData = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_URL}companies/firstContact/all/${user.organization._id}`
    );

    const totalUsers = res.data.data.length;
    setTotalPages(Math.ceil(totalUsers / itemsPerPage));

    // Slice the data based on pagination
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setContactsData(res.data.data);
    setFilteredContacts(res.data.data.slice(startIndex, endIndex));
  };
  React.useEffect(() => {
    getContactsData();
  }, [currentPage]);

  console.log(contactsData);

  const classes = useStyles();
  const navigate = useNavigate();
  const [singleContact, setSingleContact] = useState("");
  const postData = async (data) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}companies/Read/${data._id}`
      );

      setSingleContact(res.data.data);
      // setOpenDialog(true);
      // navigate(`/dashboard/contact-detail`, { state: res.data.data });
    } catch (error) {
      console.error("Error fetching contacts data:", error);
      // Optionally, show an error message to the user
    }
  };
  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleChange = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    if (searchTerm === "") {
      // If search term is empty, reset pagination and display all contacts
      setCurrentPage(1); // Reset pagination to the first page
      const startIndex = 0;
      const endIndex = startIndex + itemsPerPage;
      setFilteredContacts(contactsData.slice(startIndex, endIndex));
    } else {
      // Filter contacts based on search term
      const filteredData = contactsData.filter(
        (data) =>
          data.companyName?.toLowerCase()?.includes(searchTerm) ||
          "" ||
          data.firstContact?.email?.toLowerCase()?.includes(searchTerm) ||
          ""
      );
      setFilteredContacts(filteredData);
    }
  };
  return (
    <Box sx={{ height: "100vh" }}>
      <Card
        className={classes.card}
        sx={{ height: "600px", position: "relative" }}
      >
        <Searchbar search={classes.search} onChange={handleChange} />
        <StyledTableContainer>
          <Table>
            <TableHeader sx={{ background: Gradients.cApU }}>
              <TableRow sx={{ borderTopLeftRadius: "10px" }}>
                <TableCell
                  sx={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    color: "#fff",
                    letterSpacing: 1,
                    textShadow: "5px 5px 10px #000",
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    color: "#fff",
                    letterSpacing: 1,
                    textShadow: "5px 5px 10px #000",
                  }}
                >
                  Company
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    color: "#fff",
                    letterSpacing: 1,
                    textShadow: "5px 5px 10px #000",
                  }}
                >
                  Email
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    color: "#fff",
                    letterSpacing: 1,
                    textShadow: "5px 5px 10px #000",
                  }}
                >
                  Title
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    color: "#fff",
                    letterSpacing: 1,
                    textShadow: "5px 5px 10px #000",
                  }}
                >
                  Phone
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    color: "#fff",
                    letterSpacing: 1,
                    textShadow: "5px 5px 10px #000",
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHeader>

            <TableBody sx={{ color: "#fff" }}>
              {filteredContacts.length > 0 &&
                filteredContacts.map((data, index) => (
                  // <>{console.log('filteredContacts.length',filteredContacts.length)}</>
                  <TableRow
                    key={index}
                    className={`${classes.row} ${
                      index % 2 === 0 ? classes.evenRow : classes.oddRow
                    }`}
                    sx={{
                      cursor: "pointer",
                      borderRadius: "20px",
                      color: "#fff",
                    }}
                    // component={Link}
                    // to='/dashboard/company-crm'
                    // onClick={() => postData(data)}
                  >
                    {console.log("filteredContacts.length", data)}
                    <TableCell
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        // borderTopLeftRadius: "8px",
                        // borderBottomLeftRadius: "8px",
                      }}
                      className={classes?.cell}
                    >
                      <Avatar src="/assets/images/2.png" />
                      <Typography sx={{ fontSize: "1.2rem" }}>
                        {data?.firstContact?.name}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {data.companyName}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <EmailIcon sx={{ margin: "-7px 5px" }} />
                      {data?.firstContact?.email}
                    </TableCell>

                    <TableCell className={classes.cell}>
                      {data?.firstContact?.title}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <LocalPhoneIcon sx={{ margin: "-7px 5px" }} />
                      {data?.contact}
                    </TableCell>
                    <TableCell
                      className={classes.cell}
                      // sx={{
                      //   borderTopRightRadius: "8px",
                      //   borderBottomRightRadius: "8px",
                      // }}
                    >
                      {" "}
                      <Box sx={{ display: "flex" }}>
                        <Avatar
                          onClick={() => handleUpdateDialogOpen(data)}
                          sx={{
                            color: "#000",
                            background: "#E3A700",
                            boxShadow: "5px 5px 10px #000",
                            border: "1px solid #fff",
                          }}
                          // onClick={() => postData(data)}
                        >
                          <CreateIcon sx={{ color: "#000" }} />
                        </Avatar>

                        <Avatar
                          sizes="sm"
                          sx={{
                            color: "#E3A700",
                            background: "#404040",
                            marginLeft: "10px",
                            boxShadow: "5px 5px 10px #000 ",
                            border: "1px solid #fff",
                          }}
                          onClick={() => handleClickOpenPop(data._id)}
                        >
                          <DeleteForeverIcon fontSize="sm" />
                        </Avatar>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <Dialog
            fullWidth
            maxWidth="lg"
            PaperProps={{
              style: {
                background: " linear-gradient(#4F3B03, #E3A700,#503D06)",
                border: "3px solid #fff",
                borderRadius: "24px",
              },
            }}
            open={openUpdateDialog}
            onClose={handleUpdateDialogClose}
          >
            <DialogTitle
              sx={{
                color: "#FAFF00",
                textShadow:
                  "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
              }}
            >
              Update Contacts
            </DialogTitle>
            <DialogContent>
              <CreateContact
                closeDialog={handleUpdateDialogClose}
                state={singleData}
                check={true}
              />
            </DialogContent>
          </Dialog>
        </StyledTableContainer>
        <Dialog
          fullWidth
          maxWidth="lg"
          PaperProps={{
            style: {
              background: " linear-gradient(#4F3B03, #E3A700,#503D06)",
              border: "3px solid #fff",
              borderRadius: "24px",
            },
          }}
          open={openDialog}
          state={null}
          onClose={handleDialogClose}
        >
          <DialogTitle
            sx={{
              color: "#FAFF00",
              textShadow:
                "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
            }}
          >
            Create Contacts
          </DialogTitle>
          <DialogContent>
            {/* <CreateUser closeDialog={handleDialogClose} /> */}
            <CreateContact
              closeDialog={handleDialogClose}
              check={false}
              state={""}
            />
          </DialogContent>
        </Dialog>
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Pagination
            count={totalPages}
            // count={10}
            page={currentPage}
            onChange={(event, page) => setCurrentPage(page)}
            color="primary"
            sx={{
              position: "absolute",
              bottom: 20,
              "& .MuiPaginationItem-root": {
                boxShadow: "5px 5px 10px #000",
                border: "1px solid #fff",
                color: "#000",
                "&:hover": {
                  color: "#FFF",
                  background: "#E1A800",
                },
                "&.Mui-selected": {
                  background: "#E1A800",
                },
              },

              "& .MuiPaginationItem-icon": {
                color: "#000", // Set color of the pagination icon to white
              },
            }}
          />
        </Box>
      </Card>
      <MyDialog
        open={open}
        handleClose={handleClosePop}
        handleDelete={handleDeleteClick}
      />
      {/* <Snack
          open={notifyOpen}
          message={"User Deleted Successfully"}
          severity="error"
          handleClose={handleClosePop}
        /> */}
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button className={classes.btn} onClick={handleDialogOpen}>
          + New Contact
        </Button>
      </Box>
    </Box>
  );
};

export default Contacts;
