import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
  Typography,
  Box,
  TableContainer,
  Card,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import React from "react";
import Gradients from "../../utils/Gradients";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import MyDialog from "../../components/Dialog/MyDialog";
import Snack from "../../components/snackbar/Snack";
import Searchbar from "../../components/SearchBar/Searchbar";
import Pagination from "@mui/material/Pagination";
import { useState } from "react";
import CreateUser from "./components/CreateUser";
import UpdateUser from "./../updateUser/UpdateUser";
import CreateIcon from "@mui/icons-material/Create";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
const TableHeader = styled(TableHead)(({ theme }) => ({
  "& th": {
    marginButtom: "10px",
    borderBottom: "0px",
    border: "none",
    "&:first-child": {
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
    },
    "&:last-child": {
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "10px",
    },
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // "&:hover": {
  //   background: "linear-gradient(180deg, #9C9C9C 0%, #F3E3CC 100%)",
  // },
}));

const useStyles = makeStyles((theme) => ({
  evenRow: {
    color: "#ffffff",

    backgroundColor: "#979797",
    transition: "transform 0.2s ease-in-out",
    "&:hover": {
      transform: "scale(1.01)",
      boxShadow: " 0 0 10px rgba(0, 0, 0, 0.5)", // Add shadow on hover
      backgroundColor: "#6B6B6B",
    },
  },
  oddRow: {
    backgroundColor: "#979797",
    // borderRadius: "20px",
    color: "#ffffff",
    // Odd row color
    transition: "transform 0.2s ease-in-out",
    "&:hover": {
      transform: "scale(1.01)",
      boxShadow: " 0 0 10px rgba(0, 0, 0, 0.5)", // Add shadow on hover
      backgroundColor: "#6B6B6B",
    },
  },

  tableCell: {
    color: "#ffffff",
  },
  row: {
    color: "#ffffff",
    transition: "transform 0.2s ease-in-out",
    "&:hover": {
      transform: "scale(1.01)",
      boxShadow: " 0 0 10px rgba(0, 0, 0, 0.5)", // Add shadow on hover
    },
  },
  card: {
    padding: "25px",
    border: "2px solid #fff",
    background: "#979797",
    borderRadius: "16px",
    color: "#fff",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
  },
  cell: {
    fontSize: "1.2rem",
    fontWeight: 500,
    letterSpacing: 1,
    color: "#FFF",
    border: "none",
  },
  search: {
    border: "1px solid #fff",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
    borderRadius: "10px",
    "& .MuiInputBase-root": {
      backgroundColor: "#C9975C",
      borderRadius: "10px",
      color: "#fff",
    },
    "& fieldset": {
      border: "none",
    },
  },
  // card: {
  //   padding: "25px",
  //   border: "2px solid #fff",
  //   background: "#787676",
  //   borderRadius: "16px",
  //   color: "#fff",
  //   boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
  // },

  btn: {
    width: "180px !important",
    borderRadius: "10px !important",
    marginTop: "10px !important",
    marginRight: "20px !important",
    color: "#000 !important",
    fontWeight: "bold",
    fontVariant: "small-caps",
    background: "#E3A700",
    border: "1px solid #fff",
    fontSize: "14px !important",
    boxShadow: "5px 5px 10px #000 !important",
    fontVariant: "small-caps",
    marginBottom: "10px !important",
    fontWeight: "700 !important",

    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#E3A700",
      cursor: "pointer",
    },
  },
}));
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  // borderRadius: "20px",

  overflow: "hidden",
}));

const Users = () => {
  const [open, setOpen] = React.useState(false);
  const [notFound, SetNotFound] = React.useState(null);
  const [resStatus, setResStatus] = React.useState(null);
  const [delId, setDelId] = React.useState(""); // Store user ID to delete
  const [notifyOpen, setNotifyOpen] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [usersData, setUsersData] = React.useState([]);
  const itemsPerPage = 5;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const user = useSelector((state) => state.auth.user);
  const handleClosePop = () => {
    setOpen(false);
    setNotifyOpen(false);
  };

  const handleClickOpenPop = (id) => {
    setDelId(id);
    console.log(id);
    setOpen(true);
  };
  const navigate = useNavigate();
  const getUsersData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}users/ReadAll/${user.organization._id}`
      );
      const totalUsers = res.data.data.length;
      setTotalPages(Math.ceil(totalUsers / itemsPerPage));

      // Slice the data based on pagination
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      setUsersData(res.data.data.slice(startIndex, endIndex));
    } catch (error) {
      console.error("Error fetching users data:", error);
    }
  };

  console.log("lenght", totalPages);
  React.useEffect(() => {
    getUsersData();
  }, [currentPage]);
  const classes = useStyles();
  const handleDelete = async () => {
    await axios
      .delete(`${process.env.REACT_APP_URL}users/${delId}`)
      .then((res) => {
        setOpen(false);

        setNotifyOpen(true);
        getUsersData();
      });
  };
  const handleUpdate = (id) => {
    navigate(`/dashboard/update-user/${id}`);
  };
  const handleChange = async (event) => {
    const value = event.target.value;
    if (value !== "") {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}users/searchUser/${user.organization._id}/${value}`
      );
      setResStatus(res.status);
      if (res.status === 201) {
        SetNotFound(res.data.message);
      } else {
        setUsersData(res.data.users);
      }
    } else {
      getUsersData();
    }
  };
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [openUpdateDialog, setUpdateOpenDialog] = useState(false);
  const [singleData, setSingleData] = useState("");

  const data = useSelector((state) => state);
  const handleDialogOpen = () => {
    setOpenDialog(true);
  };
  const handleDialogClose = (data) => {
    setOpenDialog(data);
    getUsersData();
  };
  const handleUpdateDialogClose = (data) => {
    setUpdateOpenDialog(data);
    getUsersData();
  };
  const handleUpdateDialogOpen = (data) => {
    setSingleData(data);
    setUpdateOpenDialog(true);
  };
  return (
    <Box sx={{ height: "100vh" }}>
      <Card
        className={classes.card}
        sx={{ height: "600px", position: "relative" }}
      >
        <Searchbar search={classes.search} onChange={handleChange} />
        <StyledTableContainer
          sx={{ border: "2px solid #000", padding: "8px", borderRadius: "8px" }}
        >
          {resStatus === 201 ? (
            "Result Not Found"
          ) : (
            <Table>
              <TableHeader sx={{ background: Gradients.cApU }}>
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: "1.2rem",
                      fontWeight: 600,
                      color: "#fff",
                      letterSpacing: 1,
                      textShadow: "5px 5px 10px #000",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "1.2rem",
                      fontWeight: 600,
                      color: "#fff",
                      letterSpacing: 1,
                      textShadow: "5px 5px 10px #000",
                    }}
                  >
                    Role
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "1.2rem",
                      fontWeight: 600,
                      color: "#fff",
                      letterSpacing: 1,
                      textShadow: "5px 5px 10px #000",
                    }}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "1.2rem",
                      fontWeight: 600,
                      color: "#fff",
                      letterSpacing: 1,
                      textShadow: "5px 5px 10px #000",
                    }}
                  >
                    Phone
                  </TableCell>
                  <TableCell> </TableCell>
                </TableRow>
              </TableHeader>
              <TableBody sx={{ color: "#fff" }}>
                {usersData.map((data, index) => (
                  <>
                    {" "}
                    <StyledTableRow
                      key={index}
                      className={
                        index % 2 === 0 ? classes.evenRow : classes.oddRow
                      }
                      sx={{
                        cursor: "pointer",
                        // borderRadius: "20px",
                        color: "#fff",
                      }}
                    >
                      <TableCell
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          color: "#fff",
                          borderTopLeftRadius: "8px",
                          borderBottomLeftRadius: "8px",
                        }}
                        className={classes.cell}
                      >
                        <Avatar src="/assets/images/1.png" sx={{ mr: 1 }} />
                        <Typography sx={{ fontSize: "1.2rem" }}>
                          {data.name}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {data.role}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <EmailIcon sx={{ margin: "-7px 5px" }} /> {data.email}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <LocalPhoneIcon sx={{ margin: "-7px 5px" }} />{" "}
                        {data.contact}
                      </TableCell>
                      <TableCell
                        className={classes.cell}
                        sx={
                          {
                            // borderTopRightRadius: "8px",
                            // borderBottomRightRadius: "8px",
                          }
                        }
                      >
                        <Box sx={{ display: "flex" }}>
                          <Avatar
                            sx={{
                              color: "#000",
                              background: "#E3A700",
                              boxShadow: "5px 5px 10px #000",
                              border: "1px solid #fff",
                            }}
                            onClick={() => handleUpdateDialogOpen(data)}
                          >
                            <CreateIcon sx={{ color: "#000" }} />
                          </Avatar>

                          <Avatar
                            sizes="sm"
                            sx={{
                              color: "#E3A700",
                              background: "#404040",
                              marginLeft: "10px",
                              boxShadow: "5px 5px 10px #000 ",
                              border: "1px solid #fff",
                            }}
                            onClick={() => handleClickOpenPop(data._id)}
                          >
                            <DeleteForeverIcon fontSize="sm" />
                          </Avatar>
                        </Box>
                      </TableCell>
                    </StyledTableRow>
                  </>
                ))}
              </TableBody>
              <Dialog
                fullWidth
                maxWidth="lg"
                PaperProps={{
                  style: {
                    background: " linear-gradient(#4F3B03, #E3A700,#503D06)",
                    border: "3px solid #fff",
                    borderRadius: "24px",
                  },
                }}
                open={openUpdateDialog}
                onClose={handleUpdateDialogClose}
              >
                <DialogTitle
                  sx={{
                    color: "#FAFF00",
                    textShadow:
                      "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
                  }}
                >
                  Update User
                </DialogTitle>
                <DialogContent>
                  <UpdateUser
                    closeDialog={handleUpdateDialogClose}
                    id={singleData._id}
                  />
                </DialogContent>
              </Dialog>
            </Table>
          )}
        </StyledTableContainer>
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Pagination
            count={totalPages}
            // count={10}
            page={currentPage}
            onChange={(event, page) => setCurrentPage(page)}
            color="primary"
            sx={{
              position: "absolute",
              bottom: 20,
              "& .MuiPaginationItem-root": {
                background: "#A4A4A4 !important",
                border: "1px solid #fff !important",
                boxShadow: "5px 5px 10px #000 !important",
                color: "#000",
                "&:hover": {
                  color: "#000",
                  background: "#E1A800 !important ",
                },
                "&.Mui-selected": {
                  background: "#E1A800 !important",
                },
              },

              "& .MuiPaginationItem-icon": {
                color: "#FFF !important", // Set color of the pagination icon to white
              },
            }}
          />
        </Box>

        <Dialog
          fullWidth
          maxWidth="lg"
          PaperProps={{
            style: {
              background: " linear-gradient(#4F3B03, #E3A700,#503D06)",
              border: "3px solid #fff",
              borderRadius: "24px",
            },
          }}
          open={openDialog}
          onClose={handleDialogClose}
        >
          <DialogTitle
            sx={{
              color: "#FAFF00",
              textShadow:
                "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
            }}
          >
            Add User
          </DialogTitle>
          <DialogContent>
            <CreateUser closeDialog={handleDialogClose} />
          </DialogContent>
        </Dialog>

        <MyDialog
          open={open}
          handleClose={handleClosePop}
          handleDelete={handleDelete}
        />
        <Snack
          open={notifyOpen}
          message={"User Deleted Successfully"}
          severity="success"
          handleClose={handleClosePop}
        />
      </Card>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button className={classes.btn} onClick={handleDialogOpen}>
          + New User
        </Button>
      </Box>
    </Box>
  );
};

export default Users;
