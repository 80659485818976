import React, { useState } from "react";
import {
  Box,
  Button,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Alert,
  Grid,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Snack from "../../../components/snackbar/Snack";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { useNavigate } from "react-router";
import { loadServerRows } from "@mui/x-data-grid-generator";

const useStyles = makeStyles((theme) => ({
  input: {
    border: "1px solid yellow",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
    backgroundColor: "#161A24",
    borderRadius: "10px",
    color: "#fff",
    "& .MuiInputBase-root": {
      backgroundColor: "#161A24",
      borderRadius: "10px",
      color: "#fff",
    },
    "& fieldset": {
      border: "none",
    },
    "& .MuiSvgIcon-root": {
      color: "#fff",
    },
  },

  btn: {
    borderRadius: "10px",

    color: "#404040",
    fontVariant: "small-caps",
    background: "#E3A700",
    border: "1px solid #fff",
    fontSize: "1.2rem",
    fontVariant: "small-caps",
    transition: "background-color 0.3s ease", // Adding transition for smooth effect
    "&:hover": {
      backgroundColor: "#E3A700", // Changing background color on hover
      cursor: "pointer", // Showing pointer cursor on hover
      // Add any additional styles for hover state here
    },
  },
  backbtn: {
    borderRadius: "10px",
    fontSize: "1rem",
    color: "#E3A700",
    fontVariant: "small-caps",
    background: "#404040",
    border: "1px solid #fff",
    marginRight: "20px",
    // boxShadow: "5px 5px 10px #000",
    "&:hover": {
      backgroundColor: "#404040", // Changing background color on hover
      cursor: "pointer", // Showing pointer cursor on hover
      // Add any additional styles for hover state here
    },
  },
  label: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#fff",
    marginTop: "10px",
    marginBottom: "10px",
  },
}));

const UploadFile = (props) => {
  const user = useSelector((state) => state.auth.user);
  const [name, setName] = useState("");
  const [format, setFormat] = useState("");
  const [entryDate, setEntryDate] = useState("");
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const classes = useStyles();
  const [severity, setSeverity] = React.useState("");
  const [notifyOpen, setNotifyOpen] = React.useState(false);
  const handleClosePop = () => {
    setNotifyOpen(false);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  const navigate = useNavigate();
  const [isloading, setIsLoading] = useState(false);
  const handleSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();

    if (!name || !format || !entryDate || !file) {
      setError("All fields are required.");
      return;
    }
    if (format === "image" && !file.type.startsWith("image")) {
      setError("Please upload an image file.");
      setSeverity("error");
      setNotifyOpen(true);
      setIsLoading(false);
      return;
    } else if (format === "video" && !file.type.startsWith("video")) {
      setError("Please upload a video file.");
      setSeverity("error");
      setNotifyOpen(true);
      setIsLoading(false);
      return;
    } else if (
      format === "file" &&
      !file.type.startsWith("application/pdf") &&
      !file.type.startsWith("application/msword") &&
      !file.type.startsWith(
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      )
    ) {
      setError("Please upload a PDF or Word document.");
      setSeverity("error");
      setNotifyOpen(true);
      setIsLoading(false);
      return;
    } else {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("format", format);
      formData.append("entryDate", entryDate);
      formData.append("file", file);
      formData.append("user", user.organization._id);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_URL}api/files/upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(response);
        setMessage(response.data.message);
        setSeverity("success");
        setNotifyOpen(true);
        setIsLoading(false);
        // props.closeDialog(false);
      } catch (error) {
        setSeverity("error");
        setError("Error uploading the file.");
        setNotifyOpen(true);
        setIsLoading(false);
      }
    }

    // navigate("dashboard/library")
  };
  const goBack = () => {
    props.closeDialog(false);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 1 }}>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            {" "}
            <Typography className={classes.label}>Name</Typography>
            <TextField
              required
              fullWidth
              className={classes.input}
              name="Name"
              placeholder="Type here..."
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <Typography className={classes.label}>Category Type</Typography>
            <Select
              fullWidth
              onChange={(e) => setFormat(e.target.value)}
              value={format}
              className={classes.input}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"image"}>Image</MenuItem>
              <MenuItem value={"video"}>Video</MenuItem>
              <MenuItem value={"file"}>File</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <Typography className={classes.label}>Entry Date</Typography>
            <TextField
              required
              fullWidth
              type="date"
              className={classes.input}
              name="EntryDate"
              value={entryDate}
              sx={{
                "& .MuiOutlinedInput-input": {
                  colorScheme: "dark", // Corrected property name and value
                },
              }}
              onChange={(e) => setEntryDate(e.target.value)}
            />
          </Grid>
        </Grid>

        <Typography className={classes.label}>Upload file</Typography>

        <TextField
          required
          className={classes.input}
          fullWidth
          multiple={false}
          type="file"
          onChange={handleFileChange}
          accept="image/*, video/*, .pdf, .doc, .docx"
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: 4,
            mb: 2,
          }}
        >
          <Button
            variant="outlined"
            className={classes.backbtn}
            disabled={isloading}
            onClick={goBack}
          >
            Back
          </Button>
          <Button
            variant="contained"
            type="submit"
            className={classes.btn}
            sx={{ ml: 2 }}
            disabled={isloading}
          >
            {isloading ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              "Upload"
            )}
          </Button>
        </Box>
        <Snack
          open={notifyOpen}
          message={message}
          severity={severity}
          handleClose={handleClosePop}
        />
      </form>
    </div>
  );
};

export default UploadFile;
