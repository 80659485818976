import React, { useEffect, useState } from "react";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
  Typography,
  Box,
  TableContainer,
  Card,
  Pagination,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import RatingInput from "../../components/Rating/RatingInput";
import { useNavigate } from "react-router";
import Snack from "../../components/snackbar/Snack";
import MyDialog from "../../components/Dialog/MyDialog";
import { useDispatch, useSelector } from "react-redux";
import Searchbar from "../../components/SearchBar/Searchbar";
import CreateIcon from "@mui/icons-material/Create";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Gradients from "../../utils/Gradients";
import CreateVendor from "./components/CreateVendor";
import UpdateVendor from "./components/UpdateVendor";
const useStyles = makeStyles((theme) => ({
  evenRow: {
    color: "#ffffff",
    backgroundColor: "#787676",
    "&:hover": {
      transform: "scale(1.01)",
      boxShadow: " 0 0 10px rgba(0, 0, 0, 0.5)", // Add shadow on hover
      backgroundColor: "#6B6B6B",
    },
  },
  oddRow: {
    // backgroundColor: "#6B6B6B",
    backgroundColor: "#787676",
    borderRadius: "20px",
    color: "#ffffff",
    // Odd row color
    "&:hover": {
      transform: "scale(1.01)",
      boxShadow: " 0 0 10px rgba(0, 0, 0, 0.5)", // Add shadow on hover
      backgroundColor: "#6B6B6B",
    },
  },
  tableCell: {
    color: "#ffffff",
  },
  row: {
    color: "#ffffff",
    "&:hover $cell": {
      color: "#ffffff",
    },
  },
  cell: {
    fontSize: "1rem",
    // fontWeight: 500,
    letterSpacing: 1,
    color: "#FFF",
    border: "none",
  },
  search: {
    border: "1px solid #fff",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
    borderRadius: "10px",
    "& .MuiInputBase-root": {
      backgroundColor: "#C9975C",
      borderRadius: "10px",
      color: "#fff",
    },
    "& fieldset": {
      border: "none",
    },
  },
  card: {
    padding: "25px",
    border: "2px solid #fff",
    background: "#787676",
    borderRadius: "16px",
    color: "#fff",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
  },
  btn: {
    borderRadius: "10px",
    marginTop: "10px",
    marginRight: "20px",
    color: "#000",
    fontWeight: "bold",
    fontVariant: "small-caps",
    background: "#E3A700",
    border: "1px solid #fff",
    fontSize: "14px",
    boxShadow: "5px 5px 10px #000",
    fontVariant: "small-caps",
    marginBottom: "10px",

    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#E3A700",
      cursor: "pointer",
    },
  },
  headCell: {
    textShadow: "5px 5px 10px #fff",
    fontSize: "1rem",
    fontWeight: 700,
    color: "#FAFF00",
    letterSpacing: 1,
  },
}));

// commenting from ahmed account
const TableHeader = styled(TableHead)(({ theme }) => ({
  "& th": {
    marginButtom: "10px",
    borderBottom: "0px",
    border: "none",
    "&:first-child": {
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
    },
    "&:last-child": {
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "10px",
    },
  },
}));
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  // borderRadius: "10px",
  overflow: "hidden",
}));
const Vendors = () => {
  const user = useSelector((state) => state.auth.user);
  const [open, setOpen] = React.useState(false);
  const [delId, setDelId] = React.useState(""); // Store user ID to delete
  const [notifyOpen, setNotifyOpen] = React.useState(false);
  const [filteredVendors, setFilteredVendors] = React.useState([]);
  const [singleData, setSingleData] = useState("");
  const handleClickOpenPop = (id) => {
    setDelId(id);
    console.log(id);
    setOpen(true);
  };
  const handleClosePop = () => {
    setOpen(false);
  };
  const fetchVendors = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}vendors/all/${user.organization._id}`
      );

      if (response.status === 200) {
        console.log(response.data);
        // setVendors(response.data);
        const totalUsers = response.data.length;
        setTotalPages(Math.ceil(totalUsers / itemsPerPage));

        // Slice the data based on pagination
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        setVendors(response.data);
        setFilteredVendors(response.data.slice(startIndex, endIndex));
      } else {
        console.error("Failed to fetch Vendors");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const [vendors, setVendors] = useState([]);
  const handleDelete = async (id) => {
    console.log("Vendor Deleted", id);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}vendors/${delId}`
      );

      if (response.status === 200) {
        // console.log(response)
        setVendors(response.data.data);
        setNotifyOpen(true);
        setOpen(false);
        setTimeout(() => {
          setNotifyOpen(false);
        }, 2000);
        fetchVendors();
      } else {
        console.error("Failed to Delete Vendors");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const navigate = useNavigate();
  const handleUpdate = (data) => {
    setSingleData(data);
    setUpdateOpenDialog(true);
  };
  const classes = useStyles();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);

  const itemsPerPage = 5;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [openUpdateDialog, setUpdateOpenDialog] = useState(false);

  useEffect(() => {
    // Fetch vendors when the component mounts

    fetchVendors();
  }, [currentPage || openUpdateDialog || openDialog]);

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };
  const handleDialogClose = (data) => {
    setOpenDialog(data);
    fetchVendors();
  };
  const handleUpdateDialogClose = (data) => {
    setUpdateOpenDialog(data);
    fetchVendors();
  };

  const handleChange = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    const filteredData = vendors.filter((data) => {
      // Check if companyName is defined before accessing its property
      if (data.name && data.name.toLowerCase().includes(searchTerm)) {
        return true;
      }
      return false;
    });
    setFilteredVendors(filteredData);
  };
  // if (vendors.length === 0) {
  //   return (
  //     <div className={classes.maindiv}>
  //       <Typography className={classes.maintypo}>Vendors</Typography>
  //       <Typography>No data found</Typography>
  //     </div>
  //   );
  // }

  return (
    <Box sx={{ height: "100vh" }}>
      <Card
        className={classes.card}
        sx={{ height: "600px", position: "relative" }}
      >
        <Searchbar search={classes.search} onChange={handleChange} />
        <StyledTableContainer>
          <Table>
            <TableHeader sx={{ background: Gradients.cApU }}>
              <TableRow>
                <TableCell className={classes.headCell}>Name</TableCell>
                <TableCell className={classes.headCell}>Services</TableCell>
                <TableCell className={classes.headCell}>Email</TableCell>
                <TableCell className={classes.headCell}>Price</TableCell>
                <TableCell className={classes.headCell}>Rating</TableCell>
                <TableCell className={classes.headCell}>Action</TableCell>
              </TableRow>
            </TableHeader>
            <TableBody sx={{ color: "#fff" }}>
              {filteredVendors.map((data, index) => (
                <TableRow
                  key={index}
                  className={index % 2 === 0 ? classes.evenRow : classes.oddRow}
                >
                  <TableCell className={classes.cell}>
                    <Typography>{data.name}</Typography>
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {data.services}
                  </TableCell>
                  <TableCell className={classes.cell}>  <EmailIcon sx={{ margin: "-7px 5px" }} /> {data.email}</TableCell>
                  <TableCell className={classes.cell}>${parseFloat(data.price).toLocaleString()}</TableCell>
                  <TableCell className={classes.cell}>  
                    <RatingInput data={data.rating} readOnly={false} />
                  </TableCell>
                  <TableCell className={classes.cell}>
                    <Box sx={{ display: "flex" }}>
                      <Avatar
                        sx={{
                          color: "#000",
                          background: "#E3A700",
                          boxShadow: "5px 5px 10px #000",
                          border: "1px solid #fff",
                        }}
                      >
                        <CreateIcon
                          onClick={() => handleUpdate(data)}
                          sx={{ color: "#000" }}
                        />
                      </Avatar>

                      <Avatar
                        sizes="sm"
                        sx={{
                          color: "#E3A700",
                          background: "#404040",
                          marginLeft: "10px",
                          boxShadow: "5px 5px 10px #000 ",
                          border: "1px solid #fff",
                        }}
                      >
                        <DeleteForeverIcon
                          onClick={() => handleClickOpenPop(data._id)}
                          fontSize="sm"
                        />
                      </Avatar>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
        <Dialog
          fullWidth
          maxWidth="lg"
          PaperProps={{
            style: {
              background: " linear-gradient(#4F3B03, #E3A700,#503D06)",
              border: "3px solid #fff",
              borderRadius: "24px",
            },
          }}
          open={openUpdateDialog}
          onClose={handleUpdateDialogClose}
        >
          <DialogTitle
            sx={{
              color: "#FAFF00",
              textShadow:
                "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
            }}
          >
            UPDATE NEW VENDOR
          </DialogTitle>
          <DialogContent>
            <UpdateVendor
              closeDialog={handleUpdateDialogClose}
              state={singleData}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          fullWidth
          maxWidth="lg"
          PaperProps={{
            style: {
              background: " linear-gradient(#4F3B03, #E3A700,#503D06)",
              border: "3px solid #fff",
              borderRadius: "24px",
            },
          }}
          open={openDialog}
          onClose={handleDialogClose}
        >
          <DialogTitle
            sx={{
              color: "#FAFF00",
              textShadow:
                "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
            }}
          >
            Create NEW VENDOR
          </DialogTitle>
          <DialogContent>
            <CreateVendor closeDialog={handleDialogClose} />
          </DialogContent>
        </Dialog>
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Pagination
            count={totalPages}
            // count={10}
            page={currentPage}
            onChange={(event, page) => setCurrentPage(page)}
            color="primary"
            sx={{
              position: "absolute",
              bottom: 20,
              "& .MuiPaginationItem-root": {
                background: "#A4A4A4",
                border: "1px solid #fff",
                boxShadow: "5px 5px 10px #000",
                color: "#000",
                "&:hover": {
                  color: "#FFF",
                  background: "#E1A800",
                },
                "&.Mui-selected": {
                  background: "#E1A800",
                },
              },

              "& .MuiPaginationItem-icon": {
                color: "#000", // Set color of the pagination icon to white
              },
            }}
          />
        </Box>

        <MyDialog
          open={open}
          handleClose={handleClosePop}
          handleDelete={handleDelete}
        />
        <Snack
          open={notifyOpen}
          message={"Vendor Deleted Successfully"}
          severity="error"
          handleClose={handleClosePop}
        />
      </Card>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button className={classes.btn} onClick={handleDialogOpen}>
          + New Vendor
        </Button>
      </Box>
    </Box>
  );
};

export default Vendors;
