import { AppBar, Box, Toolbar, Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
const useStyles = makeStyles((theme) => ({
  typo: {
    color: "#444",
    fontSize: "34px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  },
  typo2: {
    color: "#878484",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  input: {
    display: "inline-flex",
    padding: "15px 21px 15px 30px",
    alignItems: "center",
    gap: "10px",
    borderRadius: "25px",
    border: "1px solid #D2CECE",
    background: "rgba(241, 241, 241, 0.88)",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    width: "100%",
    marginLeft: "-8px",
  },
  btn: {
    borderRadius: "25px",
    background:
      "linear-gradient(90deg, #046593 0%, rgba(60, 175, 228, 0.99) 100%)",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset",
    height: "45px",
    marginTop: "40px",
    width: "400px",
    marginLeft: "15px",
  },
}));
const ForgotPassword = () => {
  const classes = useStyles();
  return (
    <div>
      <AppBar height="68px" sx={{ bgcolor: "#3CAFE4" }}>
        <Toolbar></Toolbar>
      </AppBar>
      <Box>
        <Typography className={classes.typo}>Forget Password</Typography>
        <Typography className={classes.typo2}>
          Enter the email to reset your password
        </Typography>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{
              mb: 1,
              color: "#3CAFE4",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
            }}
          >
            {" "}
            Password
          </Typography>
          <input
            type="password"
            className={classes.input}
            placeholder="Example@gmail.com"
            autoComplete="off"
          />
        </Box>
        <Button variant="contained" fullWidth className={classes.btn}>
          Get Link
        </Button>
      </Box>
    </div>
  );
};

export default ForgotPassword;
