import React from "react";
import {
  Box,
  Button,
  Stack,
  TextField,
  FormControl,
  Alert,Select,MenuItem,
  InputLabel,Grid
} from "@mui/material";
import categoryData from "../../utils/CountryCode";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { useNavigate, useParams } from "react-router";

const useStyles = makeStyles((theme) => ({
  textfield: {
    marginBottom: "20px",
    background: "#fff",
  },

  select: {
    border: "1px solid yellow",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
    borderRadius: "10px",
    "& .MuiInputBase-root": {
      backgroundColor: "#161A24",
      borderRadius: "10px",
      color: "#fff",
    },
    "& fieldset": {
      border: "none",
    },
    "& .MuiSvgIcon-root": {
      color: "#fff",
    }
  },
  label: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "white",
    paddingBottom: "5px",
    textShadow: "5px 5px 10px #000",
  },
  disp: {
    marginTop: "30px",
    display: "flex",

    justifyContent: "flex-end",
  },
  backbtn: {
    borderRadius: "10px",
    fontSize: "14px",
    color:'#E3A700',
    height:"40px",
    marginTop:'10px',
    fontVariant: "small-caps",
    background:"#404040",
    border:"2px solid #fff",
    marginRight:"20px",
    boxShadow: "5px 5px 10px #000",
    width:"230px",
    fontWeight:'700',
    "&:hover": {
      backgroundColor: "#404040",
      cursor: "pointer",
    },
  },
  btn: {
    height:"40px",
    width:"230px",
    fontWeight:'700',
    borderRadius: "10px",
    marginTop: "10px",
    marginRight: "20px",
    color: "#000",
    fontVariant: "small-caps",
    background: "#E3A700",
    border: "2px solid #fff",
    fontSize: "14px",
    boxShadow: "5px 5px 10px #000",
    fontVariant: "small-caps",
    marginBottom: "10px",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#E3A700",
      cursor: "pointer",
    },
  },
}));

const UpdateUser = (props) => {
  const [error, setError] = React.useState(false);
  const [error1, setError1] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const { id } = useParams();
  const org = useSelector((state) => state.auth.user.organization);
  const initialValues = {
    name: "",
    email: "",
    contact: "",
    timeZone: "",
    role: "user",
    password: "",
    phoneCode:null,
    organizationId:org._id,
  };
  const goBack = () => {
    props.closeDialog(false);
  };
  const [formValues, setFormValues] = React.useState(initialValues);
  const isValidContactNumber = (contactNumber) => {
    return /^\d{10}$/.test(contactNumber);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const loadUserData = async () => {
    const res = await axios.get(`${process.env.REACT_APP_URL}users/${props.id}`);
    
    setFormValues(res.data.data);
  };

  React.useEffect(() => {
    loadUserData();
  }, []);

 

  const handleSubmit = async (e) => {
    e.preventDefault();

    const valuesToCheck = Object.values(formValues);

    if (
      valuesToCheck.some(
        (value) => typeof value === "string" && value.trim() === ""
      )
    ) {
      setError(true);
      setMessage("Please fill all the data fields.");
    } 
    
    
    else {
      setError(false);
      setMessage("");

      await axios
        .put(`${process.env.REACT_APP_URL}users/update/${props.id}`, formValues)
        .then((res) => {
          // alert(res.data.message);
          setError1(true);
          setMessage(res.data.message);
          setTimeout(() => {
            setError1(false);
          }, 2000); 
          setTimeout(() => {
          props.closeDialog(false);
        }, 2000); 
        });
    }
  };

  const classes = useStyles();

  return (
    <div style={{ padding: "20px", }}>
      <Box>
        <form onSubmit={handleSubmit}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 2 }}>
            <Grid item xs={12} sm={6} md={6} lg={6}> 
            <InputLabel className={classes.label}>Name</InputLabel>{" "}
          <TextField
            name="name"
            fullWidth
            required
            value={formValues.name}
            onChange={handleChange}
            placeholder="Enter Full Name here"
            className={classes.select}
          /></Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
            <InputLabel className={classes.label}>Email</InputLabel>{" "}
          <TextField
            name="email"
            fullWidth
            required
            type="email"
            value={formValues.email}
            onChange={handleChange}
            placeholder="johndoe@abc.com"
            className={classes.select}
          />
            </Grid>
            </Grid>
            <Grid container sx={{mt:2}} rowSpacing={1} columnSpacing={{ xs: 1, md: 2 }}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
          
            <InputLabel className={classes.label}>Contact Number</InputLabel>{" "}
            <Box display={"flex"}>
              {/* <FormControl className={classes.select} sx={{marginRight:"10px", width:'120px'}}>
                <Select
                  required
                  id="demo-simple-select"
                  value={formValues.phoneCode}
                  name="phoneCode"
               
                  onChange={handleChange}
                >
                  {categoryData.map((categoryItem) => (
                    <MenuItem key={categoryItem.dial_code} value={categoryItem.dial_code}>
                      {categoryItem.dial_code}
                    </MenuItem>
                  ))}
                </Select>
             
              </FormControl> */}
          <TextField
            name="contact"
            fullWidth
            required
            value={formValues.contact}
            onChange={handleChange}
            placeholder="xxxxxxx"
            className={classes.select}
          />
          </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
            <InputLabel className={classes.label}>Time Zone</InputLabel>{" "}
          <TextField
          fullWidth
            name="timeZone"
            value={formValues.timeZone}
            onChange={handleChange}
            placeholder="US 00:00 UTC"
            className={classes.select}
          />
            </Grid>
            </Grid>
          {error && <Alert severity="error">{message}</Alert>}
          {error1 && <Alert severity="success">{message}</Alert>}
          <div className={classes.disp}>
          <Button
              variant="outlined"
              className={classes.backbtn}
              onClick={goBack}
            >
              Back
            </Button>
          <Button variant="contained" type="submit" className={classes.btn}>
            Update Details
          </Button>
          </div>
        </form>
      </Box>
    </div>
  );
};

export default UpdateUser;
