import React, { useState } from 'react';
import { FileViewer } from 'react-file-viewer';

function FileViewerComponent() {
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const selectedFileType = selectedFile.type;

    setFile(selectedFile);
    setFileType(selectedFileType);
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} />
      {file && (
        <FileViewer
          fileType={fileType}
          filePath={URL.createObjectURL(file)}
        />
      )}
    </div>
  );
}

export default FileViewerComponent;
