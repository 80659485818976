import { Avatar, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  Box,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  CardContent,
  Card,
} from "@mui/material";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import TodayIcon from "@mui/icons-material/Today";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import CustomerList from "./components/CustomerList";
import Schedule from "./components/Schedule";
import Overview from "./components/Overview";
import ToDo from "./components/ToDo";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import axios from "axios";
import { experimentalStyled as styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((theme) => ({
  card: {
    border: "3px solid #fff",
    background: "#272E3C",
    borderRadius: "16px",
    color: "#fff",
  },
  cardheaderText: {
    fontSize: "20px",
    color: "#FAFF00",
    fontWeight: "bold",
    textShadow:
      "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
    // WebkitTextStroke: "1px black",
    // textShadow: "5px 5px 10px #FAFF00",
  },

  subCardHeader: {
    color: "#fff",
    paddingInlineStart: "10px",
    fontSize: "16px",
    fontWeight: "bold",
    paddingTop: "20px",
  },
  cardText: {
    fontSize: "25px",
    fontWeight: "bolder",
    paddingBottom: "10px",
    textShadow: "5px 5px 10px #000",
  },
  tworow: {
    borderRadius: "20px",
    background:
      "linear-gradient(0deg, rgba(151,150,227,1) 0%, rgba(4,101,147,1) 100%)",
    height: "282px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  maindiv: {
    borderRadius: "20px",
    // background:'#D49AE2',
    width: "100%",
    height: "18.875vh",
    margin: "0 25px 0 0px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "0 10px 0 10px",
  },
  maindivsec: {
    borderRadius: "20px",
    // background:'#FCE8A4',
    // width: "100%",
    height: "18.875vh",
    margin: "0 25px 0 0px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "0 10px 0 10px",
  },
  second: {
    width: "20%",
    height: "16vh",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    color: "#fff",
  },
  third: {
    // backgroundColor:"#FCC200",
    background:
      "linear-gradient(90deg, rgba(144,142,70,1) 0%, rgba(150,182,164,1) 100%)",
    width: "40px",
    height: "40px",
    borderRadius: "100px",
    boxShadow: "5px 5px 10px rgba(169, 169, 169, 0.2)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  line: {
    width: "0.5px",
    height: "70px",
    color: "#ACACAC",
    opacity: "0.4",
  },

  five: {
    // backgroundColor:"#FCC200",
    background:
      "linear-gradient(90deg, rgba(252,215,122,1) 0%, rgba(250,170,145,1) 100%)",
    width: "40px",
    height: "40px",
    borderRadius: "100px",
    boxShadow: "5px 5px 10px rgba(169, 169, 169, 0.2)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  six: {
    background:
      "linear-gradient(90deg, rgba(255,212,243,1) 21%,  rgba(225,255,163,1)100%)",
    width: "40px",
    height: "40px",
    borderRadius: "100px",
    boxShadow: "5px 5px 10px rgba(169, 169, 169, 0.2)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor:"#FCC200"
  },
  sixx: {
    // background:
    //   "linear-gradient(90deg, rgba(255,212,243,1) 21%,  rgba(225,255,163,1)100%)",
    width: "40px",
    height: "40px",
    borderRadius: "100px",
    boxShadow: "5px 5px 10px rgba(169, 169, 169, 0.2)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FCC200",
    border: "1px solid #000",
  },
  seven: {
    background: "#8D8D8D",
    width: "40px",
    height: "40px",
    borderRadius: "100px",
    boxShadow: "5px 5px 10px rgba(169, 169, 169, 0.2)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  secCont: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    margin: "15px 25px 0 0px",
    height: "150vh",
    // padding: "10px",
  },
  secone: {
    height: "160vh",
    // width: "64%",
    display: "flex",
    flexDirection: "column",
  },
  sectwo: {
    background:
      "linear-gradient(0deg, rgba(151,150,227,1) 0%, rgba(4,101,147,1) 100%)",
    height: "90vh",
    borderRadius: "20px",
    padding: "35px",
  },
  secthr: {
    border: "2px solid #fff",
    borderRadius: "16px",
    marginBottom: "20px",
    marginTop: "20px",
    padding: "20px",
    background: "#187ACE",
  },

  h5p: {
    width: "50px",
    height: "50px",
    color: "white",
    background: "rgba(13, 153, 255, 0.1)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "20px",
  },

  todoheader: {
    background: "#046593",
    color: "white",
    height: "80px",
    borderTopRightRadius: "50px",
    borderTopLeftRadius: "50px",
    padding: "25px",
  },
  welcome: {
    background:
      "linear-gradient(0deg, hsla(107, 61%, 23%, 1) 0%, hsla(108, 62%, 59%, 1) 100%)",
    backdropFilter: "blur(10px)",
    borderRadius: "20px",
    width: "100%",
    height: "18.875vh",
    margin: "0 25px 0 0px",
    boxShadow: "5px 5px 10px rgba(169, 169, 169, 0.5)",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "0 10px 0 10px",
    color: "#fff",
    display: "flex",
    justifyContent: "space-between",
    padding: "35px",
  },
}));

const Dash = () => {
  const user = useSelector((state) => state.auth.user);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  console.log(user);
  const [selectedDate, setSelectedDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [schedules, setSchedules] = useState([]);
  console.log(selectedDate);

  const handleDateChange = (date) => {
    const formattedDate = date.format("YYYY-MM-DD");
    setSelectedDate(formattedDate);

    const body = {
      date: formattedDate,
    };
    axios
      .post(`${process.env.REACT_APP_URL}api/schedules/date`, body)
      .then((response) => {
        console.log(response);
        setSchedules(response.data);
      })
      .catch((error) => {
        console.error("Error fetching schedules:", error);
      });
  };
  const [retainer, setRetainer] = React.useState("");
  const [loading1, setLoading1] = React.useState(true);
  const [loading2, setLoading2] = React.useState(true);
  const [contactsData, setContactsData] = React.useState([]);
  const [timeFrame, setTimeFram] = React.useState("weekly");
  const [graph, setGraph] = React.useState("");
  const [productscount, setProductscount] = useState("");
  const Categorytime = [
    { value: "yearly", name: "yearly" },
    { value: "monthly", name: "monthly" },
    { value: "weekly", name: "weekly" },
  ];
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(value, "name", name);
    setTimeFram(value);
    hangleGraph(value);
  };
  console.log("user", user);
  const getContactsData = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_URL}companies/firstContact/all/${user.organization._id}`
    );
    console.log(res.data.data);
    setContactsData(res.data.data);
  };
  const hangleGraph = (timeframe) => {
    axios
      .post(
        `${process.env.REACT_APP_URL}api/invoices/graphdata/${user.organization._id}/${timeframe}`
      )
      .then((response) => {
        // console.log(response,"response Graph")
        setGraph(response.data);
      });
  };
  const [notifications, setNotification] = useState([""]);

  useEffect(() => {
    const body = {
      organizationId: user.organization._id,
    };
    axios
      .post(`${process.env.REACT_APP_URL}api/invoices/notifications`, body)
      .then((response) => {
        console.log(response, "response");
        setNotification(response.data);
      });
  }, []);
  useEffect(() => {
    setLoading2(true);
    setLoading1(true);
    const body = {
      date: moment(new Date()).format("YYYY-MM-DD"),
    };
    axios
      .post(`${process.env.REACT_APP_URL}api/schedules/date`, body)
      .then((response) => {
        // console.log("+++++++resp",response);
        setSchedules(response.data);
        setLoading2(false);
        axios
          .get(
            `${process.env.REACT_APP_URL}api/totalstats/${user.organization._id}`
          )
          .then((response) => {
            setRetainer(response.data);
            getContactsData();
            hangleGraph(timeFrame);
            axios
              .get(
                `${process.env.REACT_APP_URL}api/invoices/productscount/${user.organization._id}`
              )
              .then((response) => {
                setProductscount(response.data);
                setLoading1(false);
              })
              .catch((err) => {
                console.error("Error:", err);
              });
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      })
      .catch((error) => {
        console.error("Error fetching schedules:", error);
      });
  }, []);

  console.log("productscount", productscount.totalAmount);

  const classes = useStyles();
  return loading1 ? (
    <Box sx={{height:'90vh'}}> </Box>
  ) : (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Card
        sx={{
          border: "2px solid #fff",
          background: "#272E3C",
          borderRadius: "16px",
          color: "#fff",
        }}
      >
        <CardContent>
          <Box sx={{ display: "flex" }}>
            <Avatar
              sx={{
                mt: "20px",
                mr: "15px",
                bgcolor: "#5CB644",
                boxShadow: "0px 0px 10px #5CB644",
                // elevation: 10,
              }}
            >
              <img src="/assets/images/hi.png" style={{ width: "20px" }} />
            </Avatar>

            <Box>
              <p
                className={classes.ron}
                style={{ fontSize: "24px", paddingTop: "4px" }}
              >
                Welcome back, {user.name}!
              </p>
              <div className={classes.welcomeleft}>
                <p
                  className={classes.desc}
                  style={{ fontSize: "14px", paddingTop: "6px" }}
                >
                  Today is{" "}
                  <span className={classes.ron}>
                    {moment(new Date()).format("MMMM D, YYYY")}
                  </span>{" "}
                  You have{" "}
                  <span className={classes.ron}>{notifications.length}</span>{" "}
                  new alerts, along with {schedules.length} schedule meetings
                  awaiting your response.
                </p>
              </div>
            </Box>
          </Box>
        </CardContent>
      </Card>

      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 1 }}>
        <Grid item xs={12} sm={12} md={12} lg={8} xl={9}>
          <Card className={classes.secthr}>
            <Box
              sx={{
                // margin: "5px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p className={classes.cardheaderText}>TOTAL REVENUES</p>
              <FormControl className={classes.textfield}>
                <Select
                  id="demo-simple-select"
                  value={timeFrame}
                  sx={{
                    color: "#fff",
                    background: "#252931",
                    height: "30px",
                    borderRadius: "8px",
                    "& .MuiSvgIcon-root": {
                      color: "#fff",
                    },
                  }}
                  name="timeFrame"
                  // required
                  // placeholder="Select an Item"
                  onChange={handleChange}
                >
                  {Categorytime.map((categoryItem) => (
                    <MenuItem key={categoryItem.name} value={categoryItem.name}>
                      {categoryItem.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <h2
              style={{
                textAlign: "center",
                color: "#fff",
                fontSize: "32px",
                fontWeight: "bold",
                textShadow:
                  "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
              }}
            >
              $ {graph.totalEarnings < 0 ? "0" : graph.totalEarnings}
            </h2>

            <Overview graph={graph} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                marginTop: "15px",
              }}
            >
              {/* <span style={{ color: "#28fc03" }}>+12.96%</span> */}
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={3} sx={{ mt: "20px" }}>
          <Card
            className={classes.card}
            style={{ bgcolor: "#414752", padding: "15px", height: "450px" }}
          >
            <h4 className={classes.cardheaderText}>CALENDER</h4>
            <Schedule onDateChange={handleDateChange} />
          </Card>
        </Grid>
      </Grid>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 1 }}>
        <Grid item xs={12} sm={12} md={12} lg={8} xl={9}>
          <Card className={classes.card} style={{ padding: "20px" }}>
            <h4 className={classes.cardheaderText}>CTV/OTT/OLV</h4>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 1 }}>
              <Grid item xs={6} sm={6} md={4} lg={4}>
                <Card
                  style={{
                    textAlign: "center",
                    marginTop: "10px",
                    border: "2px solid #fff",
                    background: "#4D5472",
                    borderRadius: "16px",
                    color: "#fff",
                  }}
                >
                  <p className={classes.subCardHeader}># Campaigns</p>
                  <p className={classes.cardText}>
                  { `${parseFloat(productscount.productCounts.campaignProducts).toLocaleString()}` } 
                  </p>
                </Card>
              </Grid>

              <Grid item xs={6} sm={6} md={4} lg={4}>
                <Card
                  style={{
                    textAlign: "center",
                    marginTop: "10px",
                    border: "2px solid #fff",
                    background: "#4D5472",
                    borderRadius: "16px",
                    color: "#fff",
                  }}
                >
                  <p className={classes.subCardHeader}>Sold Products</p>
                  <p className={classes.cardText}>
                    { `${parseFloat(productscount.productCounts.premiumProducts).toLocaleString()}` } 
                  </p>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Card
                  style={{
                    textAlign: "center",
                    marginTop: "10px",
                    border: "2px solid #fff",
                    background: "#4D5472",
                    borderRadius: "16px",
                    color: "#fff",
                  }}
                >
                  <p className={classes.subCardHeader}>Total Amount</p>
                  <p className={classes.cardText}>
                    { `${parseFloat(productscount.totalAmount).toLocaleString()}` } 
                  </p>
                </Card>
              </Grid>
            </Grid>
          </Card>
          <Card
            className={classes.card}
            style={{ padding: "20px", marginTop: "15px" }}
          >
            <h4 className={classes.cardheaderText}>RETAINERS</h4>
            <p style={{ margin: "10px" }}>Weekly</p>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 1 }}>
              <Grid item xs={6} sm={6} md={4} lg={4}>
                <Card
                  style={{
                    textAlign: "center",
                    marginTop: "10px",
                    border: "2px solid #fff",
                    background: "#4D5472",
                    borderRadius: "16px",
                    color: "#fff",
                  }}
                >
                  <p className={classes.subCardHeader}># Campaigns</p>
                  <p className={classes.cardText}>
                    {retainer.weeklyStats.length < 1
                      ? "-"
                      :  `${parseFloat(retainer.weeklyStats[0].totalTortCountWeek).toLocaleString()}`}
                  </p>
                </Card>
              </Grid>

              <Grid item xs={6} sm={6} md={4} lg={4}>
                <Card
                  style={{
                    textAlign: "center",
                    marginTop: "10px",
                    border: "2px solid #fff",
                    background: "#4D5472",
                    borderRadius: "16px",
                    color: "#fff",
                  }}
                >
                  <p className={classes.subCardHeader}># Signed</p>
                  <p className={classes.cardText}>
                    {retainer.weeklyStats.length < 1
                      ? "-"
                      :   `${parseFloat(retainer.weeklyStats[0].totalSignedWeek).toLocaleString()}` }
                  </p>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Card
                  style={{
                    textAlign: "center",
                    marginTop: "10px",
                    border: "2px solid #fff",
                    background: "#4D5472",
                    borderRadius: "16px",
                    color: "#fff",
                  }}
                >
                  <p className={classes.subCardHeader}># DQ'ed</p>
                  <p className={classes.cardText}>
                    {retainer.weeklyStats.length < 1
                      ? "-"
                      : `${parseFloat(retainer.weeklyStats[0].totalDQedWeek).toLocaleString()}` }
                  </p>
                </Card>
              </Grid>
            </Grid>
            <hr style={{ marginTop: "20px", marginBottom: "15px" }} />
            <p style={{ margin: "10px" }}>Monthly</p>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 1 }}>
              <Grid item xs={6} sm={6} md={4} lg={4}>
                <Card
                  style={{
                    textAlign: "center",
                    marginTop: "10px",
                    border: "2px solid #fff",
                    background: "#4D5472",
                    borderRadius: "16px",
                    color: "#fff",
                  }}
                >
                  <p className={classes.subCardHeader}># Campaigns</p>
                  <p className={classes.cardText}>
                    {retainer.monthlyStats.length < 1
                      ? "-"
                      :`${parseFloat(retainer.monthlyStats[0].totalTortCountMonth).toLocaleString()}` }
                  </p>
                </Card>
              </Grid>

              <Grid item xs={6} sm={6} md={4} lg={4}>
                <Card
                  style={{
                    textAlign: "center",
                    marginTop: "10px",
                    border: "2px solid #fff",
                    background: "#4D5472",
                    borderRadius: "16px",
                    color: "#fff",
                  }}
                >
                  <p className={classes.subCardHeader}># Signed</p>
                  <p className={classes.cardText}>
                    {retainer.monthlyStats.length < 1
                      ? "-"
                      :`${parseFloat(retainer.monthlyStats[0].totalSignedMonth).toLocaleString()}`}
                  </p>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Card
                  style={{
                    textAlign: "center",
                    marginTop: "10px",
                    border: "2px solid #fff",
                    background: "#4D5472",
                    borderRadius: "16px",
                    color: "#fff",
                  }}
                >
                  <p className={classes.subCardHeader}># DQ'ed</p>
                  <p className={classes.cardText}>
                    {retainer.monthlyStats.length < 1
                      ? "-"
                      : `${parseFloat(retainer.monthlyStats[0].totalDQedMonth ).toLocaleString()}`}
                  </p>
                </Card>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={3}>
          <Card
            className={classes.card}
            style={{
              bgcolor: "#414752",
              padding: "15px",
              height: "595px",
              overflowY: "auto",
              "&::-webkit-scrollbar": { 
                display: "none" // Hide the scrollbar for WebKit browsers
              },
              "&::-webkit-scrollbar": { 
                display: "none" // Hide the scrollbar for WebKit browsers
              },
              "&::-moz-scrollbar": { 
                display: "none" // Hide the scrollbar for Firefox browsers
              },
              "&::-ms-scrollbar": { 
                display: "none" // Hide the scrollbar for Microsoft Edge/IE browsers
              },
              "&::-webkit-scrollbar": { 
                display: "none" // Hide the scrollbar for WebKit browsers
              },
            }}
          >
            <h4 className={classes.cardheaderText}>EVENTS</h4>
            <Card
              style={{
                marginTop: "10px",
                border: "1px solid #fff",
                background: "#3B415B",
                borderRadius: "8px",
                color: "#fff",
              }}
            >
              <CardContent>
                {schedules.length === 0 ? (
                  <Card
                    style={{
                      marginTop: "10px",
                      border: "2px solid #fff",
                      background: "#4D5472",
                      borderRadius: "12px",
                      color: "#fff",
                    }}
                  >
                    <CardContent>
                      <p>No schedules available.</p>
                    </CardContent>
                  </Card>
                ) : (
                  schedules.map((sc, i) => (
                    // console.log("date", sc);
                    <Card
                      style={{
                        marginTop: "10px",
                        border: "2px solid #fff",
                        background: i % 2 === 0 ? "#005197" : "#6B6B6B",

                        borderRadius: "16px",
                        color: "#fff",
                      }}
                    >
                      <CardContent
                        style={{ fontWeight: "bold", fontSize: "16px" }}
                      >
                        {sc.notes}
                      </CardContent>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          paddingRight: "15px",
                        }}
                      >
                        <Box
                          style={{
                            display: "flex",
                            paddingInlineStart: "15px",
                          }}
                        >
                          <TodayIcon style={{ paddingRight: "8px" }} />
                          <p style={{ fontSize: "16px" }}>
                            <p>{moment(sc.date).format("DD MMMM YYYY")}</p>
                          </p>
                        </Box>
                        <Box style={{ display: "flex" }}>
                          <WatchLaterIcon style={{ paddingRight: "8px" }} />
                          <p style={{ fontSize: "16px" }}>{sc.time}</p>
                        </Box>
                      </Box>
                      <CardContent style={{ fontWeight: "bold" }}>
                        <Box
                          display={{ display: "flex", alignItems: "center" }}
                        >
                          <p style={{ fontSize: "14px" }}>Meeting With</p>{" "}
                          <Avatar
                            sx={{
                              marginLeft: "10px",
                              marginRight: "10px",
                              fontSize: "15px",
                              width: "30px", // Set the desired width
                              height: "30px", // Set the desired height
                            }}
                            src="assets/images/4.png"
                          ></Avatar>{" "}
                          <p style={{ fontSize: "14px" }}>
                            {sc?.company?.companyName}
                          </p>
                        </Box>
                      </CardContent>
                    </Card>
                  ))
                )}
              </CardContent>
            </Card>
          </Card>
        </Grid>
      </Grid>
      <Card
        className={classes.card}
        style={{ background: "#3B415B", marginTop: "15px" }}
      >
        <CardContent>
          <h4 className={classes.cardheaderText}>New CUSTOMERS</h4>
          <CustomerList data={contactsData} sx={{ color: "white" }} />
        </CardContent>
      </Card>
    </div>
  );
};

export default Dash;
