import React, { useEffect } from "react";
import {
  Box,
  Button,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Alert,
  Grid,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import Snack from "../../../components/snackbar/Snack";
const useStyles = makeStyles((theme) => ({
  input: {
    border: "1px solid yellow",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
    borderRadius: "10px",
    "& .MuiInputBase-root": {
      backgroundColor: "#161A24",
      borderRadius: "10px",
      color: "#fff",
    },
    "& fieldset": {
      border: "none",
    },
    "& .MuiSvgIcon-root": {
      color: "#fff",
    }
  },
  label:{
    fontSize: "14px",
    fontWeight: 600,
    color:"#fff",
    marginTop:"6px",
    marginBottom:"5px",
    textShadow: "5px 5px 10px #000 ",
  },
  btn: {
    height:"40px",
    width:"230px",
    fontWeight:'700',
    borderRadius: "10px",
    marginTop: "10px",
    marginRight: "20px",
    color: "#000",
    fontVariant: "small-caps",
    background: "#E3A700",
    border: "2px solid #fff",
    fontSize: "14px",
    boxShadow: "5px 5px 10px #000",
    fontVariant: "small-caps",
    marginBottom: "10px",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#E3A700",
      cursor: "pointer",
    },
  },
  backbtn: {
    borderRadius: "10px",
    fontSize: "14px",
    color:'#E3A700',
    height:"40px",
    marginTop:'10px',
    fontVariant: "small-caps",
    background:"#404040",
    border:"2px solid #fff",
    marginRight:"20px",
    boxShadow: "5px 5px 10px #000",
    width:"230px",
    fontWeight:'700',
    "&:hover": {
      backgroundColor: "#404040",
      cursor: "pointer",
    },
  },
}));

const UpdateProduct = (props) => {
  const [error, setError] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [lable, setlable] = React.useState("");
  const navigate = useNavigate();
  const { state } = props;
  // console.log(state);
  const [productName, setProductName] = React.useState(state.productName);
  const [baseCpm, setBaseCpm] = React.useState(state.baseCpm);
  const [budget, setBudget] = React.useState(state.budget);
  const [override, setOverride] = React.useState(state.override);
  const [impressions, setImpressions] = React.useState(state.impressions);
  const [clientCpm, setClientCpm] = React.useState(state.clientCpm);
  const [zenonCommission, setZenonCommission] = React.useState(
    state.zenonCommission
  );
  const productId = state._id;

  const initialValues = {
    productName: state.productName,
    // baseCpm: state.baseCpm,
    budget: state.budget,
    override: state.override,
    impressions: state.impressions,
    clientCpm: state.clientCpm,
    zenonCommission: state.zenonCommission,
    value: "",
    category: "",
  };

  const categoryData = [
    { val: 29, name: "Premium Connected TV ONLY (CTV)" },
    { val: 24, name: "Digital Video Blend" },
    { val: 13, name: "Online Video" },
    { val: 13, name: "Streaming Radio" },
    { val: 4, name: "Geofencing" },
    { val: 4.25, name: "Geofencing + Retargeting" },
    { val: 4.5, name: "Geofencing + Data bits+ Retargeting" },
    { val: 13, name: "Geofencing Video" },
    { val: 9, name: "Advanced Email 30k - 49,999" },
    { val: 8, name: "Advanced Email 50k+" },
    { val: 3.5, name: "Banners Awareness" },
    {
      val: 3.75,
      name: "Banners Keyword, Contextual, Demo/Behavioral Site Retargeting",
    },
  ];
  const [formValues, setFormValues] = React.useState(initialValues);
  const [cat, setCat] = React.useState("");
  const Formula = (E4, C5, Selectvalue) => {
    const selectedCategory = findValue(Selectvalue);
    console.log("initValue:=>", E4, C5, Selectvalue);
    const Impressio = ((E4 * (100 - C5)) / 100 / selectedCategory.val) * 1000;
    const zenon = (E4 * C5) / 100;
    const clientCpm = (1000 * E4) / Impressio;
    console.log("Formula:=>", Impressio, zenon, clientCpm);
    setImpressions(Impressio);
    setClientCpm(clientCpm.toFixed(2));
    setZenonCommission(zenon.toFixed(2));
    // console.log("setData", imp, zen, cpm)
  };
  const findValue = (categoryName) => {
    const foundItem = categoryData.find((item) => item.name === categoryName);
    return foundItem; // Return the found item
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("selcr", name, value);
    console.log("name", e.target);

    if (name === "productName") {
      Formula(formValues.budget, formValues.override, value);
    }
    if (name === "budget") {
      if( value >= 0){
        Formula(value, formValues.override, formValues.productName);
      }
      
    }
    if (name === "override") {
      Formula(formValues.budget, value, formValues.productName);
    }
    if(name === "budget"){
      if( value >= 0){
        setFormValues({ ...formValues, [name]: value });
      }
     
    }
  else{
    setFormValues({ ...formValues, [name]: value });
  }
  };

  const [notifyOpen, setNotifyOpen] = React.useState(false);
  const handleClosePop = () => {
    setNotifyOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const valuesToCheck = Object.values(formValues);
    setNotifyOpen(true);
    setError(false);
    setMessage("");
    console.log("productName:", productName);
    const body = {
      productName: formValues.productName,
      budget: formValues.budget,
      override: formValues.override,
      impressions: impressions,
      clientCpm: clientCpm,
      zenonCommission: zenonCommission,
    };
    console.log("body++", body);
    await axios.put(
      `${process.env.REACT_APP_URL}api/products/${productId}`,
      body
    ).then(res=>{
      setFormValues({...formValues, productName: '',budget:'',override:''});
      setClientCpm('')
      setZenonCommission('')
      setImpressions('')
      props.closeDialog(false);
    });
  };
  const goBack = () => {
    props.closeDialog(false);
  };
  const classes = useStyles();
  return (
    <div>
      <Box>
        <form onSubmit={handleSubmit}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 2 }}>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <InputLabel className={classes.label} id="demo-simple-select-label">
                Product Name{" "}
              </InputLabel>
              <FormControl className={classes.input} fullWidth>
                <Select
                required
                  id="demo-simple-select"
                  value={formValues.productName}
                  name="productName"
                  onChange={handleChange}
                >
                  {categoryData.map((categoryItem) => (
                    <MenuItem key={categoryItem.val} value={categoryItem.name}>
                      {categoryItem.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
            <Box sx={{ position: "relative", width: "100%" }}>
                <Typography className={classes.label}>
                  {" "}
                  Budget ($)
                </Typography>
                <TextField
                required
                  fullWidth
                  className={classes.input}
                  name="budget"
                  sx={{
                    "& .MuiInputBase-root": {paddingLeft:"15px"}
                  }}

                  type="number"
                  value={formValues.budget}
                  onChange={handleChange}
                />
                <Box sx={{ position: "absolute", bottom: 18, left: 15 }}>
                    <Typography color={"#fff"}>$</Typography>
                  </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
            <Box sx={{ position: "relative", width: "100%" }}>
                <Typography className={classes.label}>
                  {" "}
                  Override (%)
                </Typography>
                <TextField
                required
                  fullWidth
                  className={classes.input}
                  name="override"
                  value={formValues.override}
                  onChange={handleChange}
                />
                 <Box sx={{ position: "absolute", bottom: 18, right: 15 }}>
                    <Typography color={"#fff"}>%</Typography>
                  </Box>
              </Box>
             
            </Grid>
          </Grid>
          <Box sx={{ pt: 2 }}></Box>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 2 }}>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <Box>
                <Typography className={classes.label}>
                  {" "}
                  Impressions
                </Typography>
                <TextField
                  fullWidth
                  className={classes.input}
                  name="impressions"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={impressions}
                  onChange={handleChange}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <Box>
                <Typography className={classes.label}>
                  {" "}
                  Cleint CPM
                </Typography>
                <TextField
                  fullWidth
                  className={classes.input}
                  name="clientCpm"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={clientCpm}
                  onChange={handleChange}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
            <Box sx={{ position: "relative", width: "100%" }}>
                <Typography className={classes.label}>
                  {" "}
                  Zenon Commission ($)
                </Typography>
                <TextField
                  fullWidth
                  className={classes.input}
                  name="zenonCommission"
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={{
                    "& .MuiInputBase-root": {paddingLeft:"15px"}
                  }}
                  value={zenonCommission}
                  onChange={handleChange}
                />
                     <Box sx={{ position: "absolute", bottom: 18, left: 15 }}>
                    <Typography color={"#fff"}>$</Typography>
                  </Box>
              </Box>
            </Grid>
          </Grid>
          {error && <Alert severity="error">{message}</Alert>}
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              variant="contained"
              className={classes.backbtn}
              onClick={goBack}
            >
              {" "}
              Back{" "}
            </Button>
            <Button
              variant="contained"
              type="submit"
              className={classes.btn}
              sx={{ ml: 2 }}
            >
              {" "}
              Update Product{" "}
            </Button>
          </Box>
        </form>
      </Box>
      <Snack
        open={notifyOpen}
        message={"Product Updated Successfully"}
        severity="success"
        handleClose={handleClosePop}
      />
    </div>
  );
};

export default UpdateProduct;
