import React from 'react';
import { Box } from '@mui/material';
import { DataGrid, GridRowEditStopReasons } from '@mui/x-data-grid';
import { useLocation } from 'react-router-dom';

function Retainer() {
  const { state } = useLocation();
  // console.log(state)
  const [header, ...rows] = state.data.first16Columns;

  const [cardsData, setCardsData] = React.useState(state.data.remainingColumns);
  const [cardValues, setCardValues] = React.useState(cardsData[cardsData.length-1]);
  console.log(cardValues)
  const validHeader = header.map((h, index) => (h === '' ? `Column ${index + 1}` : h));

  const [tableHeader, setTableHeader] = React.useState(validHeader);
  const [tableRows, setTableRows] = React.useState(
    rows.map((row, index) => ({
      id: index + 1,
      ...Object.fromEntries(tableHeader.map((field, index) => [field.toLowerCase(), row[index]])),
    }))
  );

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow };
    setTableRows(tableRows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const columns = tableHeader.map((field) => ({
    field: field.toLowerCase(),
    headerName: field,
    width: 150,
    editable: true,
  }));
  return (
    <div>
      <Box sx={{ height: 350, width: '100%' }}>
        <DataGrid
          rows={tableRows}
          columns={columns}
          editMode="row"
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
        />
      </Box>
      <div style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop:'15px'}}>
            <div style={{width:'226px' , height:'130px', borderRadius:'10px', marginRight:'20px',
            display:'flex', flexDirection:'column',justifyContent:'center', 
            boxShadow:' 0 4px 6px rgba(0, 0, 0, 0.5)', padding:'10px'}}>
                <h4 style={{color:'#757D8A', fontWeight:'450'}}>Totally Weekly Lead In</h4>
                <h2>{cardValues[0]}</h2>
            </div>

            <div style={{width:'226px' , height:'130px', borderRadius:'10px', marginRight:'20px',
            display:'flex', flexDirection:'column',justifyContent:'center', 
            boxShadow:' 0 4px 6px rgba(0, 0, 0, 0.5)', padding:'10px', background:'#5B6AD0', color:'white'}}>
                <h4 style={{ fontWeight:'450'}}>Totally Weekly Signed In</h4>
                <h2>{cardValues[1]}</h2>
            </div>

            <div style={{width:'226px' , height:'130px', borderRadius:'10px', marginRight:'20px',
            display:'flex', flexDirection:'column',justifyContent:'center', 
            boxShadow:' 0 4px 6px rgba(0, 0, 0, 0.5)', padding:'10px'}}>
                <h4 style={{color:'#757D8A', fontWeight:'450'}}>Weekly CPL / Closing %</h4>
                <h2>{cardValues[2]}</h2>
            </div>
        </div>

      </div>

  );
}

export default Retainer;
