import React, { useState, useEffect, useRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { makeStyles } from "@mui/styles";
import { Avatar, Box } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
const useStyles = makeStyles((theme) => ({
  pdfFullView: {
    // width: "100%",
    display: "flex",
    justifyContent: "center",
    height: "80%",

    position: "relative",
    overflow: "hidden",
  },
  pdfViewerContainer: {
    width: "490px",

    height: "654px",
    position: "relative",
    overflow: "hidden",
  },
  pdf: {
    position: "relative",
    top: "0",
    left: "0",
    width: "10%",
    height: "100%",
  },
}));

const MyPdfViewer = ({ pdf, Wstyle }) => {
  const classes = useStyles();
  const url = pdf;
  console.log("url", url,"++s");

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [containerDimensions, setContainerDimensions] = useState({
    width: 0,
    height: 0,
  });
  const containerRef = useRef(null);

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const { clientWidth, clientHeight } = containerRef.current;
        setContainerDimensions({ width: clientWidth, height: clientHeight });
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handlePageChange = (newPageNumber) => {
    console.log(numPages, "numPages")
    if (newPageNumber < 1 || newPageNumber > numPages) {
      console.log(newPageNumber, "newPageNumber")
    
    } else {
      setPageNumber(newPageNumber);
    }
  };

  const widthScale = containerDimensions.width / 590;
  const heightScale = containerDimensions.height / 600;
  console.log(widthScale,heightScale)
  const scale = Math.min(widthScale, heightScale);

  return (
    <>
      <div
        
        className={
          Wstyle === "true" ? classes.pdfFullView : classes.pdfViewerContainer
        }
        ref={containerRef}
      >
        <Document
          file={url}
          onLoadSuccess={onDocumentLoadSuccess}
          // onLoadError={console.error}
          style={{borderRadius:'16px'}}
        >
          <Page pageNumber={pageNumber} scale={scale} className={classes.pdf} />
        </Document>
      </div>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "-60px",
        }}
      >
        <Box
          sx={{
            background: "#878585",
            borderRadius: "30px",
            border: "1px solid #A47901",
            width: "130px",
            height: "45px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Avatar
            sx={{ background: "#000" }}
            onClick={() => handlePageChange(pageNumber - 1)}
          >
            <ArrowBackIosNewIcon />
          </Avatar>
          {pageNumber} /{" "}
          {numPages === null || numPages === "" || numPages === undefined
            ? 0
            : numPages}
          <Avatar
            sx={{ background: "#000" }}
            onClick={() => handlePageChange(pageNumber + 1)}
          >
           <ArrowForwardIosIcon />
          </Avatar>
        </Box>
      </Box>
    </>
  );
};

export default MyPdfViewer;
