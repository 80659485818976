import React from "react";
import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
  Typography,
  Box,
  TableContainer,
  Card,
  Pagination,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import Gradients from "../../utils/Gradients";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Snack from "../../components/snackbar/Snack";
import Searchbar from "../../components/SearchBar/Searchbar";
import CreateIcon from "@mui/icons-material/Create";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useState } from "react";
import CreateProduct from "./components/CreateProduct";
import UpdateProduct from "./components/UpdateProduct";
import MyDialog from "../../components/Dialog/MyDialog";
const TableHeader = styled(TableHead)(({ theme }) => ({
  "& th": {
    marginButtom: "10px",
    borderBottom: "0px",
    border: "none",
    "&:first-child": {
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
    },
    "&:last-child": {
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "10px",
    },
  },
}));
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  // borderRadius: "10px",
  overflow: "hidden",
}));

const useStyles = makeStyles((theme) => ({

  tableCell: {
    color: "#ffffff",
  },
  evenRow: {
    color: "#ffffff",
    backgroundColor: "#787676",
    "&:hover": {
      transform: "scale(1.01)", 
      boxShadow: " 0 0 10px rgba(0, 0, 0, 0.5)", // Add shadow on hover
      backgroundColor: "#6B6B6B",

    },
  },
  oddRow: {
    // backgroundColor: "#6B6B6B",
    backgroundColor: "#787676",
    borderRadius: "20px",
    color: "#ffffff",
    // Odd row color
    "&:hover": {
      transform: "scale(1.01)", 
      boxShadow: " 0 0 10px rgba(0, 0, 0, 0.5)", // Add shadow on hover
      backgroundColor: "#6B6B6B",

    },
  },
  tableCell: {
    color: "#ffffff",
  },
  row: {
    color: "#ffffff",
    "&:hover $cell": {
      color: "#ffffff",
    },
  },
  cell: {
    fontSize: "1.2rem",
    fontWeight: 500,
    letterSpacing: 1,
    color: "#FFF",
    border: "none",
  },
  search: {
    border: "1px solid #fff",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
    borderRadius: "10px",
    "& .MuiInputBase-root": {
      backgroundColor: "#C9975C",
      borderRadius: "10px",
      color: "#fff",
    },
    "& fieldset": {
      border: "none",
    },
  },
  card: {
    padding: "25px",
    border: "2px solid #fff",
    background: "#787676",
    borderRadius: "16px",
    color: "#fff",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
  },
  btn: {
    borderRadius: "10px",
    marginTop: "10px",
    marginRight: "20px",
    color: "#000",
    fontWeight: "bold",
    fontVariant: "small-caps",
    background: "#E3A700",
    border: "1px solid #fff",
    fontSize: "14px",
    boxShadow: "5px 5px 10px #000",
    fontVariant: "small-caps",
    marginBottom: "10px",

    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#E3A700",
      cursor: "pointer",
    },
  },
  headCell: {
    textShadow: "5px 5px 10px #fff",
    fontSize: "1rem",
    fontWeight: 700,
    color: "#FAFF00",
    letterSpacing: 1,
  },
}));
const Products = () => {
  const [resStatus, setResStatus] = React.useState(null);
  const [notifyOpen, setNotifyOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleClosePop = () => {
    setOpen(false);
    setNotifyOpen(false);
  };

  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [singleData, setSingleData] = useState("");

  const [usersData, setUsersData] = React.useState([]);
  const itemsPerPage = 5;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const classes = useStyles();
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const [productData, setProductData] = React.useState([]);
  const [delId, setDelId] = React.useState("");
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  const getProducts = async () => {
    const data = await axios.get(
      `${process.env.REACT_APP_URL}api/products/all/${user.organization._id}`
    );
    const totalUsers = data.data.data.length;
    setTotalPages(Math.ceil(totalUsers / itemsPerPage));

    // Slice the data based on pagination
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setProductData(data.data.data.slice(startIndex, endIndex));
  };
  React.useEffect(() => {
    getProducts();
  }, [currentPage]);

  const handleUpdate = (data) => {
    setSingleData(data);
    setUpdateOpenDialog(true);
  };
  const handleClickOpenPop = (id) => {
    setDelId(id);
    console.log(id);
    setOpen(true);
  };
  const handleDelete = async () => {
    // console.log(productId)
    const data = await axios.delete(
      `${process.env.REACT_APP_URL}api/products/${delId}`
    );
    // console.log(data.data)
    setProductData(data.data.data);
    setOpen(false);

    setNotifyOpen(true);
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [openUpdateDialog, setUpdateOpenDialog] = useState(false);

  const data = useSelector((state) => state);
  const handleDialogOpen = () => {
    setOpenDialog(true);
  };
  const handleDialogClose = (data) => {
    setOpenDialog(data);
    getProducts();
  };
  const handleUpdateDialogClose = (data) => {
    setUpdateOpenDialog(data);
    getProducts();
  };

  const handleChange = async (event) => {
    const value = event.target.value;
    if (value !== "") {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}api/products/search/${user.organization._id}/${value}`
      );
      setResStatus(res.status);
      if (res.status === 201) {
        // SetNotFound(res.data.message);
      } else {
        setProductData(res.data.products);
      }
    } else {
      getProducts();
    }
  };
  return (
    <Box sx={{ height: "90vh" }}>
      <Card
        className={classes.card}
        sx={{ height: "600px", position: "relative" }}
      >
        <Searchbar search={classes.search} onChange={handleChange} />
        <StyledTableContainer>
          {resStatus === 201 ? (
            "Result Not Found"
          ) : (
            <Table>
              <TableHeader sx={{ background: Gradients.cApU }}>
                <TableRow>
                  <TableCell className={classes.headCell}>
                    Product Name
                  </TableCell>
                  <TableCell className={classes.headCell}>Budget</TableCell>
                  <TableCell className={classes.headCell}>
                    Impressions
                  </TableCell>
                  <TableCell className={classes.headCell}>Percentage</TableCell>
                  <TableCell className={classes.headCell}>
                    Commissions
                  </TableCell>
                  <TableCell className={classes.headCell}></TableCell>
                </TableRow>
              </TableHeader>
              <TableBody sx={{ color: "#fff" }}>
                {productData.map((data, index) => (
                  <TableRow
                    key={index}
                    className={
                      index % 2 === 0 ? classes.evenRow : classes.oddRow
                    }
                  >
                    <TableCell className={classes.cell}>
                      <Typography>{data.productName}</Typography>
                    </TableCell>
                    <TableCell className={classes.cell}>
                    ${parseFloat(data.budget).toLocaleString()}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {data.clientCpm}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {(Math.floor(data.impressions).toString().slice(0, 2))}%
                    </TableCell>
                    <TableCell className={classes.cell}>
                ${parseFloat(data.override).toLocaleString()}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <Box sx={{ display: "flex" }}>
                        <Avatar
                          sx={{
                            color: "#000",
                            background: "#E3A700",
                            boxShadow: "5px 5px 10px #000",
                            border: "1px solid #fff",
                          }}
                          onClick={() => handleUpdate(data)}
                        >
                          <CreateIcon sx={{ color: "#000" }} />
                        </Avatar>

                        <Avatar
                          sizes="sm"
                          sx={{
                            color: "#E3A700",
                            background: "#404040",
                            marginLeft: "10px",
                            boxShadow: "5px 5px 10px #000 ",
                            border: "1px solid #fff",
                          }}
                          onClick={() => handleClickOpenPop(data._id)}
                        >
                          <DeleteForeverIcon fontSize="sm" />
                        </Avatar>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </StyledTableContainer>
        <Dialog
          fullWidth
          maxWidth="lg"
          PaperProps={{
            style: {
              background: " linear-gradient(#4F3B03, #E3A700,#503D06)",
              border: "3px solid #fff",
              borderRadius: "24px",
            },
          }}
          open={openUpdateDialog}
          onClose={handleUpdateDialogClose}
        >
          <DialogTitle
            sx={{
              color: "#FAFF00",
              textShadow:
                "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
            }}
          >
            Update Product
          </DialogTitle>
          <DialogContent>
            <UpdateProduct
              closeDialog={handleUpdateDialogClose}
              state={singleData}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          fullWidth
          maxWidth="lg"
          PaperProps={{
            style: {
              background: " linear-gradient(#4F3B03, #E3A700,#503D06)",
              border: "3px solid #fff",
              borderRadius: "24px",
            },
          }}
          open={openDialog}
          onClose={handleDialogClose}
        >
          <DialogTitle
            sx={{
              color: "#FAFF00",
              textShadow:
                "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
            }}
          >
            Add Product
          </DialogTitle>
          <DialogContent>
            <CreateProduct closeDialog={handleDialogClose} />
          </DialogContent>
        </Dialog>
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Pagination
            count={totalPages}
            // count={10}
            page={currentPage}
            onChange={(event, page) => setCurrentPage(page)}
            color="primary"
            sx={{
              position: "absolute",
              bottom: 20,
              "& .MuiPaginationItem-root": {
                background: "#A4A4A4",
                border: "1px solid #fff",
                boxShadow: "5px 5px 10px #000",
                color: "#000",
                "&:hover": {
                  color: "#FFF",
                  background: "#E1A800",
                },
                "&.Mui-selected": {
                  background: "#E1A800",
                },
              },

              "& .MuiPaginationItem-icon": {
                color: "#000", // Set color of the pagination icon to white
              },
            }}
          />
        </Box>
        <MyDialog
          open={open}
          handleClose={handleClosePop}
          handleDelete={handleDelete}
        />
        <Snack
          open={notifyOpen}
          message={"Product Deleted Successfully"}
          severity="error"
          handleClose={handleClosePop}
        />
      </Card>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button className={classes.btn} onClick={handleDialogOpen}>
          + New Product
        </Button>
      </Box>
    </Box>
  );
};

export default Products;
