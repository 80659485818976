import {
    Grid,
    Box,
    Typography,
    TextField,
    styled,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Avatar,
    Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState, useEffect } from "react";
import Gradients from "../../../utils/Gradients";
import axios from "axios";
import { ArrowForwardIosTwoTone } from "@mui/icons-material";
const useStyles = makeStyles((theme) => ({
    btn: {
        marginTop: "50px",
        borderRadius: "12px",
        background: "linear-gradient(180deg, #3CAFE4 0%, #046593 100%)",
        width: "281.211px",
        height: "56px",
    },
}));
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    borderRadius: "20px",
    border: "0.3px solid rgba(32,138,187,0.25)",
    overflow: "hidden",
    marginTop: "20px",
}));
const tableData = [
    {
        avtar: "/assets/images/1.png",
        name: "john Doe",
        title: "founder",
        email: "abc@gmail.com",
    },
    {
        avtar: "/assets/images/2.png",
        name: "john Doe",
        title: "founder",
        email: "abc@gmail.com",
    },
    {
        avtar: "/assets/images/3.png",
        name: "john Doe",
        title: "founder",
        email: "abc@gmail.com",
    },
];
const AddContacts = ({ companyData, onContactsChange, setActiveStep }) => {
    const classes = useStyles();
    console.log(companyData)
    const [contactName, setContactName] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [contactTitle, setContactTitle] = useState('');

    const [contactData, setContactData] = useState([]);
    const [selectedContact, setSelectedContact] = useState('');

    const handleUpdate = () => {
        onContactsChange(contactData)
        setActiveStep('increment');
    };

    console.log(companyData, "Update contact 2nd step");

    const [newContact, setNewContact] = useState({
        name: "",
        email: "",
        title: "",
    });

    const fetchContactsData = async () => {
        try {
            const body = {
                companyId: companyData._id
            }
            console.log(body)
            const response = await axios.post(`${process.env.REACT_APP_URL}companies/all/contacts`, body,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }); // Replace with your API endpoint
            console.log(response)
            setContactData(response.data.data); // Update the state with API response data
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        fetchContactsData();
    }, []);

    const handleInputChange = (field, value) => {
        setNewContact((prevContact) => ({ ...prevContact, [field]: value }));
    };

    const handleAddContact = async () => {
        console.log("added");
        if (contactName && contactEmail && contactTitle) {
            const body = {
                name: contactName,
                email: contactEmail,
                title: contactTitle,
                companyId: companyData._id
            }
            await axios
                .post(`${process.env.REACT_APP_URL}companies/registerContact`, body)
                .then((res) => {
                    setContactData([...contactData, res.data.data])
                    setContactName('');
                    setContactEmail('');
                    setContactTitle('');
                });
        }


    };

    const handleUpdateContact = async () => {
        console.log(selectedContact, "Update Contact:", contactName, contactEmail, contactTitle);
        const body = {
            name: contactName,
            email: contactEmail,
            title: contactTitle,
        }
        const res = await axios.put(`${process.env.REACT_APP_URL}companies/contacts/${selectedContact._id}`, body);
        console.log(res.data.data, 'api response')
        if (res) {
            setContactData([...contactData, res.data.data])
            setContactName('');
            setContactEmail('');
            setContactTitle('');
            setButtonContent("Add Contact");
            console.log(contactData)
        }

    };

    const handleBack = () => {
        setActiveStep('decrement');
    };

    const [buttonContent, setButtonContent] = useState("Add Contact");

    const handleEdit = (index) => {
        console.log("editted", contactData[index]);
        const updatedTableData = [...contactData];
        const selectedItem = updatedTableData.splice(index, 1)[0];
        console.log('Removed from table', selectedItem)
        setSelectedContact(selectedItem)
        setContactData(updatedTableData);
        setButtonContent("Update Contact");
        setContactName(selectedItem.name);
        setContactEmail(selectedItem.email);
        setContactTitle(selectedItem.title);
    };

    const handleDelete = async(index) => {
        console.log("Deleted", contactData[index]);
        const updatedTableData = [...contactData];
        const selectedItem = updatedTableData.splice(index, 1)[0];
        console.log('Removed from table', selectedItem)
        const res = await axios.delete(`${process.env.REACT_APP_URL}companies/contacts/${selectedItem._id}`)
        console.log(res, 'api response')
        setContactData(updatedTableData);
    };


    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={6} md={4} lg={4}>
                    <Box>
                        <Typography sx={{ fontSize: "14px", fontWeight: 600, mb: 1 }}>
                            {" "}
                            Contact Name
                        </Typography>
                        <TextField
                            className={classes.input}
                            value={contactName}
                            onChange={(e) => setContactName(e.target.value)}
                        />
                    </Box>
                </Grid>
                <Grid item xs={6} md={4} lg={4}>
                    <Box>
                        <Typography sx={{ fontSize: "14px", fontWeight: 600, mb: 1 }}>
                            {" "}
                            Contact Email
                        </Typography>
                        <TextField
                            value={contactEmail}
                            onChange={(e) => setContactEmail(e.target.value)}
                            className={classes.input}
                        />
                    </Box>
                </Grid>
                <Grid item xs={6} md={4} lg={4}>
                    <Box>
                        <Typography sx={{ fontSize: "14px", fontWeight: 600, mb: 1 }}>
                            {" "}
                            Contact Title
                        </Typography>
                        <TextField
                            className={classes.input}
                            value={contactTitle}
                            onChange={(e) => setContactTitle(e.target.value)}
                        />
                    </Box>
                    {buttonContent === 'Update Contact' ? (
                        <Button
                            className={classes.btn}
                            variant="contained"
                            onClick={() => handleUpdateContact()}
                        >
                            {" "}
                            {buttonContent}{" "}
                        </Button>
                    ) : (
                        <Button
                            className={classes.btn}
                            variant="contained"
                            onClick={() => handleAddContact()}
                        >
                            {" "}
                            {buttonContent}{" "}
                        </Button>
                    )}

                </Grid>
            </Grid>
            <StyledTableContainer>
                <Table>
                    <TableHead sx={{ background: Gradients.cAp }}>
                        <TableRow>
                            <TableCell
                                sx={{
                                    fontSize: "14px",
                                    fontWeight: 600,
                                    color: "#fff",
                                    letterSpacing: 1,
                                }}
                            >
                                Name
                            </TableCell>
                            <TableCell
                                sx={{
                                    fontSize: "14px",
                                    fontWeight: 600,
                                    color: "#fff",
                                    letterSpacing: 1,
                                }}
                            >
                                Title
                            </TableCell>
                            <TableCell
                                sx={{
                                    fontSize: "14px",
                                    fontWeight: 600,
                                    color: "#fff",
                                    letterSpacing: 1,
                                }}
                            >
                                Email
                            </TableCell>
                            <TableCell
                                sx={{
                                    fontSize: "14px",
                                    fontWeight: 600,
                                    color: "#fff",
                                    letterSpacing: 1,
                                }}
                            >
                                Action
                            </TableCell>
                            <TableCell> </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {contactData.map((val, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell
                                        className={classes.cell}
                                        sx={{ display: "flex", alignItems: "center" }}
                                    >
                                        <Avatar src="/assets/images/3.png" sx={{ mr: 2 }} />
                                        {val.name}
                                    </TableCell>
                                    <TableCell className={classes.cell}>{val.title}</TableCell>
                                    <TableCell className={classes.cell}>{val.email}</TableCell>
                                    <TableCell>
                                        <Box sx={{ display: "flex" }}>
                                            <Avatar
                                                src="/assets/images/edit.png"
                                                onClick={() => handleEdit(index)}
                                                sx={{ cursor: "pointer" }}
                                            />

                                            <Avatar 
                                            src="/assets/images/del.png" 
                                            onClick={() => handleDelete(index)}
                                            sx={{ cursor: "pointer" }}
                                            />
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </StyledTableContainer>
            <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 3, p: 2 }}
            >
                <Typography sx={{ color: "#202224", fontWeight: 600, opacity: 0.6 }}>
                    Showing 1 - 09 of 78
                </Typography>
                <Box sx={{ display: "flex", mt: -2 }}>
                    <Avatar
                        src="/assets/images/b.png"
                        sx={{ height: "95px", width: "95px", mr: -3 }}
                    />
                    <Avatar
                        src="/assets/images/f.png"
                        sx={{ height: "95px", width: "95px" }}
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Button
                    className={classes.btn}
                    variant="contained"
                    onClick={handleBack}
                    sx={{ mr: 2 }}
                >
                    {" "}
                    Go back{" "}
                </Button>
                <Button
                    className={classes.btn}
                    variant="contained"
                    onClick={handleUpdate}
                >
                    {" "}
                    Save & Proceed{" "}
                </Button>
            </Box>
        </div>
    );
};

export default AddContacts;
