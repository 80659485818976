import {
  Avatar,
  Box,
  Stack,
  Table,
  styled,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableContainer,
  Button,
  Divider,
  Grid,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Card,
  InputAdornment,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle
} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CreateIcon from "@mui/icons-material/Create";
import Snack from "../../../components/snackbar/Snack";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";

import axios from "axios";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { grey } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import React, { useState, useEffect } from "react";
import Gradients from "../../../utils/Gradients";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Scheduling from "./Scheduling";
const TableHeader = styled(TableHead)(({ theme }) => ({
  "& th": {
    marginButtom: "10px",
    borderBottom: "0px",
    border: "none",
    "&:first-child": {
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
    },
    "&:last-child": {
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "10px",
    },
  },
}));
const useStyles = makeStyles((theme) => ({
  evenRow: {
    color: "#ffffff",
   
    backgroundColor: "#979797",
    transition: "transform 0.2s ease-in-out",
    borderBottom:'0px',
    "&:hover": {
      transform: "scale(1.01)", 
      boxShadow: " 0 0 5px rgba(0, 0, 0, 0.5)", // Add shadow on hover
      backgroundColor: "#6B6B6B" ,

    },
  },
  oddRow: {
   
    backgroundColor: "#979797",
    // borderRadius: "20px",
    color: "#ffffff",
    // Odd row color
    transition: "transform 0.2s ease-in-out",
    borderBottom:'0px',
    "&:hover": {
      transform: "scale(1.01)", 
      boxShadow: " 0 0 5px rgba(0, 0, 0, 0.5)", // Add shadow on hover
      backgroundColor: "#6B6B6B",
 

    },
  },
  tableCell: {
    color: "#ffffff",
  },
  row: {
    color: "#ffffff",
    "&:hover $cell": {
      color: "#ffffff",
    },
  },
  cell: {
    fontSize: "1.2rem",
    fontWeight: 500,
    letterSpacing: 1,
    color: "#FFF",
    border: "none",
    textShadow: "0 5px 5px rgba(0, 0, 0, 0.5)"

  },
  innercard: {
    border: "3px solid #fff",
    background: " linear-gradient(#30344A, #9EA6DD,#34374D)",
    borderRadius: "12px",
    boxShadow :"inset 0px 1px 5px #fff, 0px 0px 20px black",
    color: "#FAFF00",
  },
  input: {
    border: "1px solid yellow",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
    borderRadius: "10px",
    backgroundColor: "#161A24",
    color: "#fff",

    "& .MuiInputBase-root": {
      backgroundColor: "#161A24",
      borderRadius: "10px",
      color: "#fff",
    },
    "& fieldset": {
      border: "none",
      color: "#fff",
    },
    "& .MuiSvgIcon-root": {
      color: "#fff",
    }
  },
  btn: {
    borderRadius: "10px",
    marginTop: "20px",
    marginRight: "20px",
    color: "#404040",
    fontVariant: "small-caps",
    background: "#E3A700",
    border: "1px solid #fff",
    fontSize: "14px",
    boxShadow: "5px 5px 10px #000",
    fontVariant: "small-caps",
    marginBottom: "10px",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#E3A700",
      cursor: "pointer",
    },
  },
  btn1: {
    borderRadius: "10px",
    marginTop: "20px",
    marginRight: "20px",
    color: "#E3A700",
    fontVariant: "small-caps",
    background: "#404040",
    border: "1px solid #fff",
    fontSize: "14px",
    boxShadow: "5px 5px 10px #000",
    fontVariant: "small-caps",
    marginBottom: "10px",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#404040",
      cursor: "pointer",
    },
  },
  cardtextHeader: {
    color: "#fff",
    // textStroke: "1px solid black",
    textShadow:
      "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
    fontSize: "14px",
    // textShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
  },
  cardtext: {
    color: "#fff",
    // textStroke: "1px solid black",
    textShadow:
      "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
    fontSize: "25px",
    fontWeight: 700,
    // textShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
  },

  typo1: {
    color: "#9A9A9A",
    fontWeight: 600,
    fontSize: "14px",
  },
  typo2: {
    color: "#238FC1",
    fontWeight: 700,
    fontSize: "12px",
  },
  head: {
    fontSize: "14px",
    fontWeight: 800,
    color: "#fff",
    fontStyle: "normal",
    lineHeight: "normal",
  },
  // cell: {
  //   fontSize: "16px",
  //   fontWeight: 600,
  //   lineHeight: "normal",
  // },
  box: {
    borderRadius: "12px",
    // border: '0.3px solid #208ABB',
    border: "0.3px solid rgba(32,138,187,0.25)",
    background: "#E6F6FE",
    boxShadow: " 0px 14px 25px 0px rgba(141, 112, 112, 0.12)",
  },
  btn: {
    borderRadius: "10px",
    marginTop: "10px",
    marginRight: "20px",
    color: "#000",
    fontVariant: "small-caps",
    background: "#E3A700",
fontWeight:"700",
// width:'200px',
    border: "2px solid #fff",
    fontSize: "14px",
    boxShadow: "5px 5px 10px #000",
    fontVariant: "small-caps",
    marginBottom: "10px",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#E3A700",
      cursor: "pointer",
    },
  },
  bbtn: {
    borderRadius: "10px",
    marginTop: "10px",
    marginRight: "20px",
    color: "#E3A700",
    fontVariant: "small-caps",
    background: "#404040",

    border: "1px solid #fff",
    fontSize: "14px",
    boxShadow: "5px 5px 10px #000",
    fontVariant: "small-caps",
    marginBottom: "10px",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#404040",
      cursor: "pointer",
    },
  },
  btn3: {
    textTransform: "none",
    borderRadius: "12px",
    fontSize: "1.2rem",
    width: "190px",
    height: "56px",
    marginRight: "5px",
    opacity: "1",
    boxShadow:
      "0px 5px 4px 0px rgba(244, 244, 244, 0.25) inset, 0px -2px 4px 0px rgba(0, 0, 0, 0.25) inset",
  },
  box2: {
    width: "97%",
    height: "235px",
    flexShrink: 0,
    color: "#fff",
    border: "1px solid yellow",
    background: "#161A24",
    padding: "15px",
    borderRadius: "10px",

    marginLeft: "10px",
  },
  field: {
    background: "#FFF",
    strokeWidth: "1px",
    stroke: "#52A5CF",
    boxShadow:
      "0px 5px 4px 0px rgba(244, 244, 244, 0.25) inset, 0px -2px 4px 0px rgba(0, 0, 0, 0.25) inset",
  },
  date: {
    background: "white",
    // strokeWidth: '0.5px',
    // stroke: '#52A5CF',
    boxShadow:
      "0px 5px 4px 0px rgba(244, 244, 244, 0.25) inset, 0px -2px 4px 0px rgba(0, 0, 0, 0.25) inset",
    opacity: "0.5",
    width: "220px",
    height: "56px",
    padding: "15px",
    borderRadius: "10px",
  },
  cell: {
    fontSize: "16px",
    fontWeight: 800,
    color: "#fff",
  },
  textArea: {
    marginTop: "10px",
    background: "#BFE3A0",
    padding: "10px",
    borderRadius: "10px",
    border: "none",
    boxShadow:
      "0px 5px 4px 0px rgba(244, 244, 244, 0.25) inset, 0px -2px 4px 0px rgba(0, 0, 0, 0.25) inset",
  },
  card: {
    marginTop: "10px",
    border: "2px solid #fff",
    background: "#979797",
    
    borderRadius: "16px",
    color: "#fff",
    boxShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
  },
  label:{
    color:'#fff',
    textShadow:'5px 5px 10px #000'
  }
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  // borderRadius: "10px",
  border: "none",

  overflow: "hidden",
}));
const CompanyCrm = () => {
  React.useEffect(() => {}, []);
  const navigate = useNavigate();
  const goBack = () => {
    navigate("/dashboard/crm");
  };
  const classes = useStyles();
  // ----------------------------------------------
  const [items, setItems] = useState([]);
  const [formValues, setFormValues] = useState({
    Pname: "",
    price: "",
  });
  const handleTextChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newItem = { productName, amount };
    if (selectedItemIndex !== null) {
      // If an index is selected, update the item at that index
      const updatedTableData = [...tableData];
      updatedTableData.splice(selectedItemIndex, 0, newItem);
      setTableData(updatedTableData);
      setButtonContent("Add Item");
    } else {
      // If no index is selected, add a new item to the tableData
      setTableData((prevTableData) => [...prevTableData, newItem]);
    }

    // setItems((prevItems) => [...prevItems, formValues]);

    setProductName("");
    setAmount("");

    setSelectedItemIndex(null);
    console.log("Updated Data:", newItem);
    console.log("Table Data:", tableData);
  };

  // --------------------------

  //     const [formValues, setFormValues]=React.useState(initialValues)
  // const handleTextChange = (e) => {
  //     const {name , value} = e.target
  //     setFormValues({...formValues, [name]: value});
  // }
  // const handleSubmit = (e) => {
  //     e.preventDefault()
  //     // console.log(formValues)
  //     tableData.push(formValues)
  //     console.log(tableData)
  // }
  const user = useSelector((state) => state.auth.user);
  const [age, setAge] = React.useState("");
  const { state } = useLocation();
  const [tableData, setTableData] = useState(state?.products || []);
  const [selectedValue, setSelectedValue] = React.useState(state?.industry);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [notifyOpen, setNotifyOpen] = React.useState(false);
  console.log(state, "state");
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const [data, setData] = React.useState([]);
  // const [contact, setContact] = React.useState([]);
  const getData = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_URL}companies/dropdown`
    );
    // console.log(res.data.data)
    setData(res.data.data);
  };

  // const getContact = async () => {
  //   const res = await axios.get(`${process.env.REACT_APP_URL}companies/firstContact/${state}`)
  //   console.log(res.data.data)
  //   setContact(res.data.data)
  // }
  React.useEffect(() => {
    getData();
    // getContact();
  }, []);

  const handleButtonClick = () => {
    console.log("state", state?.companyName);
    navigate(`/dashboard/scheduling`, { state: state });
  };

  //  -------------------------Update Changes all handles-------------------------------
  const initialDate = state?.initialContactDate
    ? new Date(state.initialContactDate)
    : new Date();
  const formattedDate = initialDate.toISOString().split("T")[0];

  const [selectedDate, setSelectedDate] = useState(formattedDate);
  const [selectedLeadSource, setSelectedLeadSource] = useState(
    state?.leadSource
  );
  const [selectedCompanyClassification, setSelectedCompanyClassification] =
    useState(state?.companyClassification);
  const [selectedContactDiscussion, setSelectedContactDiscussion] = useState(
    state?.initialContactDiscussion
  );
  const [selectedLeftOff, setSelectedLeftOff] = useState(state?.howItLeftOff);

  const [selectedTargetValue, setSelectedTargetValue] = useState(
    state?.targetValue
  );

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleDelete = async () => {
    console.log("Delete Click ");
    const res = await axios.delete(
      `${process.env.REACT_APP_URL}companies/delete/${state._id}`
    );

    console.log(res);
    navigate("/dashboard/crm");
  };
  const handleLeadChange = (event) => {
    setSelectedLeadSource(event.target.value);
  };

  const handleCompanyClassificationChange = (event) => {
    setSelectedCompanyClassification(event.target.value);
  };
  const handleContactDiscussionChange = (event) => {
    setSelectedContactDiscussion(event.target.value);
  };
  const handleLeftOffChange = (event) => {
    setSelectedLeftOff(event.target.value);
  };
  const handleTargetValueChange = (event) => {
    setSelectedTargetValue(event.target.value);
  };

  const [formDatac, setFormDataC] = useState({
    companyName: [],
    productName: [],
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [message, SetMessage] = useState('');
  const [status,setStatus] = useState('')
  const handleUpdateChange = async () => {
    // console.log('Initial Contact Date:', selectedDate);
    // console.log('Industry:', selectedValue);
    // console.log('Lead Source:', selectedLeadSource);
    // console.log('Company Classification:', selectedCompanyClassification);
    // console.log('Contact Discussion:', selectedContactDiscussion);
    // console.log('Left Off:', selectedLeftOff);
    // console.log('Target Value:', selectedTargetValue);
    // console.log(tableData)

    const body = {
      initialContactDate: selectedDate,
      industry: selectedValue,
      leadSource: selectedLeadSource,
      companyClassification: selectedCompanyClassification,
      notes: state?.notes,
      initialContactDiscussion: selectedContactDiscussion,
      howItLeftOff: selectedLeftOff,
      targetValue: selectedTargetValue,
      products: tableData,
    };
try {
  const res = await axios.put(
    `${process.env.REACT_APP_URL}companies/update/crm/${state._id}`,
    body
  );
  setNotifyOpen(true);
  SetMessage(res.data.message);
  setStatus('success');
  console.log(res, "api response");
} catch (error) {
  setNotifyOpen(true);
  SetMessage(error.data.message);
  setStatus('danger');

}
   
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_URL}api/retainerinfo/${user.organization._id}`
      )
      .then((res) => {
        setFormDataC({
          ...formDatac,
          companyName: res.data.companies,
          productName: res.data.retainerProducts,
        });
      });
  }, []);
  const [productName, setProductName] = useState("");
  const [amount, setAmount] = useState("");

  const handleProductNameChange = (e) => {
    setProductName(e.target.value);
  };
  console.log('data other',state)
  const handleAmountChange = (e) => {
    if(e.target.value > -1){
      setAmount((e.target.value));
    }
    // Convert to a number if needed
  };

  const [selectedItem, setSelectedItem] = useState({
    productName: "", // Provide a default value or leave it empty based on your needs
    amount: "",
  });
  const [buttonContent, setButtonContent] = useState("Add Product");

  const handleEdit = (index) => {
    const updatedTableData = [...tableData];
    const selectedItem = updatedTableData.splice(index, 1)[0];
    setTableData(updatedTableData);

    setProductName(selectedItem.productName);
    setAmount(selectedItem.amount);

    setSelectedItemIndex(index);
    setButtonContent("Update Product");
  };
  const handleDialogClose = (data) => {
    setOpenDialog(data);
  };
  const handleDialogOpen = () => {
    setOpenDialog(true);
  };
  const handleDel = (index) => {
    console.log("Deleted row");
    const updatedTableData = [...tableData];
    const selectedItem = updatedTableData.splice(index, 1)[0];
    setTableData(updatedTableData);
  };
  const handleClosePop = () => {
    // setOpen(false);
    setNotifyOpen(false);
  };
  const handleInstagramClick = () => {
    const instagramUrl = state.instagramLink; // Replace 'https://www.example.com' with the actual Instagram URL
    window.open(instagramUrl, '_blank'); // Opens the URL in a new window
  };
console.log('Initial Contact Date:', );
  return (
    <Box>
      <Box >
        <Box
          sx={{
            display: "flex",
            mb: 5,
            justifyContent: "space-between",
            alignItems: "center",

          }}
        >
          <Box  sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer", 
          }}
          
          onClick={goBack}
          >
            <ArrowBackIcon/>
          
           <Typography sx={{ fontWeight: 600, color: "#202224",}}>
               Back to CRM
              </Typography>
              </Box>
          <Box
             sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
           
            }}>    
          <Avatar
            sx={{
              color: "#E3A700",
              background: "#fff",
              marginLeft: "10px",
              border: "2px solid #E3A700",
              mr: 2,
            }}
            onClick={handleDelete}
          >
            <DeleteForeverIcon />
          </Avatar>
          <Button
            className={classes.bbtn}
            variant="outlined"
            onClick={handleDialogOpen}
          >
            Schedule Meeting
          </Button>
          <Button
            className={classes.btn}
            variant="contained"
            onClick={handleUpdateChange}
          >
            {" "}
            Save Changes{" "}
          </Button>
          </Box>
      

        </Box>
      </Box>
      <Card className={classes.card}>
        <CardContent>
          <Grid
            container
            rowSpacing={1}
            sx={{ marginTop: "10px" }}
            columnSpacing={{ xs: 1, md: 2 }}
          >
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Card className={classes.innercard}>
                <CardContent style={{ textAlign: "center" }}>
                  <Typography className={classes.cardtextHeader}>
                    Company Name
                  </Typography>
                  <Typography className={classes.cardtext}>
                    {state?.companyName? state.companyName : "-"}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Card className={classes.innercard}>
                <CardContent style={{ textAlign: "center" }}>
                  <Typography className={classes.cardtextHeader}>
                    Contact Name
                  </Typography>
                  <Typography className={classes.cardtext}>
                    {state?.firstContact?.name? state.firstContact.name : "-"}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Card className={classes.innercard}>
                <CardContent style={{ textAlign: "center" }}>
                  <Typography className={classes.cardtextHeader}>
                    Phone Number
                  </Typography>
                  <Typography className={classes.cardtext}>
                    {(state?.contact)?state?.contact:'-'}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Card className={classes.innercard}>
                <CardContent style={{ textAlign: "center" }}>
                  <Typography className={classes.cardtextHeader}>
                    Website Link
                  </Typography>
                  <Typography className={classes.cardtext}>
                    {state?.websiteLink?state?.websiteLink:'-'}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Card className={classes.innercard} sx={{ mt: 2, mb: 2 }}>
            <Box
              style={{
            
                padding:'10px',
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ fontWeight: "bold", fontSize: "16px", color: "#fff" }}
              >
                {" "}
                Social Link
              </Typography>
              <Box sx={{ display: "flex" }}>
              <IconButton>
                <FacebookIcon sx={{ color: "#fff" }} />
               </IconButton>
               <IconButton onClick={handleInstagramClick}>
                <InstagramIcon sx={{ mx: 2, color: "#fff" }} />
                
               </IconButton>
                
               <IconButton >
                <TwitterIcon sx={{ color: "#fff" }} />
                </IconButton>
                {/* {state.instagramLink} */}
              </Box>
            </Box>
          </Card>

          <Grid container sx={{ mt: 5 }} spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <Typography className= {classes.label} sx={{ fontWeight: 600, color: "#202224", mb: 2 }}>
                Initital Contact Date
              </Typography>
              <TextField
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-input': {
                    colorScheme: 'dark', // Corrected property name and value
                  },
                }}
                type="date"
                className={classes.input}
                value={selectedDate}
                onChange={handleDateChange}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
            <Typography className= {classes.label} sx={{ fontWeight: 600, color: "#202224", mb: 2 }}>

                Industry
              </Typography>
              <FormControl fullWidth>
                <Select
                  className={classes.input}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Industry"
                  value={selectedValue}
                  onChange={handleChange}
                >
                  {data.map((item, ind) => (
                    <MenuItem key={ind} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
            <Typography className= {classes.label} sx={{ fontWeight: 600, color: "#202224", mb: 2 }}>

                Lead Source{" "}
              </Typography>
              <TextField
                fullWidth
                className={classes.input}
                value={selectedLeadSource}
                onChange={handleLeadChange}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
            <Typography className= {classes.label} sx={{ fontWeight: 600, color: "#202224", mb: 2 }}>

                Company Classification
              </Typography>
              <TextField
                fullWidth
                className={classes.input}
                value={selectedCompanyClassification}
                onChange={handleCompanyClassificationChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={4}>
              <Typography sx={{ mt: 3 }}>Notes</Typography>
              {state?.notes ? (
                
                <textarea
                style={{ resize: "none", width: "100%", padding: "10px" }}
                className={classes.input}
                value={state?.notes}
                // onChange={handleContactDiscussionChange}
                placeholder="Enter Text here"
                rows="14"
                readOnly
                cols="37"
                maxlength="150"
              ></textarea>
                
              ) : (
               
                    <textarea
                    style={{ resize: "none", width: "100%", padding: "10px" }}
                    className={classes.input}
                    value={' No notes data'}
                    // onChange={handleContactDiscussionChange}
                    placeholder="Enter Text here"
                    rows="14"
                    readOnly
                    cols="37"
                    maxlength="150"
                  ></textarea>
              )}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Typography sx={{ mt: 3 }}>
                Initital Contact Discussion:
              </Typography>

              <textarea
                style={{ resize: "none", width: "100%", padding: "10px" }}
                className={classes.input}
                value={selectedContactDiscussion}
                onChange={handleContactDiscussionChange}
                placeholder="Enter Text here"
                rows="14"
                cols="37"
                maxlength="150"
              ></textarea>
            </Grid>
            <Grid item xs={12} md={5} lg={4}>
              <Typography sx={{ mt: 3 }}>How it Left Off</Typography>

              <textarea
                style={{ resize: "none", width: "100%", padding: "10px" }}
                value={selectedLeftOff}
                onChange={handleLeftOffChange}
                className={classes.input}
                placeholder="Enter Text here"
                rows="14"
                cols="37"
                maxlength="200"
              ></textarea>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
<Box  sx={{ marginTop: '50px' }} />
      <Card className={classes.card}>
        <CardContent>
          <form onSubmit={handleSubmit}>
            {selectedItem && (
              <Box sx={{ display: "flex" }}>
                <Grid container spacing={1} sx={{ mt: 3 }}>
                  <Grid item sx={12} md={5} lg={5}>
                  <Typography className= {classes.label} sx={{ fontWeight: 600, color: "#202224", mb: 2 }}>

                      Product Name
                    </Typography>
                    <FormControl className={classes.input} fullWidth>
                      <Select
                        required
                        id="demo-simple-select"
                        value={productName}
                        name="Pname"
                        // label="Age"
                        onChange={handleProductNameChange}
                      >
                        {formDatac.productName.map((categoryItem) => (
                          <MenuItem
                            key={categoryItem._id}
                            value={categoryItem.productName}
                          >
                            {categoryItem.productName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item sx={12} md={2} lg={5}>
                  <Box sx={{ position: "relative", width: "100%" }}>
                  <Typography className= {classes.label} sx={{ fontWeight: 600, color: "#202224", mb: 2 }}>

                      Amount
                    </Typography>
                    <TextField
                      fullWidth
                      sx={{
                        "& .MuiInputBase-root": {paddingLeft:"15px"}
                      }}
                      className={classes.input}
                      name="price"
                      onChange={handleAmountChange}
                      type="number"
                      value={amount}
                    />
                      <Box sx={{ position: "absolute", bottom: 18, left: 15 }}>
                    <Typography color={"#fff"}>$</Typography>
                  </Box>
              </Box>
                  </Grid>
                  <Grid item sx={12} md={4} lg={2}>
                    <Box sx={{ mt: 3.8 }}>
                      <Button
                        variant="contained"
                        className={classes.btn}
                        type="submit"
                        sx={{ height:'56px',width:'95%' }}
                      >
                        {" "}
                        {buttonContent}{" "}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
          </form>
        </CardContent>
        <Box sx={{ pl: 2, pr: 3, pb: 3 }}>
          <StyledTableContainer>
            <Table>
              <TableHeader sx={{ background: Gradients.cApU }}>
                <TableRow>
                  <TableCell className={classes.cell}>Product Name</TableCell>
                  <TableCell className={classes.cell}>Amount</TableCell>
                  <TableCell className={classes.cell}>Action</TableCell>
                </TableRow>
              </TableHeader>
              <TableBody sx={{ background: "#fff" }}>
                {tableData.map((item, index) => {
                  return (
                    <TableRow
                      key={index}
                      className={
                     
                        index % 2 === 0 ? classes.evenRow : classes.oddRow
                      }
                      sx={{
                        cursor: "pointer",
                        // borderRadius: "20px",
                        color:'#fff',
                        color: "#fff",
                      }}
                    >
                      <TableCell
                        sx={{
                          fontSize: "16px",
                          fontWeight: 500,
                          color: "#fff",
                          borderBottom: "none",
                        }}
                      >
                        {" "}
                        {item.productName}
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#fff",
                          fontSize: "16px", 
                          fontWeight: 500,
                          borderBottom: "none",
                        }}
                      >
                        {" "}
                       ${parseFloat(item.amount).toLocaleString()}
                      </TableCell>
                      <TableCell sx={{ borderBottom: "none" }}>
                        <Box sx={{ display: "flex" }}>
                          <Avatar
                           sx={{ color: "#000", background: "#E3A700", boxShadow: "5px 5px 10px #000", border:'1px solid #fff'}}
                            onClick={() => handleEdit(index)}
                          >
                            <CreateIcon sx={{ color: "#000" }} />
                          </Avatar>

                          <Avatar
                            sizes="sm"
                            sx={{ color: "#E3A700", background: "#404040", boxShadow: "5px 5px 10px #000", border:'1px solid #fff',marginLeft:'20px'}}
                            onClick={() => handleDel(index)}
                          >
                            <DeleteForeverIcon fontSize="sm" />
                          </Avatar>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Box>
        <Box sx={{ p: 2 }}>
          <Typography sx={{ fontSize: "18px", fontWeight: 700 }} className={classes.label}>
            {" "}
            Target Value
          </Typography>
          <TextField
            multiline
            rows={9}
            fullWidth
            sx={{ mt: 2 }}
            placeholder="Type Here..."
            value={selectedTargetValue}
            onChange={handleTargetValueChange}
            className={classes.input}
          />
        </Box>
      </Card>

      <Dialog
          fullWidth
          maxWidth="lg"
          PaperProps={{
            style: {
              background: " linear-gradient(#4F3B03, #E3A700,#503D06)",
              border: "3px solid #fff",
              borderRadius: "24px",
            },
          }}
          open={openDialog}
          onClose={handleDialogClose}
        >
          <DialogTitle
            sx={{
              color: "#FAFF00",
              textShadow:
                "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
            }}
          >
         
          </DialogTitle>
          <DialogContent>
            <Scheduling closeDialog={handleDialogClose} />
          </DialogContent>
        </Dialog>


        <Snack
          open={notifyOpen}
          message={message}
          severity={status}
          handleClose={handleClosePop}
        />



    </Box>
  );
};

export default CompanyCrm;
