import React, { useState } from "react";
import {
  Box,
  Button,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Alert,
  Grid,
  TextareaAutosize,
  CircularProgress,
} from "@mui/material";
// import CircularProgress  from '@mui/material/CircularProgress';
import axios from "axios";
import { useNavigate } from "react-router";
import { makeStyles } from "@mui/styles";
import RatingInput from "../../../components/Rating/RatingInput";
import FileViewerComponent from "../../../components/FileViewer/FileViewerComponent";
// import { Document, Page } from 'react-pdf'
import MyPdfViewer from "./MyPdfViewer";
import Snack from "../../../components/snackbar/Snack";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  input: {
    border: "1px solid yellow",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
    borderRadius: "10px",
    "& .MuiInputBase-root": {
      backgroundColor: "#161A24",
      borderRadius: "10px",
      color: "#fff",
    },
    "& fieldset": {
      border: "none",
    },
    "& .MuiSvgIcon-root": {
      color: "#fff",
    },
  },
  backbtn: {
    borderRadius: "10px",
    fontSize: "14px",
    color: "#E3A700",
    height: "40px",
    marginTop: "10px",
    fontVariant: "small-caps",
    background: "#404040",
    border: "2px solid #fff",
    marginRight: "20px",
    boxShadow: "5px 5px 10px #000",
    width: "230px",
    fontWeight: "700",
    "&:hover": {
      backgroundColor: "#404040",
      cursor: "pointer",
    },
  },
  btn: {
    height: "40px",
    width: "230px",
    fontWeight: "700",
    borderRadius: "10px",
    marginTop: "10px",
    marginRight: "20px",
    color: "#000",
    fontVariant: "small-caps",
    background: "#E3A700",
    border: "2px solid #fff",
    fontSize: "14px",
    boxShadow: "5px 5px 10px #000",
    fontVariant: "small-caps",
    marginBottom: "10px",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#E3A700",
      cursor: "pointer",
    },
  },
  //  label: {
  //     fontSize: "14px",
  //     fontWeight: 600,
  //     mb: 1,
  //     color:"#fff",
  //     marginTop:"10px"
  //   }
  label: {
    marginTop: "10px",
    fontSize: "14px",
    fontWeight: "bold",
    color: "white",
    paddingBottom: "5px",
    textShadow: "5px 5px 10px #000",
  },
}));

const CreateContracts = (props) => {
  const user = useSelector((state) => state.auth.user);
  const [client, setClient] = useState("");
  const [signedDate, setSignedDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [services, setServices] = useState("");
  const [compaign, setCompaign] = useState("");
  const [monthlySpend, setMonthlySpend] = useState("");
  
  const [file, setFile] = useState([]);
  console.log(file);
  const handleClientChange = (event) => {
    setClient(event.target.value);
  };
  const handleSignedDateChange = (event) => {
    setSignedDate(event.target.value);
  };
  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };
  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };
  const handleServicesChange = (event) => {
    setServices(event.target.value);
  };
  const handleCompaignChange = (event) => {
    setCompaign(event.target.value);
  };
  const handleMonthlySpendChange = (event) => {
    if (event.target.value >= 0) {
      setMonthlySpend(event.target.value);
    }
  };
  // snack
  const [response, setResponse] = React.useState("");
  const [severity, setSeverity] = React.useState("");
  const [notifyOpen, setNotifyOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const handleClosePop = () => {
    setNotifyOpen(false);
  };
  const camp = [{ val: "CCA" }, { val: "CP" }, { val: "PA" }, { val: "REF" }];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    const body = {
      client,
      signedDate,
      startDate,
      endDate,
      services,
      campaign: compaign,
      monthlySpend,
      file,
      userId: user.organization._id,
    };

    console.log(body);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}contracts`,
        body,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        setNotifyOpen(true);
        setResponse("Created Successfully");
        setSeverity("success");
        setClient("");
        setSignedDate("");
        setStartDate("");
        setEndDate("");
        setServices("");
        setCompaign("");
        setMonthlySpend("");
        setIsLoading(false)
        setTimeout(()=> { props.closeDialog(false)}, 3000)
       
      } else {
        setIsLoading(false)
        setNotifyOpen(true);
        setResponse("Failed to create contract .");
        setSeverity("error");
        console.error("Failed to create contract");
      }
    } catch (error) {
      setIsLoading(false)
      console.error("Error:", error);
    }
  };

  // const url = "https://www.africau.edu/images/default/sample.pdf";


  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileType = selectedFile.type;
      // Check if the selected file type is either PDF or Word document
      if (fileType === "application/pdf" || fileType === "application/msword" || fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
        setFile(selectedFile);
      } else {
        // Show error message
        setNotifyOpen(true);
        setResponse("Please select a PDF  or Word document file.");
        setSeverity("error");
        event.target.value = null;
      }
    }
  };
  const navigate = useNavigate();
  const goBack = () => {
    props.closeDialog(false);
  };
  const classes = useStyles();
  return (
    <div style={{ marginTop: "-40px" }}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 2 }}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <div
            style={{
              width: "495px",
              height: "660px",
              border: "3px solid #fff",

              borderRadius: "0px",
              marginTop: "40px",
            }}
          >
            {file !== null ? (
              <MyPdfViewer pdf={file} /> // Render MyPdfViewer with the selected file
            ) : (
              <p>Please select a PDF file to view.</p> // Render a message if no file is selected
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <form onSubmit={handleSubmit}>
            <Box sx={{ mt: 5 }}></Box>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 2 }}>
              <Grid item xs={12} md={12} lg={12}>
                <Box>
                  <Typography className={classes.label}>Client</Typography>
                  <TextField
                    required
                    fullWidth
                    className={classes.input}
                    name="Client"
                    placeholder="Client"
                    value={client}
                    onChange={handleClientChange}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 2 }}>
              <Grid item xs={12} md={6} lg={4}>
                <Box>
                  <Typography className={classes.label}>
                    Send & Signed Date
                  </Typography>
                  <TextField
                    required
                    fullWidth
                    type="date"
                    className={classes.input}
                    name="SignedDate"
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        colorScheme: "dark", // Corrected property name and value
                      },
                    }}
                    value={signedDate}
                    onChange={handleSignedDateChange}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Box>
                  <Typography className={classes.label}>Start Date</Typography>
                  <TextField
                    required
                    fullWidth
                    type="date"
                    className={classes.input}
                    name="EndDate"
                    value={startDate}
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        colorScheme: "dark", // Corrected property name and value
                      },
                    }}
                    onChange={handleStartDateChange}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={12} lg={4}>
                <Box>
                  <Typography className={classes.label}>End Date</Typography>
                  <TextField
                    required
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        colorScheme: "dark", // Corrected property name and value
                      },
                    }}
                    type="date"
                    className={classes.input}
                    name="EndDate"
                    value={endDate}
                    onChange={handleEndDateChange}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 2 }}>
              <Grid item xs={12} md={6} lg={4}>
                <Box>
                  <Typography className={classes.label}>Services</Typography>
                  <TextField
                    required
                    fullWidth
                    className={classes.input}
                    name="Services"
                    placeholder="Services"
                    value={services}
                    onChange={handleServicesChange}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Box>
                  <Typography className={classes.label}>Compaign</Typography>
                  {/* <TextField
                required  
                  fullWidth
                  className={classes.input}
                  name="Compaign"
                  placeholder="Compaign"
                  value={compaign}
                  onChange={handleCompaignChange}
                /> */}
                  <FormControl className={classes.input} fullWidth>
                    <Select
                      id="demo-simple-select"
                      value={compaign}
                      name="Compaign"
                      required
                      placeholder="Compaign"
                      onChange={handleCompaignChange}
                    >
                      {camp.map((categoryItem) => (
                        <MenuItem
                          key={categoryItem.val}
                          value={categoryItem.val}
                        >
                          {categoryItem.val}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} md={12} lg={4}>
                <Box sx={{ position: "relative", width: "100%" }}>
                  <Typography className={classes.label}>
                    {" "}
                    Monthly Spend ($)
                  </Typography>
                  <TextField
                    required
                    fullWidth
                    type="number"
                    className={classes.input}
                    name="MonthlySpend"
                    sx={{
                      "& .MuiInputBase-root": {paddingLeft:"15px"}
                    }}
                    value={monthlySpend}
                    onChange={handleMonthlySpendChange}
                  />
                          <Box sx={{ position: "absolute", bottom: 18, left: 15 }}>
                    <Typography color={"#fff"}>$</Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 2 }}>
              <Grid item xs={12} md={12} lg={12}>
                <Typography className={classes.label}> Upload</Typography>
                <div>
                  {/* <Button className={classes.btn3}>
                                    Choose File to Upload
                                </Button> */}

                  <TextField
                    required
                    fullWidth
                    className={classes.input}
                    multiple={false}
                    type="file"
                    onChange={handleFileChange}
                  />
                </div>
              </Grid>
            </Grid>

            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
              <Button
                variant="outlined"
                className={classes.backbtn}
                onClick={goBack}
              >
                Back{" "}
              </Button>
              <Button
                variant="contained"
                type="submit"
                className={classes.btn}
                sx={{ ml: 2 }}
                disabled={isLoading}
                // onClick={handleSubmit}
              >
               {isLoading ? <CircularProgress/> : "Upload Contract"}
              </Button>
            </Box>
          </form>
        </Grid>
      </Grid>

      <Snack
        open={notifyOpen}
        message={response}
        severity={severity}
        handleClose={handleClosePop}
      />
    </div>
  );
};

export default CreateContracts;
