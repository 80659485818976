import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Card,
  Box,
  Button,
  Grid,
} from "@mui/material";
import { pdfjs } from "react-pdf";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles((theme) => ({
  card: {
    // marginLeft: '10px',
    // marginTop: '10px',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "311px",
    height: "261px",
    borderRadius: "25px",
    // flexShrink: '0',
    background:
      "var(--prime-blue-gradiant, linear-gradient(180deg, #3CAFE4 0%, #046593 100%))",
    "& img": {
      width: "100%",
      height: "auto",
      maxWidth: "100%",
      borderRadius: theme.spacing(1),
    },
  },
  cardContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    justifyContent: "space-evenly",
    backgroundColor: "#E6F6FE",
  },
  maintypo: {
    display: "flex",
    justifyContent: "center",
    color: "#979191",
    fontFamily: "Nunito Sans",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    letterSpacing: "-0.114px",
  },
  image: {
    width: "311px",
    height: "261px",
  },
  btn: {
    borderRadius: "10px",
    marginTop: "20px",
    marginRight: "20px",
    color: "#E3A700",
    fontVariant: "small-caps",
    background: "#404040",
    border: "2px solid #fff",
    fontSize: "14px",
    boxShadow: "5px 5px 10px #000",
    fontVariant: "small-caps",
    marginBottom: "10px",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#404040",
      cursor: "pointer",
    },
  },
  btn1: {
    borderRadius: "10px",
    marginTop: "20px",
    marginRight: "20px",
    color: "#404040",
    fontVariant: "small-caps",
    background: "#E3A700",
    border: "2px solid #fff",
    fontSize: "14px",
    boxShadow: "5px 5px 10px #000",
    fontVariant: "small-caps",
    marginBottom: "10px",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#E3A700",
      cursor: "pointer",
    },
  },
  card: {
    padding: "25px",
    border: "2px solid #fff",
    background: "#9A9A9A",
    borderRadius: "16px",
    color: "#fff",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
  },
}));

const PdfThumbnail = ({ pdfUrl, width, height }) => {
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    const loadPdfThumbnail = async () => {
      try {
        const loadingTask = pdfjs.getDocument(pdfUrl);
        const pdf = await loadingTask.promise;
        const firstPage = await pdf.getPage(1);
        const viewport = firstPage.getViewport({ scale: 1 });
        const scale = Math.min(
          width / viewport.width,
          height / viewport.height
        );
        const scaledViewport = firstPage.getViewport({ scale });

        const canvas = document.createElement("canvas");
        canvas.width = scaledViewport.width;
        canvas.height = scaledViewport.height;
        const context = canvas.getContext("2d");

        await firstPage.render({
          canvasContext: context,
          viewport: scaledViewport,
        }).promise;

        // Adjust canvas to fit the desired dimensions if necessary
        const finalCanvas = document.createElement("canvas");
        finalCanvas.width = width;
        finalCanvas.height = height;
        const finalCtx = finalCanvas.getContext("2d");
        finalCtx.fillStyle = "#FFFFFF"; // Fill with white background
        finalCtx.fillRect(0, 0, width, height);
        // Center the rendered PDF page in the thumbnail
        finalCtx.drawImage(
          canvas,
          (width - scaledViewport.width) / 2,
          (height - scaledViewport.height) / 2
        );

        setImageUrl(finalCanvas.toDataURL());
      } catch (error) {
        console.error("Error generating PDF thumbnail", error);
      }
    };

    loadPdfThumbnail();
  }, [pdfUrl, width, height]);

  return imageUrl ? (
    <img
      src={imageUrl}
      alt="PDF Thumbnail"
      style={{
        width,
        height,
        objectFit: "cover",
        borderRadius: "16px",
        border: "3px solid #fff",
      }}
    />
  ) : (
    <Box
      style={{
        width,
        height,
        objectFit: "cover",
        borderRadius: "16px",
        border: "3px solid #fff",
      }}
    ></Box>
  );
};

const Files = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate("/dashboard/library");
  };
  const classes = useStyles();
  const user = useSelector((state) => state.auth.user);
  const [pdfItems, setPdfItems] = useState([]);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState({
    url: "",
    name: "",
    entryDate: "",
  });

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_URL}api/files/file/${user.organization._id}`
      )
      .then((response) => {
        setPdfItems(response.data);
      })
      .catch((error) => {
        console.error("Error fetching PDF files:", error);
      });
  }, [user.organization._id]);

  const openFullScreen = (url, name, entryDate) => {
    setSelectedPdf({ url, name, entryDate });
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };
  console.log(pdfItems, "pdfItems");
  return (
    <Box sx={{ height: "90vh" }}>
      <Card className={classes.card}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button onClick={goBack} className={classes.btn}>
            Back to Library
          </Button>
          <Typography
            sx={{
              mt: 2,
              color: "#FAFF00",
              fontWeight: "700",
              fontSize: "27px",
              fontFamily: "Nunito Sans",
              textShadow:
                "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
            }}
          >
            FILES
          </Typography>
        </Box>
        <Grid container spacing={1} sx={{ mt: 5 }}>
          {pdfItems.map((pdf) => (
            <Grid
              key={pdf._id}
              style={{ margin: "10px", cursor: "pointer" }}
              onClick={() =>
                openFullScreen(
                  `${process.env.REACT_APP_URL}${pdf.filePath}`,
                  pdf.name,
                  pdf.entryDate
                )
              }
              item
              xs={12}
              md={3}
              lg={2}
            >
              <PdfThumbnail
                style={{}}
                pdfUrl={`${process.env.REACT_APP_URL}${pdf.filePath}`}
                width={206}
                height={215}
              />
            </Grid>
          ))}
        </Grid>

        {selectedPdf && (
          <Dialog open={lightboxOpen} onClose={closeLightbox} fullScreen>
            <DialogTitle>
              <button
                onClick={closeLightbox}
                style={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  background: "none",
                  border: "none",
                  color: "#000",
                  fontSize: "20px",
                  cursor: "pointer",
                }}
              >
                X
              </button>
            </DialogTitle>
            <DialogContent>
              <div
                style={{
                  width: "100%",
                }}
              >
                <iframe width={"100%"} height={"800px"} src={selectedPdf.url} />
              </div>
            </DialogContent>
          </Dialog>
        )}
      </Card>
    </Box>
  );
};

export default Files;
