import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Alert,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { useNavigate } from "react-router";
import MyDialog from "../../../components/Dialog/MyDialog";
import Snack from "../../../components/snackbar/Snack";
import { useSelector } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import categoryData from "../../../utils/CountryCode";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
const useStyles = makeStyles((theme) => ({
  textfield: {
    marginBottom: "20px",
    background: "#fff",
  },
  label: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "white",
    paddingBottom: "5px",
    textShadow: "5px 5px 10px #000",
  },
  disp: {
    marginTop: "30px",
    display: "flex",

    justifyContent: "flex-end",
  },
  backbtn: {
    borderRadius: "10px",
    fontSize: "14px",
    color: "#E3A700",
    height: "40px",
    marginTop: "10px",
    fontVariant: "small-caps",
    background: "#404040",
    border: "2px solid #fff",
    marginRight: "20px",
    boxShadow: "5px 5px 10px #000",
    width: "230px",
    fontWeight: "700",
    "&:hover": {
      backgroundColor: "#404040",
      cursor: "pointer",
    },
  },
  btn: {
    height: "40px !important",
    width: "230px !important",
    fontWeight: "700",
    borderRadius: "10px !important",
    marginTop: "10px",
    marginRight: "20px",
    color: "#000",
    fontVariant: "small-caps",
    background: "#E3A700",
    border: "2px solid #fff !important",
    fontSize: "14px",
    boxShadow: "5px 5px 10px #000",
    fontVariant: "small-caps",
    marginBottom: "10px ",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#E3A700",
      cursor: "pointer",
    },
  },
  select: {
    border: "1px solid yellow",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
    borderRadius: "10px",
    "& .MuiInputBase-root": {
      backgroundColor: "#161A24",
      borderRadius: "10px",
      color: "#fff",
    },
    "& fieldset": {
      border: "none",
    },
    "& .MuiSvgIcon-root": {
      color: "#fff",
    }
  },
}));

// const isValidContactNumber = (contactNumber) => {
//   return /^\d{13}$/.test(contactNumber);
// };
const CreateUser = (props) => {
  const org = useSelector((state) => state.auth.user.organization);

  const initialValues = {
    name: "",
    email: "",
    contact: "",
    timeZone: "",
    role: "user",
    password: "",
    organizationId: org._id,
  };



  
  const [countryCodestate, SetCountryCodestate] = React.useState(null);
  const [error, setError] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [confirmPassword, setConfrimPassword] = React.useState("");
  const [showPassword, setShowPassword] = useState(false);
  // snack
  const [response, setResponse] = React.useState("");
  const [severity, setSeverity] = React.useState("");
  const [notifyOpen, setNotifyOpen] = React.useState(false);
  const handleClosePop = () => {
    setNotifyOpen(false);
  };
  const navigate = useNavigate();
  const [formValues, setFormValues] = React.useState(initialValues);
  const goBack = () => {
    props.closeDialog(false);
  };
  const [category, setCategory] = useState(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == "category") {
      setCategory(value);
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    for (const key in formValues) {
      if (!formValues[key].trim()) {
        setError(true);
        return setMessage(`Please fill ${key} data`);
      }
    }

    // if (!isValidContactNumber(formValues.contact)) {
    //   setError(true);
    //   setMessage("Please enter a valid 10-digit contact number.");
    //   return;
    // }

    if (formValues.password.length < 6) {
      setError(true);
      return setMessage("Password should be at least 6 characters long.");
    }
    if (formValues.password !== confirmPassword) {
      setError(true);
      setMessage("Passwords do not match.");
      return;
    }

    setError(false);
    setMessage("");

    const body = {
      name: formValues.name,
      email:formValues.email,
      contact : formValues.contact,
      timeZone: formValues.timeZone,
      phoneCode : category,
      role: "admin",
      password: formValues.password,
      organizationId: org._id,
      
    } ;
    console.log(body);
    await axios
      .post(`${process.env.REACT_APP_URL}users/register`, body)
      .then((res) => {
        if(res.data.status === 200){
        setResponse(res.data.message);
        setSeverity("success");
        setNotifyOpen(true);}else{
          setResponse(res.data.message);
          setSeverity("error");
          setNotifyOpen(true);

        }
        setFormValues(initialValues);
        setConfrimPassword("");
        setTimeout(() => {
          props.closeDialog(false);
        }, 100000);
      })
      .catch((err) => {
        console.log(err);
        setSeverity("error");
        setTimeout(() => {
          props.closeDialog(false);
        }, 100000);
      });
  };
  const [role, setRole] = React.useState("");
  const classes = useStyles();
  return (
    <div>
      <Box>
        <form onSubmit={handleSubmit}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 1 }}>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <InputLabel className={classes.label}>Name</InputLabel>{" "}
              <TextField
                fullWidth
                name="name"
                value={formValues.name}
                onChange={handleChange}
                required={true}
                placeholder="Enter Full Name here"
                className={classes.select}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <InputLabel className={classes.label}>Email</InputLabel>{" "}
              <TextField
                fullWidth
                name="email"
                value={formValues.email}
                onChange={handleChange}
                required={true}
                type="email"
                placeholder="johndoe@abc.com"
                className={classes.select}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <InputLabel className={classes.label}>Contact Number</InputLabel>{" "}
              <Box display={"flex"}>
              {/* <FormControl className={classes.select} sx={{marginRight:"10px", width:'120px'}}>
                <Select
                  id="demo-simple-select"
                  value={category}
                  name="category"
                  required
                  // label="Age"
                  onChange={handleChange}
                >
                  {categoryData.map((categoryItem) => (
                    <MenuItem key={categoryItem.dial_code} value={categoryItem.dial_code}>
                      {categoryItem.dial_code}
                    </MenuItem>
                  ))}
                </Select>
             
              </FormControl> */}
              <TextField
                  fullWidth
                  name="contact"
                  value={formValues.contact}
                  onChange={handleChange}
                  required
                  placeholder="type here"
                  className={classes.select}
                />
              </Box>
             
            </Grid>
          </Grid>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 2 }}>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <FormControl fullWidth></FormControl>
              <InputLabel className={classes.label}>Time Zone</InputLabel>{" "}
              <TextField
                fullWidth
                name="timeZone"
                value={formValues.timeZone}
                onChange={handleChange}
                required
                placeholder="US 00:00 UTC"
                className={classes.select}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <Box sx={{ position: "relative", width: "100%" }}>
                <FormControl fullWidth></FormControl>
                <InputLabel className={classes.label}>
                  Create Password
                </InputLabel>{" "}
                <TextField
                  fullWidth
                  required
                  name="password"
                  value={formValues.password}
                  onChange={handleChange}
                  placeholder="* * * * * * *"
                  type={showPassword ? "text" : "password"}
                  className={classes.select}
                />
                <Box
                  sx={{ position: "absolute", bottom: 10, right: 20 }}
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? (
                    <VisibilityIcon sx={{ color: "#fff" }} />
                  ) : (
                    <VisibilityOffIcon sx={{ color: "#fff" }} />
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <Box sx={{ position: "relative", width: "100%" }}>
                <FormControl fullWidth></FormControl>
                <InputLabel className={classes.label}>
                  Confirm Password
                </InputLabel>{" "}
                <TextField
                  fullWidth
                  required
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfrimPassword(e.target.value);
                  }}
                  placeholder="* * * * * * *"
                  type={showPassword ? "text" : "password"}
                  className={classes.select}
                />
                <Box
                  sx={{ position: "absolute", bottom: 10, right: 20 }}
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? (
                    <VisibilityIcon sx={{ color: "#fff" }} />
                  ) : (
                    <VisibilityOffIcon sx={{ color: "#fff" }} />
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>

          {error && <Alert severity="error">{message}</Alert>}

          <div className={classes.disp}>
            <Button
              variant="outlined"
              className={classes.backbtn}
              onClick={goBack}
            >
              Back
            </Button>
            <Button variant="contained" type="submit" className={classes.btn}>
              {" "}
              Create User{" "}
            </Button>
          </div>
        </form>
      </Box>
      <Snack
        open={notifyOpen}
        message={response}
        severity={severity}
        handleClose={handleClosePop}
      />
    </div>
  );
};

export default CreateUser;
