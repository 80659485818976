import React, { useEffect, useState } from "react";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
// import calandar from "../../../../public/assets/images/calendar.svg"
import { NativeSelect } from "@mui/material";
import {
  Box,
  Button,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Alert,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { useNavigate } from "react-router";
import { Label } from "@mui/icons-material";
import Snack from "../../../components/snackbar/Snack";
const useStyles = makeStyles((theme) => ({
  textfield: {
  
    border: "1px solid yellow",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
    borderRadius: "10px",
    "& .MuiInputBase-root": {
      backgroundColor: "#161A24",
      borderRadius: "10px",
      color: "#fff",
    },
    "& fieldset": {
      border: "none",
    },
    "& .MuiSvgIcon-root": {
      color: "#fff",
    }
  },
  btn: {
    width:"260px",

    borderRadius: "8px",
    fontWeight:"700",
    color: "#161A24",
    fontVariant: "small-caps",
    background: "#E3A700",
    border: "2px solid #fff",
    fontSize: "1.2rem",
    fontVariant: "small-caps",
    transition: "background-color 0.3s ease", // Adding transsition for smooth effect
    "&:hover": {
      backgroundColor: "#E3A700", // Changing background color on hover
      cursor: "pointer", // Showing pointer cursor on hover
      // Add any additional styles for hover state here
    },
  },
  backbtn: {
    width:"260px",
    borderRadius: "8px",
    fontSize: "1rem",
    color: "#E3A700",
    fontVariant: "small-caps",
    background: "#404040",
    fontWeight:"700",
    border: "2px solid #fff",
    marginRight: "20px",
    boxShadow: "5px 5px 10px #000",
  },
  disp: {
    marginTop: "30px",
    display: "flex",
    justifyContent: "flex-end",
  },
  heading: {
    marginBottom: "20px",
  },

  date: {
    background: "#FFFFFF",
    // boxShadow: '0px 5px 4px 0px rgba(244, 244, 244, 0.25) inset, 0px -2px 4px 0px rgba(0, 0, 0, 0.25) inset',
    // opacity:'0.7',
    width: "335px",
    height: "56px",
    padding: "15px",
    borderRadius: "5px",
    marginBottom: "20px",
    border: "0.5px solid grey",
  },
  label: {
    color: "#fff",
    textShadow: "5px 5px 10px #000",
    
  },
}));

const AddSheet = (props) => {
  const user = useSelector((state) => state.auth.user);
  const classes = useStyles();
  const [formDatac, setFormDataC] = useState({
    companyName: [],
    productName: [],
  });
  const [notifyOpen, setNotifyOpen] = React.useState(false);
  const handleClosePop = () => {
    setNotifyOpen(false);
  };
  const [formData, setFormData] = useState({
    userId: user.organization._id,
    companyName: "",
    typeOfTort: "",
    day: "",
    numberOfSigned: "",
    numberDQed: "",
    date: "",
  });

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_URL}api/retainerinfo/${user.organization._id}`
      )
      .then((res) => {
        setFormDataC({
          ...formDatac,
          companyName: res.data.companies,
          productName: res.data.retainerProducts,
        });
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`${process.env.REACT_APP_URL}api/retainers`, {
        userId: user.organization._id,
        ...formData,
      })
      .then((response) => {
        setSuccess("Retainer added successfully!");
        setNotifyOpen(true);
        setFormData({
          companyName: "",
          typeOfTort: "",
          day: "",
          numberOfSigned: "",
          numberDQed: "",
          date: "",
        });
        // props.closeDialog(false)
      })
      .catch((error) => {
        setNotifyOpen(true);
        setError("Failed to add retainer. Please try again.");
        // props.closeDialog(false)
      });
  };

  const goBack = () => {
    props.closeDialog(false);
  };
  const daysData = [
    { val: "Mon", name: "Monday" },
    { val: "Tue", name: "Tuesday" },
    { val: "Wed", name: "Wednesday" },
    { val: "Thu", name: "Thursday" },
    { val: "Fri", name: "Friday" },
    { val: "Sat", name: "Saturday" },
    { val: "Sun", name: "Sunday" },
  ];

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 2 }}>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <InputLabel
                className={classes.label}
                id="demo-simple-select-label"
              >
                {" "}
                Company Name
              </InputLabel>
              <FormControl className={classes.textfield} fullWidth>
                <Select
                  id="demo-simple-select"
                  value={formData.companyName}
                  name="companyName"
                  required
                  // placeholder="Select an Item"
                  onChange={handleFieldChange}
                >
                  {formDatac.companyName.map((categoryItem) => (
                    <MenuItem
                      key={categoryItem._id}
                      value={categoryItem.companyName}
                    >
                      {categoryItem.companyName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <label className={classes.label}>Type of Tort</label>
              <FormControl className={classes.textfield} fullWidth>
                <Select
                  required
                  id="demo-simple-select"
                  value={formData.typeOfTort}
                  name="typeOfTort"
                  // label="Age"
                  onChange={handleFieldChange}
                >
                  {formDatac.productName.map((categoryItem) => (
                    <MenuItem
                      key={categoryItem._id}
                      value={categoryItem.productName}
                    >
                      {categoryItem.productName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <label className={classes.label}>Day</label>
              <FormControl className={classes.textfield} fullWidth>
                <Select
                  required
                  id="demo-simple-select"
                  value={formData.day}
                  name="day"
                  // label="Age"
                  onChange={handleFieldChange}
                >
                  {daysData.map((categoryItem) => (
                    <MenuItem key={categoryItem.val} value={categoryItem.name}>
                      {categoryItem.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, md: 2 }}
            sx={{ mt: 2 }}
          >
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <label className={classes.label}>Number of Signed/Sent</label>
              <TextField
                fullWidth
                name="numberOfSigned"
                value={formData.numberOfSigned}
                onChange={handleFieldChange}
                placeholder="Type Here"
                className={classes.textfield}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <label className={classes.label}>Number DQ'ed</label>
              <TextField
                name="numberDQed"
                fullWidth
                value={formData.numberDQed}
                onChange={handleFieldChange}
                placeholder="Type Here"
                className={classes.textfield}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <label className={classes.label}>Date</label>

              <TextField
                fullWidth
                name="date"
                type="date"
                value={formData.date}
                onChange={handleFieldChange}
                className={classes.textfield}
                sx={{
                  '& .MuiOutlinedInput-input': {
                    colorScheme: 'dark', // Corrected property name and value
                  },
                }}
                required
              />
            </Grid>
          </Grid>
          <div className={classes.disp}>
            <Button
              variant="outlined"
              className={classes.backbtn}
              onClick={goBack}
            >
              Back
            </Button>
            <Button variant="contained" type="submit" className={classes.btn}>
              Create Retainer
            </Button>
          </div>
        </Box>
      </form>
      <Snack
        open={notifyOpen}
        message={success ? success : error}
        severity={success ? "success" : "error"}
        handleClose={handleClosePop}
      />
    </Box>
  );
};

export default AddSheet;
