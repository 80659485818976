import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  Avatar,
} from "@mui/material";
import { styled } from "@mui/system";
import Pagination from "@mui/material/Pagination";

const TableHeader = styled(TableHead)(({ theme }) => ({
  "& th": {
    backgroundColor: "#005197",
    color: "white",
    borderBottom: "0px",
    marginButtom: "10px",
    boxShadow: "0px 1px 0px 0px #fff",
    "&:first-child": {
      borderTopLeftRadius: "16px",
      borderBottomLeftRadius: "16px",
    },
    "&:last-child": {
      borderTopRightRadius: "16px",
      borderBottomRightRadius: "16px",
    },
  },
}));

const CustomerList = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedData = props.data.slice(startIndex, endIndex);
  const totalPages = Math.ceil(props.data.length / itemsPerPage);

  // const handlePrevPage = () => {
  //   setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  // };

  // const handleNextPage = () => {
  //   setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  // };
  

  return (
    <TableContainer
      component={Paper}
      sx={{
        background: "transparent",
        color: "white",
        maxHeight: "300px",
        overflow: "hidden",
        pt: 3,
      }}
    >
      <Table aria-label="Project Table">
        <TableHeader>
          <TableRow>
            <TableCell
              sx={{
                color: "white",
                border: "none",
                fontWeight: "bold",
                textShadow: "5px 5px 10px #000",
                fontSize: "16px",
              }}
            >
              Person
            </TableCell>
            <TableCell
              sx={{
                color: "white",
                border: "none",
                fontWeight: "bold",
                textShadow: "5px 5px 10px #000",
                fontSize: "16px",
              }}
            >
              Company
            </TableCell>
            <TableCell
              sx={{
                color: "white",
                border: "none",
                fontWeight: "bold",
                textShadow: "5px 5px 10px #000",
                fontSize: "16px",
              }}
            >
              Email
            </TableCell>
            <TableCell
              sx={{
                color: "white",
                border: "none",
                fontWeight: "bold",
                textShadow: "5px 5px 10px #000",
                fontSize: "16px",
              }}
            >
              Contact
            </TableCell>
          </TableRow>
        </TableHeader>

        <TableBody sx={{ margin: 2 }}>
          {displayedData.map((project, index) => (
            <TableRow
              key={project.id}
              sx={{
                // backgroundColor: index % 2 === 0 ? "" : "#6B6B6B",
                borderTopLeftRadius: index % 2 === 0 ? "16px" : 0,
                borderBottomLeftRadius: index % 2 === 0 ? "16px" : 0,
                borderTopRightRadius: index % 2 !== 0 ? "16px" : 0,
                borderBottomRightRadius: index % 2 !== 0 ? "16px" : 0,
                "&:hover": {
                  transform: "scale(1.01)", 
                  boxShadow: " 0 0 10px rgba(0, 0, 0, 0.5)", // Add shadow on hover
                  backgroundColor: "#6B6B6B",
            
                },
              }}
            >
              <TableCell
                sx={{
                  border: "none",
                  borderTopLeftRadius: "16px",
                  borderBottomLeftRadius: "16px",
                  color: "#fff",
                }}
              >
                {project.firstContact.name}
              </TableCell>
              <TableCell sx={{ border: "none", color: "#fff" }}>
                {project.companyName}
              </TableCell>
              <TableCell sx={{ border: "none", color: "#fff" }}>
                {project.firstContact.email}
              </TableCell>
              <TableCell
                sx={{
                  border: "none",
                  borderTopRightRadius: "16px",
                  borderBottomRightRadius: "16px",
                  color: "#fff",
                }}
              >
                {project.contact}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 3, mb: 4 }}>
        <Pagination
          count={totalPages}
          // count={10}
          page={currentPage}
          onChange={(event, page) => setCurrentPage(page)}
          color="primary"
          sx={{
            "& .MuiPaginationItem-root": {
              background: "#1D212B",
              color: "#FFF",
              "&:hover": {
                color: "#187ACE",
              },
              "&.Mui-selected": {
                background: "#187ACE",
              },
            },

            "& .MuiPaginationItem-icon": {
              color: "#FFF", // Set color of the pagination icon to white
            },
          }}
        />
      </Box>
    </TableContainer>
  );
};

export default CustomerList;
