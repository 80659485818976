import {
  Alert,
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  colors,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import {
  Typography,
  Button,
  TextField,
  Avatar,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  Card,
  CardContent,
  styled,
} from "@mui/material";
import React, { useEffect } from "react";
import axios from "axios";
import Gradients from "../../../utils/Gradients";
import Snack from "../../../components/snackbar/Snack";
import CreateIcon from "@mui/icons-material/Create";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import categoryData from "../../../utils/CountryCode";
const useStyles = makeStyles((theme) => ({
  evenRow: {
    color: "#ffffff",
    backgroundColor: "#9A9A9A",
    "&:hover $cell": {
      color: "#ffffff",
    },
  },
  btnSave: {
    color: "#000",
    background: "#fff",
    border: "2px solid black",
    marginTop: "10px",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#fff",
      cursor: "pointer",
      border: "2px solid black",
    },
  },
  oddRow: {
    backgroundColor: "#6B6B6B",
    borderRadius: "20px",
    color: "#ffffff",
    // Odd row color
    "&:hover $cell": {
      color: "#ffffff",
    },
  },

  tableCell: {
    color: "#ffffff",
  },
  row: {
    color: "#ffffff",
    "&:hover $cell": {
      color: "#ffffff",
    },
  },
  cell: {
    fontSize: "1.2rem",
    fontWeight: 500,
    letterSpacing: 1,
    color: "#FFF",
    border: "none",
  },
  btng: {
    marginBottom: "2rem",
  },
  card: {
    padding: "25px",
    border: "2px solid #fff",
    background: "#9A9A9A",
    borderRadius: "16px",
    color: "#fff",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
  },
  cardtextHeader: {
    color: "FAFF00",
    // textStroke: "1px solid black",
    textShadow:
      "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
    fontSize: "14px",
    // textShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
  },
  input: {
    border: "1px solid yellow",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
    borderRadius: "10px",
    backgroundColor: "#000",
    color: "#fff",

    "& .MuiInputBase-root": {
      backgroundColor: "#000",
      borderRadius: "10px",
      color: "#fff",
    },
    "& fieldset": {
      border: "none",
      color: "#fff",
    },
    "& .MuiSvgIcon-root": {
      color: "#fff",
    },
  },
  btn: {
    borderRadius: "10px",
    marginTop: "20px",
    marginRight: "20px",
    color: "#404040",
    fontVariant: "small-caps",
    background: "#E3A700",
    border: "1px solid #fff",
    fontSize: "14px",
    boxShadow: "5px 5px 10px #000",
    fontVariant: "small-caps",
    marginBottom: "10px",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#E3A700",
      cursor: "pointer",
    },
  },
  cardtext: {
    color: "FAFF00",
    textShadow:
      "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
    fontSize: "16px",
  },

  innercard: {
    border: "2px solid #FAFF00",
    background: " linear-gradient(#494235, #9D9491,#494235)",
    borderRadius: "16px",
    color: "#FAFF00",
  },
  backbtn: {
    borderRadius: "10px",
    fontSize: "14px",
    color: "#E3A700",
    height: "40px",
    marginTop: "10px",
    fontVariant: "small-caps",
    background: "#404040",
    border: "1px solid #fff",
    marginRight: "20px",
    boxShadow: "5px 5px 10px #000",
  },
  label: {
    color: "white",
    paddingBottom: "5px",
    textShadow: "5px 5px 10px #000",
  },
}));

const steps = [
  {
    label: "Step 1",
    sub: "Company Details",
    description: `Enter Company Details and contact details`,
  },
  {
    label: "Step 2",
    sub: "Add Contacts",
    description: "Enter company employee details and their roles",
  },
  {
    label: "Step 3",
    sub: "Notes & Lead",
    description: `Add Additional Notes and type of leads`,
  },
  {
    label: "Step 4",
    sub: "Finish",
    description: `Review your information`,
  },
];

const TableHeader = styled(TableHead)(({ theme }) => ({
  "& th": {
    marginButtom: "10px",
    borderBottom: "0px",
    border: "none",

    "&:first-child": {
      // borderTopLeftRadius: "10px",
      // borderBottomLeftRadius: "10px",
    },
    "&:last-child": {
      // borderTopRightRadius: "10px",
      // borderBottomRightRadius: "10px",
    },
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  // borderRadius: "10px",
  overflow: "hidden",
}));
const isValidContactNumber = (contactNumber) => {
  return /^\d{10}$/.test(contactNumber);
};
const CreateContact = (props) => {
  const org = useSelector((state) => state.auth.user.organization);
  const [data, setData] = React.useState([]);
  const getData = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_URL}companies/dropdown`
    );

    setData(res.data.data);
  };
  React.useEffect(() => {
    getData();
  }, []);

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  console.log("props.state.phoneCode", props.state);

  const initialValues = {
    companyName: props.state ? props.state.companyName : "",
    category: props.state ? props.state.industry : "",
    contact: props.state ? props.state.contact : "",
    code: null,
    websiteLink: props.state ? props.state.websiteLink : "",
    instagramLink: props.state ? props.state.instagramLink : "",
  };
  const initialValues2 = {
    name: "",
    email: "",
    title: "",
  };
  // console.log("updatedate", props.state);
  const initialValues3 = {
    type: "",
    notes: "",
  };
  const [formValues, setFormValues] = React.useState(initialValues);
  const [error, setError] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [companyDetailsData, setCompanyDetailsData] = React.useState([]);
  const [formValues3, setFormValues3] = React.useState(initialValues3);
  const [code, setCode] = React.useState("+1");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setError(false);
    if (name == "code") {
      console.log("code++++++++++++", value);
      setFormValues({ ...formValues, code: value });
      // setCode(value);
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  console.log("check", formValues, props.state);
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // if (Object.values(formValues).some((value) => value.trim() === "")) {
      //   setError(true);
      //   setMessage("Please fill all the data fields.");
      //   return;
      // } else if (!isValidContactNumber(formValues.contact)) {
      //   setError(true);
      //   setMessage("Please enter a valid 11-digit contact number.");
      //   return;
      // }
      if (props.check == true) {
        console.log("check");
        //add api for update

        const body = {
          companyName: formValues.companyName,
          category: formValues.category,
          contact: formValues.contact,
          phoneCode: formValues.code,
          websiteLink: formValues.websiteLink,
          instagramLink: formValues.instagramLink,
        };
        const res = await axios.put(
          `${process.env.REACT_APP_URL}companies/update/${props.state._id}`,
          body
        );
        setCompanyDetailsData(res.data.data);
        handleNext();
      } else {
        await axios
          .post(`${process.env.REACT_APP_URL}companies/registerCompany`, {
            user: org._id,
            ...formValues,
          })
          .then((res) => {
            console.log("companyDetail", res.data.data);
            if (res.status === 200) {
              setCompanyDetailsData(res.data.data);
              handleNext();
              setFormValues(initialValues);
            } else {
              setError(true);
              setMessage(res.data.message);
              setFormValues(initialValues);
            }
          });
      }
    } catch (error) {
      console.log(error.message);
      setError(true);
      setMessage("Internal Server Error. Please try again later.");
    }
  };
  // console.log("catagary", formValues);
  const [formValues2, setFormValues2] = React.useState(initialValues2);
  const [error2, setError2] = React.useState(false);
  const [message2, setMessage2] = React.useState("");
  console.log(formValues2);
  const handleChange2 = (e) => {
    const { name, value } = e.target;
    if (name == "title") {
      if (/^[a-zA-Z\s]+$/.test(value) || value === "") {
        setFormValues2({ ...formValues2, [name]: value });
      }
    } else {
      setFormValues2({ ...formValues2, [name]: value });
    }
  };
  const getTableData = async () => {
    const body = {
      companyId: ContactTableData,
    };

    await axios
      .get(`${process.env.REACT_APP_URL}companies/contacts`, body)
      .then((res) => {
        console.log(res);
      });
  };
  const [ContactTableData, setContactTableData] = React.useState([]);
  const [singleContact, setSingleContact] = React.useState();
  const [tableData, setTableData] = React.useState([]);
  const [contactAction, setContactAction] = React.useState(false);
  // useEffect(() => {
  //   postData();
  // }, []);
  const postData = async (data) => {
    console.log("props.state._id", data);
    setSingleContact(data);
    setContactAction(true);
    setFormValues2({
      ...formValues2,
      email: data?.email,
      name: data?.name,
      title: data?.title,
    });
  };
  const getAllContact = async (id) => {
    console.log("call", id);
    let res = null;
    if (props.check) {
      res = await axios.get(
        `${process.env.REACT_APP_URL}companies/Read/${props.state._id}`
      );
    } else {
      console.log("call");
      res = await axios.get(`${process.env.REACT_APP_URL}companies/Read/${id}`);
    }

    setTableData(res?.data?.data?.contacts);
    // console.log("notes: res?.data?.data?.leads?.notes",res?.data?.data.leads[0]?.notes)
    setFormValues3({
      ...formValues3,
      notes: res?.data?.data?.leads[0]?.notes,
      type: res?.data?.data?.leads[0]?.type,
    });
  };

  const handleDelete = async (ContactID) => {
    const res = await axios.delete(
      `${process.env.REACT_APP_URL}companies/contacts/${ContactID}`
    );
    console.log(res, "api response");

    getAllContact();
  };

  console.log(formValues3);
  useEffect(() => {
    if (props.check) {
      getAllContact();
    }
  }, []);

  const handleSubmit2 = async (e) => {
    let body = null;
    setError2(false);
    e.preventDefault();
    if (props.check) {
      body = {
        ...formValues2,
        companyId: props.state._id,
      };
    } else {
      body = {
        ...formValues2,
        companyId: companyDetailsData._id,
      };
    }

    try {
      if (
        Object.values(formValues2).some(
          (value) => typeof value === "string" && value.trim() === ""
        )
      ) {
        setError2(true);
        setMessage2("Please fill all the data fields.");
        return;
      }

      if (props.check == true) {
        console.log("check");
        //add api for update

        if (contactAction) {
          const body = formValues2;
          const res = await axios.put(
            `${process.env.REACT_APP_URL}companies/contacts/${singleContact._id}`,
            body
          );
          getAllContact();
          setFormValues2({ ...formValues2, email: "", name: "", title: "" });
          setContactAction(false);
        } else {
          await axios
            .post(`${process.env.REACT_APP_URL}companies/registerContact`, body)
            .then((res) => {
              console.log(res.data.status, "res.data.status");
              if (res.data.status == "success") {
                setContactTableData(res.data.data);
                setTableData([res.data.data]);

                const newArray = [...tableData, res.data.data];
                setTableData(newArray);
                setFormValues2({
                  ...formValues2,
                  email: "",
                  name: "",
                  title: "",
                });

                getTableData();
              } else {
                setError2(true);
                setMessage2(res.data.message);
              }
            });
        }

        // handleNext();
      } else {
        if (contactAction) {
          const body = formValues2;
          const res = await axios.put(
            `${process.env.REACT_APP_URL}companies/contacts/${singleContact._id}`,
            body
          );
          // console.log(res.data.data.company, "RESPONSE")
          getAllContact(res.data.data.company);
          setFormValues2({ ...formValues2, email: "", name: "", title: "" });
          setContactAction(false);
        } else {
          await axios
            .post(`${process.env.REACT_APP_URL}companies/registerContact`, body)
            .then((res) => {
              setContactTableData(res.data.data);
              setTableData([res.data.data]);
              const newArray = [...tableData, res.data.data];
              setTableData(newArray);
              setFormValues2({
                ...formValues2,
                email: "",
                name: "",
                title: "",
              });

              // getTableData();
            });
        }
      }
    } catch (error) {
      console.log(error);
      // setError2(true);
      // setMessage2("An error occurred while registering the user.");
    }
  };
  // console.log(tableData)
  const classes = useStyles();

  const [error3, setError3] = React.useState(false);
  const [message3, setMessage3] = React.useState("");

  const handleIndustryChange = (event) => {
    const selectedIndustry = event.target.value;
    setFormValues3((prevValues) => ({
      ...prevValues,
      type: selectedIndustry,
    }));
  };

  const handleNotesChange = (event) => {
    const { name, value } = event.target;
    setFormValues3((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  // snack
  const [response, setResponse] = React.useState("");
  const [severity, setSeverity] = React.useState("");
  const [notifyOpen, setNotifyOpen] = React.useState(false);
  const handleClosePop = () => {
    setNotifyOpen(false);
  };
  const handleSubmit3 = async (e) => {
    e.preventDefault();
    // console.log(formValues3)

    const body = {
      ...formValues3,
      companyId: companyDetailsData._id,
    };
    try {
      // Check if any form field is empty
      if (
        Object.values(formValues3).some(
          (value) => typeof value === "string" && value.trim() === ""
        )
      ) {
        setError3(true);
        setMessage3("Please fill all the data fields.");
        return;
      }
      if (props.check == true) {
        console.log("check");
        //add api for update
        const body = {
          type: formValues3.type,
          notes: formValues3.notes,
          companyId: props.state._id,
        };
        const res = await axios.post(
          `${process.env.REACT_APP_URL}companies/updatelead`,
          body
        );
        handleNext();
      } else {
        await axios
          .post(`${process.env.REACT_APP_URL}companies/registerLead`, body)
          .then((res) => {
            setResponse("Detail saved Successfully!");
            setSeverity("success");
            setNotifyOpen(true);
            // console.log(res)
            handleNext();
          });
      }
    } catch (error) {
      console.log(error);
      setError3(true);
      setSeverity("danger");
      // setMessage3("An error occurred while registering the user.");
    }
    // console.log(body)
  };
  const goBack = () => {
    props.closeDialog(false);
  };
  console.log("+++++++++++++++++++++++", formValues.code);
  return (
    <Box sx={{ p: 8 }} className={classes.box}>
      <Stepper activeStep={activeStep} orientation="horizontal">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === 2 ? (
                  <Typography variant="caption">Last step</Typography>
                ) : null
              }
            >
              {step.label}
              <Typography className={classes.sub} sx={{ mt: 1 }}>
                {" "}
                {step.sub}{" "}
              </Typography>
            </StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      <Box>
        {activeStep == 0 ? (
          <div style={{ marginTop: "30px" }}>
            <form onSubmit={handleSubmit}>
              <div style={{}}>
                <Grid container spacing={1}>
                  <Grid item xs={6} md={4} lg={4}>
                    <Typography
                      className={classes.label}
                      sx={{ fontSize: "14px", fontWeight: 600, mb: 1 }}
                    >
                      Company Name
                    </Typography>
                    <TextField
                      required
                      fullWidth
                      className={classes.input}
                      name="companyName"
                      value={formValues.companyName}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={4}>
                    <Box sx={{}}>
                      <Typography
                        className={classes.label}
                        sx={{ fontSize: "14px", fontWeight: 600, mb: 1 }}
                      >
                        {" "}
                        Category
                      </Typography>
                      <FormControl fullWidth>
                        <Select
                          required
                          className={classes.input}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          // label="Industry"
                          name="category"
                          value={formValues.category}
                          onChange={handleChange}
                        >
                          {data.map((item, ind) => (
                            <MenuItem key={ind} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={4} lg={4}>
                    <Box>
                      <Typography
                        className={classes.label}
                        sx={{ fontSize: "14px", fontWeight: 600, mb: 1 }}
                      >
                        {" "}
                        Phone Number
                      </Typography>
                      <Box display={"flex"}>
                        {/* <FormControl
                          className={classes.input}
                          sx={{ marginRight: "10px", width: "120px" }}
                        >
                          <Select
                            id="demo-simple-select"
                            value={formValues.code}
                            name="code"
                            onChange={handleChange}
                          >
                            {categoryData.map((categoryItem) => (
                              <MenuItem
                                key={categoryItem.code}
                                value={categoryItem.dial_code}
                              >
                                {categoryItem.dial_code}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl> */}
                        <TextField
                          required
                          fullWidth
                          className={classes.input}
                          name="contact"
                          value={formValues.contact}
                          onChange={handleChange}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={4} lg={6}>
                    <Box sx={{ mt: 3 }}>
                      <Typography
                        className={classes.label}
                        sx={{ fontSize: "14px", fontWeight: 600, mb: 1 }}
                      >
                        {" "}
                        Website Link
                      </Typography>
                      <TextField
                        fullWidth
                        className={classes.input}
                        name="websiteLink"
                        value={formValues.websiteLink}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={4} lg={6}>
                    <Box sx={{ mt: 3 }}>
                      <Typography
                        className={classes.label}
                        sx={{ fontSize: "14px", fontWeight: 600, mb: 1 }}
                      >
                        {" "}
                        Instagram Link
                      </Typography>
                      <TextField
                        fullWidth
                        className={classes.input}
                        name="instagramLink"
                        value={formValues.instagramLink}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>
                </Grid>

                {error && <Alert severity="error">{message}</Alert>}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    className={classes.backbtn}
                    onClick={goBack}
                  >
                    Back
                  </Button>
                  <Button
                    className={classes.btn}
                    variant="contained"
                    type="submit"
                  >
                    {" "}
                    Save & Proceed{" "}
                  </Button>
                </Box>
              </div>
            </form>
          </div>
        ) : activeStep == 1 ? (
          <div>
            <form onSubmit={handleSubmit2}>
              <Grid container spacing={1} sx={{ pt: 5 }}>
                <Grid item xs={6} md={4} lg={4}>
                  <Box>
                    <Typography
                      className={classes.label}
                      sx={{ fontSize: "14px", fontWeight: 600, mb: 1 }}
                    >
                      {" "}
                      Contact Name
                    </Typography>
                    <TextField
                      required
                      fullWidth
                      className={classes.input}
                      name="name"
                      value={formValues2.name}
                      onChange={handleChange2}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} md={4} lg={4}>
                  <Box>
                    <Typography
                      className={classes.label}
                      sx={{ fontSize: "14px", fontWeight: 600, mb: 1 }}
                    >
                      {" "}
                      Contact Email
                    </Typography>
                    <TextField
                      required
                      fullWidth
                      className={classes.input}
                      name="email"
                      type="email"
                      value={formValues2.email}
                      onChange={handleChange2}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} md={4} lg={4}>
                  <Box>
                    <Typography
                      className={classes.label}
                      sx={{ fontSize: "14px", fontWeight: 600, mb: 1 }}
                    >
                      {" "}
                      Contact Title
                    </Typography>
                    <TextField
                      required
                      fullWidth
                      className={classes.input}
                      name="title"
                      value={formValues2.title}
                      onChange={handleChange2}
                    />
                  </Box>
                </Grid>
                {error2 && <Alert severity="error">{message2}</Alert>}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                    paddingBottom: "10px",
                  }}
                >
                  <Button
                    // className={classes.btn}

                    className={classes.btnSave}
                    variant="contained"
                    type="submit"
                  >
                    {" "}
                    {contactAction ? "Update" : "Save"}
                  </Button>
                </Box>
              </Grid>
            </form>
            <Card className={classes.card}>
              <StyledTableContainer>
                <Table>
                  <TableHeader sx={{ background: Gradients.cApU }}>
                    <TableRow
                      sx={{
                       
                  
                      }}
                    >
                      <TableCell
                        sx={{
                          fontSize: "14px",
                          fontWeight: 600,
                          color: "#fff",
                          letterSpacing: 1,
                        }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "14px",
                          fontWeight: 600,
                          color: "#fff",
                          letterSpacing: 1,
                        }}
                      >
                        Title
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "14px",
                          fontWeight: 600,
                          color: "#fff",
                          letterSpacing: 1,
                        }}
                      >
                        Email
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "14px",
                          fontWeight: 600,
                          color: "#fff",
                          letterSpacing: 1,
                        }}
                      >
                        Action
                      </TableCell>
                      <TableCell> </TableCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody sx={{ color: "#fff" }}>
                    {tableData &&
                      tableData.map((val, index) => {
                        return (
                          <TableRow
                            key={index}
                            className={
                              index % 2 === 0 ? classes.evenRow : classes.oddRow
                            }
                            sx={{
                              cursor: "pointer",
                              borderRadius: "20px",
                              color: "#fff",
                            }}
                          >
                            {val ? (
                              <>
                                <TableCell
                                  className={classes.cell}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Avatar
                                    src="/assets/images/3.png"
                                    sx={{ mr: 2 }}
                                  />
                                  {val.name}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  {val.title}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  {val.email}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  <Box sx={{ display: "flex" }}>
                                    <Avatar
                                      sx={{
                                        color: "#000",
                                        background: "#E3A700",
                                        boxShadow: "5px 5px 10px #000",
                                        border: "1px solid #fff",
                                      }}
                                      onClick={() => postData(val)}
                                    >
                                      <CreateIcon sx={{ color: "#000" }} />
                                    </Avatar>

                                    <Avatar
                                      sizes="sm"
                                      sx={{
                                        color: "#E3A700",
                                        background: "#404040",
                                        marginLeft: "10px",
                                        boxShadow: "5px 5px 10px #000 ",
                                        border: "1px solid #fff",
                                      }}
                                      onClick={() => handleDelete(val._id)}
                                    >
                                      <DeleteForeverIcon fontSize="sm" />
                                    </Avatar>
                                  </Box>
                                </TableCell>
                                <TableCell className={classes.cell}></TableCell>
                              </>
                            ) : null}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </StyledTableContainer>
            </Card>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                alignItems: "center",
              }}
            >
              {/* <Button
                variant="outlined"
                className={classes.backbtn}
                onClick={goBack}
              >
                close
              </Button> */}
              <Button
                className={classes.btn}
                variant="contained"
                onClick={handleNext}
              >
                {" "}
                Save & Proceed{" "}
              </Button>
            </Box>
          </div>
        ) : activeStep == 2 ? (
          <div>
            <form onSubmit={handleSubmit3}>
              <Box>
                <Typography
                  className={classes.label}
                  sx={{ fontWeight: 600, color: "#202224", mb: 2 }}
                >
                  Industry
                </Typography>
                <FormControl fullWidth>
                  <Select
                    className={classes.input}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formValues3.type}
                    label="Industry"
                    onChange={handleIndustryChange}
                  >
                    {data.map((item, ind) => (
                      <MenuItem key={ind} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ mt: 3 }}>
                <Typography
                  className={classes.label}
                  sx={{ fontSize: "14px", fontWeight: 600, mb: 1 }}
                >
                  {" "}
                  Notes
                </Typography>
                <TextField
                  required
                  fullWidth
                  placeholder="Start Writing from here"
                  name="notes"
                  value={formValues3.notes}
                  onChange={handleNotesChange}
                  multiline
                  rows={10}
                  className={classes.input}
                />
              </Box>
              {error3 && <Alert severity="error">{message3}</Alert>}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                {/* <Button
                  variant="outlined"
                  className={classes.backbtn}
                  onClick={goBack}
                >
                  Back
                </Button> */}
                <Button
                  className={classes.btn}
                  variant="contained"
                  // onClick={handleSubmit3}
                  type="submit"
                >
                  {" "}
                  Save & Proceed{" "}
                </Button>
              </Box>
            </form>

            <Snack
              open={notifyOpen}
              message={response}
              severity={severity}
              handleClose={handleClosePop}
            />
          </div>
        ) : activeStep == 3 ? (
          <div>
            <Grid
              container
              rowSpacing={1}
              sx={{ marginTop: "10px" }}
              columnSpacing={{ xs: 1, md: 2 }}
            >
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Card className={classes.innercard}>
                  <CardContent style={{ textAlign: "center" }}>
                    <Typography className={classes.cardtextHeader}>
                      Company Name
                    </Typography>
                    <Typography className={classes.cardtext}>
                      {companyDetailsData.companyName
                        ? companyDetailsData.companyName
                        : "-"}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Card className={classes.innercard}>
                  <CardContent style={{ textAlign: "center" }}>
                    <Typography className={classes.cardtextHeader}>
                      Phone Number
                    </Typography>
                    <Typography className={classes.cardtext}>
                      {companyDetailsData.contact
                        ? companyDetailsData.contact
                        : "-"}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Card className={classes.innercard}>
                  <CardContent style={{ textAlign: "center" }}>
                    <Typography className={classes.cardtextHeader}>
                      Website Link
                    </Typography>
                    <Typography className={classes.cardtext}>
                      {companyDetailsData.websiteLink
                        ? companyDetailsData.websiteLink
                        : "-"}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={12} md={12} lg={12}>
                <Card className={classes.innercard} sx={{ mt: 2, mb: 2 }}>
                  <CardContent
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography className={classes.typo1}>
                      {" "}
                      Social Link
                    </Typography>
                    <Box sx={{ display: "flex" }}>
                      <FacebookIcon />

                      <InstagramIcon sx={{ mx: 2 }} />

                      <TwitterIcon />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Card className={classes.card}>
              <StyledTableContainer >
                <Table>
                  <TableHeader sx={{ background: Gradients.cApU }}>
                    <TableRow sx={{ borderTopLeftRadius: "10px" }}>
                      <TableCell
                        sx={{
                          fontSize: "14px",
                          fontWeight: 600,
                          color: "#fff",
                          letterSpacing: 1,
                        }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "14px",
                          fontWeight: 600,
                          color: "#fff",
                          letterSpacing: 1,
                        }}
                      >
                        Title
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "14px",
                          fontWeight: 600,
                          color: "#fff",
                          letterSpacing: 1,
                        }}
                      >
                        Email
                      </TableCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody sx={{ color: "#fff" }}>
                    {tableData &&
                      tableData.map((val, index) => {
                        return (
                          <TableRow
                            key={index}
                            className={
                              index % 2 === 0 ? classes.evenRow : classes.oddRow
                            }
                            sx={{
                              cursor: "pointer",
                              borderRadius: "20px",
                              color: "#fff",
                            }}
                          >
                            {val ? (
                              <>
                                <TableCell
                                  className={classes.cell}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Avatar
                                    src="/assets/images/3.png"
                                    sx={{ mr: 2 }}
                                  />
                                  {val?.name}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  {val.title}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  {val.email}
                                </TableCell>
                              </>
                            ) : null}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </StyledTableContainer>
            </Card>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                alignItems: "center",
              }}
            >
              {/* <Button
                variant="outlined"
                className={classes.backbtn}
                onClick={goBack}
              >
                Back
              </Button> */}
              <Button
                className={classes.btn}
                variant="contained"
                onClick={goBack}
                // component={Link}
                // to="/dashboard/contacts"
              >
                Finish
              </Button>
            </Box>
          </div>
        ) : null}
      </Box>
    </Box>
  );
};

export default CreateContact;
