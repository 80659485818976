import {
  Toolbar,
  Typography,
  Box,
  Avatar,
  ListItemAvatar,
  ListItemButton,
  TextField,
  Button,
  InputAdornment,
  Badge,
  Stack,
  Tooltip
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import Page from "../../components/page/page";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import gradients from "../../utils/Gradients";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import GroupsIcon from "@mui/icons-material/Groups";
import QrCodeIcon from "@mui/icons-material/QrCode";
import BusinessIcon from "@mui/icons-material/Business";
import ReceiptIcon from "@mui/icons-material/Receipt";
import clsx from "clsx";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Anchor, NotificationAddOutlined } from "@mui/icons-material";
import { Outlet, useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { logout } from "../../store/actions/authActions";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import DescriptionIcon from "@mui/icons-material/Description";
import HandshakeIcon from "@mui/icons-material/Handshake";
import DensitySmallIcon from "@mui/icons-material/DensitySmall";
import DashboardIcon from "@mui/icons-material/Dashboard";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";

import { VscLibrary } from "react-icons/vsc";
import axios from "axios";
// import { FaHandshakeSimple } from 'react-icon/fa'

const drawerWidth = 220;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});
const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer - 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    // marginLeft: drawerWidth,
    width: `calc(100% - 275px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!open && {
    // marginLeft: drawerWidth,
    width: `calc(100% - 158px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
const listData = [
  { id: 1, title: "Dashboard", icon: <DashboardIcon />, to: "/dashboard/dash" },
  {
    id: 2,
    title: "Retainers",
    icon: <TrackChangesIcon />,
    to: "/dashboard/_retainer",
  },

  {
    id: 4,
    title: "Contacts",
    icon: <PermContactCalendarIcon />,
    to: "/dashboard/contacts",
  },
  { id: 5, title: "CRM", icon: <GroupsIcon />, to: "/dashboard/crm" },
  { id: 6, title: "Products", icon: <QrCodeIcon />, to: "/dashboard/products" },
  {
    id: 7,
    title: "Companies",
    icon: <BusinessIcon />,
    to: "/dashboard/companies",
  },
  {
    id: 8,
    title: "Invoices",
    icon: <ReceiptIcon />,
    to: "/dashboard/invoices",
  },

  {
    id: 9,
    title: "Contracts",
    icon: <DescriptionIcon />,
    to: "/dashboard/contracts",
  },
  

  {
    id: 10,
    title: "Vendors",
    icon: <HandshakeIcon />,
    to: "/dashboard/vendors",
  },
  { id: 11, title: "Library", icon: <VscLibrary />, to: "/dashboard/library" },
  {
    id: 12,
    title: "Chat GPT",
    icon: <img src="/assets/images/gpt.svg" alt="My Icon" />,
    to: "/dashboard/gpt",
  },
  {
    id: 13,
    title: "Create Invoice",
    icon: <DescriptionIcon />,
    to: "/dashboard/Create-Invoice2",
  },
  { id: 3, title: "Users", icon: <PeopleAltIcon />, to: "/dashboard/users" },
  // {id:13 ,title: 'Sheets', icon: <DensitySmallIcon/>, to:'/dashboard/sheets'},
  // '/dashboard/retainer'
];
const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(3)
  },
  avatar: { 
  
    filter: "drop-shadow(0px 0px 14px rgba(0, 0, 0, 0.15))",
  },
  selected: {
    background: "linear-gradient(#D59D03, #31343B);",
    backdropFilter: "blur(27px)",
    borderRadius: "10px",
    border: "0.5px solid #EAC04D",
    // marginLeft:"10px",
    // marginRight:'5px'
  },
  hover: {
    background: "linear-gradient(#D59D03, #31343B);",
    borderRadius: "10px",
    border: "0.5px solid #EAC04D",
  },
  btn: {
    color: "#FFF",
    "&:hover": {
      background: "linear-gradient(#D59D03, #31343B);",
      color: "#D59D03",
      borderRadius: "10px",
      border: "0.5px solid #EAC04D",
    },
  },
  icon: {
    color: "#fff",
  },
  input: {
    "&::placeholder": {
      color: "#fff",
    },
  },
}));
const Dashboard = () => {
  const [options, setOptions] = useState([""]);

  const { state } = useLocation();
  console.log("Company Name :", state);
  const user = useSelector((state) => state.auth.user);
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  useEffect(() => {
    const body = {
      organizationId: user.organization._id,
    };

    axios
      .post(`${process.env.REACT_APP_URL}api/invoices/notifications`, body)
      .then((response) => {
        console.log(response, "response");
        setOptions(response.data);
        // setGraph(response.data);
      });
  }, []);
  const ClearButton = (
    <MenuItem>
      <Button
        onClick={() => {
          /* Handle clear logic here */
        }}
      >
        Clear Notifications
      </Button>
    </MenuItem>
  );
  const handleDrawerClose = () => {
    setOpen(!open);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    navigate("/dashboard/profile");
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogOut = () => {
    setAnchorEl(null);
    dispatch(logout());
    navigate("/login");
  };
  const titles = {
    "/dashboard/profile": "Profile",
    "/dashboard/users": "Users",
    "/dashboard/new-user": "Create User",
    "/dashboard/contacts": "Contacts",
    "/dashboard/contact-detail": "Company contact",
    "/dashboard/update-user/:id": "Update Details",
    "/dashboard/update-contact": "Update Contact",
    "/dashboard/crm": "CRM",
    "/dashboard/scheduling": `${state?.companyName}`,
    "/dashboard/company-crm": "Company CRM",
    "/dashboard/products": "Products",
    "/dashboard/new-contact": "Create Contact",
    "/dashboard/products": "Products",
    "/dashboard/new-product": "Create Product",
    "/dashboard/companies": "Companies",
    "/dashboard/update-company": "Update Company",
    "/dashboard/update-product": "Update Product",

    "/dashboard/invoices": "Invoices",
    "/dashboard/new-invoices": "Create invoices",
    "/dashboard/contracts": "Contracts",
    "/dashboard/new-contract": "Contracts",
    "/dashboard/update-contract": "Update Contract",
    "/dashboard/Create-Invoice2": "Create Invoice",
    
    "/dashboard/vendors": "Vendors",
    "/dashboard/new-vendor": "Vendors",
    "/dashboard/update-vendor": "Update Vendor",
    "/dashboard/library": "Library",
    "/dashboard/library/videos": "Library",
    "/dashboard/library/images": "Library",
    "/dashboard/library/files": "Library",
    "/dashboard/upload-file": "Upload Files",

    "/dashboard/gpt": "Chat GPT",

    "/dashboard/retainer": "Retainer",
    "/dashboard/_retainer": "Retainer",
    "/dashboard/new-sheet": "Create Retainer",
    "/dashboard/invoices": "Invoices",
    "/dashboard/dash": "Dashboard",
  };

  const itemsList = Array.from(
    { length: 15 },
    (_, index) => `Item ${index + 1}`
  );

  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const handleMenuClick = () => {
    console.log("hittt button");
    setIsMenuOpen(!isMenuOpen);
  };
  const [title, setTitle] = React.useState(titles["/dashboard/users"]);
  React.useEffect(() => {
    setTitle(titles[location.pathname]);
  }, [location.pathname]);
  const [hoverIndex, setHoverIndex] = React.useState(null);

  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [open1, setOpen1] = React.useState(false);

  const handleClickListItem = (event) => {
    setAnchorEl1(event.currentTarget);
    setOpen1(true);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
    setOpen1(false);
  };

  // const handleMenuItemClick1 = (event, index) => {

  //   setSelectedIndex1(index);
  //   setAnchorEl1(null);
  //   console.log("hit", )
  // };

  return (
    <Page title="Users">
      <Box sx={{ display: "flex", pt: 5, pl: open ? 0 : 4.5 } }>
        <CssBaseline />

        <AppBar
          // position="absolute"
          open={open}
          sx={{
            background: gradients.cAp,
            borderRadius: "20px",
            mt: 2,
            height: "90px",
            mr: 4,
            // backgroundcColor: "white",
            color: "black",
            border: "1px solid #187ACE ",
            boxShadow: "0px 0px 15px #0080f0 inset",
            borderRadius: "16px",
          }}
        >
          <Toolbar
            sx={{ mt: 1.5, display: "flex", justifyContent: "space-between" }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                noWrap
                component="div"
                sx={{ fontWeight: "bold", fontSize: "32px" }}
              >
                {title ? title : "Users"}
              </Typography>

              {location.pathname == "/dashboard/users" ||
              // || location.pathname == '/dashboard/new-user'
              location.pathname == "/dashboard/contacts" ||
              location.pathname == "/dashboard/products" ||
              // || location.pathname == '/dashboard/companies'
              location.pathname == "/dashboard/vendors" ||
              location.pathname == "/dashboard/contracts" ||
              location.pathname == "/dashboard/library" ||
              location.pathname == "/dashboard/invoices" ||
              location.pathname == "/dashboard/_retainer" ? (
                <></>
              ) : // <Button
              //   startIcon={<AddCircleIcon color="#C3C3C3" sx={{ ml: 1 }} />}
              //   sx={{ padding: "10px 20px", color: "black" }}
              //   component={Link}
              //   to={
              //     location.pathname == "/dashboard/users"
              //       ? "/dashboard/new-user"
              //       : location.pathname == "/dashboard/contacts"
              //       ? "/dashboard/new-contact"
              //       : location.pathname == "/dashboard/products"
              //       ? "/dashboard/new-product"
              //       : //  location.pathname == '/dashboard/companies' ? '/dashboard/new-company' :
              //       location.pathname == "/dashboard/vendors"
              //       ? "/dashboard/new-vendor"
              //       : location.pathname == "/dashboard/contracts"
              //       ? "/dashboard/new-contract"
              //       : location.pathname == "/dashboard/library"
              //       ? "/dashboard/upload-file"
              //       : location.pathname == "/dashboard/_retainer"
              //       ? "/dashboard/new-retainer"
              //       : location.pathname == "/dashboard/invoices"
              //       ? "/dashboard/new-invoices"
              //       : null
              //   }
              // >
              //   {location.pathname == "/dashboard/users"
              //     ? "Create new user"
              //     : location.pathname == "/dashboard/contacts"
              //     ? "Create new contact"
              //     : location.pathname == "/dashboard/products"
              //     ? "Create Product"
              //     : //  location.pathname == '/dashboard/companies' ? "Create new Company" :
              //     location.pathname == "/dashboard/vendors"
              //     ? "Create new Vendor"
              //     : location.pathname == "/dashboard/contracts"
              //     ? "Create new contract"
              //     : location.pathname == "/dashboard/library"
              //     ? "Upload Files"
              //     : location.pathname == "/dashboard/_retainer"
              //     ? "Add New"
              //     : location.pathname == "/dashboard/invoices"
              //     ? "Add Invoice"
              //     : null}
              // </Button>
              null}
            </Box>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton onClick={handleClickListItem}>
                <Avatar sx={{ bgcolor: "#1D212B" }}>
                  <Badge badgeContent={options.length} color="error"  sx={{color:"red"}} variant="dot" >
                    <NotificationsIcon sx={{ color: "#C3C3C3" }} />
                  </Badge>
                </Avatar>
              </IconButton>

              <Menu
                id="lock-menu"
                anchorEl={anchorEl1}
                open={open1}
                onClose={handleClose1}
                MenuListProps={{
                  "aria-labelledby": "lock-button",
                  role: "listbox",
                  sx: { maxHeight: "calc(100vh - 48px)" }, // Limit menu height to viewport height - button height
                }}
              >
                {options.length < 1
                  ? " "
                  : options.map((option, index) => (
                      <MenuItem key={index}>{option.message}</MenuItem>
                    ))}
                {/* {options.length > 1 && ClearButton} */}
              </Menu>

              <Avatar
                sx={{ ml: 4, mr: 2, height: "60px", width: "60px", mb: -1 }}
                src="/assets/images/2.png"
              />
              <Stack>
                <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>
                  {user.name}
                </Typography>
              </Stack>
              <Box sx={{ display: "flex", alignItems: "center", ml: 2.5 }}>
                <Avatar
                  sx={{
                    height: "25px",
                    width: "25px",
                    cursor: "pointer",
                    bgcolor: "#1D212B",
                  }}
                  onClick={handleClick}
                >
                  <KeyboardArrowDownIcon sx={{ color: "#C3C3C3" }} />
                </Avatar>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          open={open}
          // bgCard
          PaperProps={{
            sx: {
              height:"97vh",
              background: gradients.Custom,
              // backgroundImage: 'url("/assets/images/bgCard.png")', 
              // backgroundSize: 'cover', // Adjust as needed
             
              ml: 2,
              mt: 2,
              border: "3px solid #187ACE ",
              boxShadow: "0px 0px 15px #0080f0 inset",
              borderRadius: "16px",
            },
          }}
        >
          <Box sx={{ p: 2, mt: "5px" }}>
            {open ? (
              <>
                <Box
                  style={{
                    // background:'#fff',

                    padding: "10px",
                    width: "130px",
                    height: "130px",
                    borderRadius: "200px",
                    border: "1px solid",
                    background: "#000",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src="/assets/images/logo1.png"
                    style={{ width: "70px" }}
                  />
                </Box>
                <Box
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      // marginLeft: "10px",
                      // textAlign: "center",
                      mt: 3,
                      ml: 1,
                      fontWeight: 700,
                      fontSize: "26px",
                      color: "#fff",
                      textShadow: "5px 5px 10px #000",
                    }}
                  >
                    KUDU
                  </Typography>
                  <IconButton sx={{ mt: 2 }} onClick={handleDrawerClose}>
                    {open ? (
                      <Box
                        style={{
                          ml: 2,
                          background: "#797A99",
                          border: "1px solid",
                          borderRadius: "100px",
                          width: "30px",
                          height: "30px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {" "}
                        <ArrowBackIosIcon
                          style={{ color: "#fff", fontSize: "16px" }}
                        />
                      </Box>
                    ) : (
                      ""
                    )}
                  </IconButton>
                </Box>
              </>
            ) : (
              <>
                <Box
                  style={{
                    // background:'#fff',
                    padding: "10px",
                    borderRadius: "10px",
                    margin: "-8px",
                  }}
                >
                  <img
                    src="/assets/images/logo1.png"
                    style={{ width: "30px" }}
                  />
                </Box>
              </>
            )}
          </Box>
          <IconButton onClick={handleDrawerClose}>
            {open ? (
              ""
            ) : (
              <Box
                style={{
                  ml: 2,
                  background: "#797A99",
                  border: "1px solid",
                  borderRadius: "100px",
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ArrowForwardIosIcon
                  style={{ color: "#fff", fontSize: "16px" }}
                />
              </Box>
            )}
          </IconButton>

          <List
            sx={{
              overflowY: "auto",
              "::-webkit-scrollbar": { display: "none" },
            }}
          >
            {listData.map((val, index) => (
              <ListItem
                disablePadding
                sx={{ mb: 0.5 }}
                className={clsx(classes.root, {
                  [classes.selected]: selectedIndex === val.id,
                  [classes.hover]:
                    selectedIndex !== val.id && hoverIndex === val.id,
                })}
                onMouseEnter={() => setHoverIndex(val.id)}
                onMouseLeave={() => setHoverIndex(null)}
              >
                <ListItemButton
                  key={val}
                  className={clsx(classes.root, {
                    [classes.selected]: selectedIndex === val.id,
                  })}
                  selected={selectedIndex === val.id}
                  onClick={(event) => handleListItemClick(event, val.id)}
                  component={Link}
                  to={val.to}
                >
                  <ListItemAvatar>
                  <Tooltip title={!open?val.title:''} placement="right">
                    <Avatar
                      className={classes.avatar}
                      sx={{
                        marginLeft:'-7.5px !important',
                        background:
                          hoverIndex === val.id ? val.id === 12? '': '#fff' : gradients.Custom,
                        color:
                          hoverIndex === val.id ? "#D59D03" : gradients.Custom,
                      }}
                    >
                      {val.icon}
                    </Avatar>
                    </Tooltip>
                  </ListItemAvatar>
                  <ListItemText
                    primary={val.title}
                    sx={{
                      color:
                        selectedIndex === val.id
                          ? "#fff"
                          : hoverIndex === val.id
                          ? "#fff"
                          : "#fff",
                      fontSize: "16px",
                      fontWeight:"bold",
                      textShadow:
                      "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
          <DrawerHeader />
          <Box sx={{ pr: 2, ml: 1, height:'93%' }}>
            <Outlet />
          </Box>
        </Box>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleLogOut}>Logout</MenuItem>
      </Menu>
    </Page>
  );
};

export default Dashboard;
