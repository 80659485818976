import {
  Box, Divider, Table,
  Typography, Avatar, Stack, Grid, styled, TableBody, TableCell, TableHead,
  TableRow, TableContainer, Button
} from '@mui/material'
import React from 'react'
import { makeStyles } from '@mui/styles'
import Gradients from '../../../utils/Gradients'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  typo1: {
    color: '#9A9A9A',
    fontWeight: 600,
    fontSize: '14px'
  },
  typo2: {
    color: '#238FC1',
    fontWeight: 700,
    fontSize: '12px'
  },
  head: {
    fontSize: '14px',
    fontWeight: 800,
    color: '#fff',
    fontStyle: 'normal',
    lineHeight: 'normal'
  },
  cell: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: 'normal'
  },
  box: {
    borderRadius: '12px',
    border: '0.3px solid rgba(32,138,187,0.25)',
    background: '#E6F6FE',
    boxShadow: ' 0px 14px 25px 0px rgba(141, 112, 112, 0.12)',
  },
  btn: {
    marginTop: '50px',
    borderRadius: '12px',
    background: 'linear-gradient(180deg, #3CAFE4 0%, #046593 100%)',
    width: '281.211px',
    height: '56px',
  },
  avatar: {
    cursor: 'pointer', // Change cursor to pointer for the avatar
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
}))

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  borderRadius: '20px',
  border: '0.3px solid rgba(32,138,187,0.25)',
  overflow: 'hidden',
}));


const Finish = ({ companyData, contactsData }) => {
  const classes = useStyles()
  const navigate = useNavigate()

  const handleFinish = () => {
    navigate('/dashboard/contacts')
  }
  return (
    <div>
      <Grid container spacing={5}>
        <Grid
          item
          xs={12}
          md={6}
          lg={4}
        >
          <Typography className={classes.typo1}> Company Name</Typography>
          <Typography className={classes.typo2}>{companyData?.companyName}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={4}
        >
          <Typography className={classes.typo1}> Phone Number</Typography>
          <Typography className={classes.typo2}>{companyData?.contact}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={4}
        >
          <Typography className={classes.typo1}> Website Link</Typography>
          <Typography className={classes.typo2}>{companyData?.websiteLink}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={4}
        >
          <Typography className={classes.typo1}> Social Link</Typography>
          <Typography className={classes.typo2}>{companyData?.instagramLink}</Typography>


        </Grid>

      </Grid>
      <Box sx={{ marginTop: '20px' }}>
        <StyledTableContainer>
          <Table >
            <TableHead>
              <TableRow sx={{ background: Gradients.cAp }}>
                <TableCell className={classes.head}> Name </TableCell>
                <TableCell className={classes.head}> Title </TableCell>
                <TableCell className={classes.head}> Email </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ background: '#fff' }}>
              {contactsData?.map((contact, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell className={classes.cell} sx={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar src="/assets/images/3.png" sx={{ mr: 2 }} />
                      {contact?.name}
                    </TableCell>
                    <TableCell className={classes.cell}>{contact?.title}</TableCell>
                    <TableCell className={classes.cell}>{contact?.email}</TableCell>
                  </TableRow>
                );
              })}

            </TableBody>
          </Table>
        </StyledTableContainer>
      </Box>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <Button className={classes.btn} variant='contained'
          onClick={handleFinish}
        > Finish </Button>
      </Box>
    </div>
  )
}

export default Finish
