import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  TableContainer,
  Card,
  Box,
  Pagination,
} from "@mui/material";
import Gradients from "../../utils/Gradients";
import CreateIcon from "@mui/icons-material/Create";
import React from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router";
import Searchbar from "../../components/SearchBar/Searchbar";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useState } from "react";
import axios from "axios";
import CreateCompany from "./components/CreateCompany";
import moment from "moment";
import DateRangeIcon from '@mui/icons-material/DateRange';
const TableHeader = styled(TableHead)(({ theme }) => ({
  "& th": {
    marginButtom: "10px",
    borderBottom: "0px",
    border: "none",
    "&:first-child": {
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
    },
    "&:last-child": {
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "10px",
    },
  },
}));
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  // borderRadius: "10px",
  overflow: "hidden",
}));

const useStyles = makeStyles((theme) => ({
  evenRow: {
    color: "#ffffff",
    backgroundColor: "#787676",
    "&:hover": {
      transform: "scale(1.01)", 
      boxShadow: " 0 0 10px rgba(0, 0, 0, 0.5)", // Add shadow on hover
      backgroundColor: "#6B6B6B",

    },
  },
  oddRow: {
    // backgroundColor: "#6B6B6B",
    backgroundColor: "#787676",
    borderRadius: "20px",
    color: "#ffffff",
    // Odd row color
    "&:hover": {
      transform: "scale(1.01)", 
      boxShadow: " 0 0 10px rgba(0, 0, 0, 0.5)", // Add shadow on hover
      backgroundColor: "#6B6B6B",

    },
  },
  tableCell: {
    color: "#ffffff",
  },
  row: {
    color: "#ffffff",
    "&:hover $cell": {
      color: "#ffffff",
    },
  },
  cell: {
    fontSize: "1.2rem",
    fontWeight: 500,
    letterSpacing: 1,
    color: "#FFF",
    border: "none",
  },
  search: {
    border: "1px solid #fff",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
    borderRadius: "10px",
    "& .MuiInputBase-root": {
      backgroundColor: "#C9975C",
      borderRadius: "10px",
      color: "#fff",
    },
    "& fieldset": {
      border: "none",
    },
  },
  card: {
    padding: "25px",
    border: "2px solid #fff",
    background: "#787676",
    borderRadius: "16px",
    color: "#fff",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
  },
  btn: {
    borderRadius: "10px",
    marginTop: "10px",
    marginRight: "20px",
    color: "#000",
    fontWeight: "bold",
    fontVariant: "small-caps",
    background: "#E3A700",
    border: "1px solid #fff",
    fontSize: "14px",
    boxShadow: "5px 5px 10px #000",
    fontVariant: "small-caps",
    marginBottom: "10px",

    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#E3A700",
      cursor: "pointer",
    },
  },
  headCell: {
    textShadow: "5px 5px 10px #fff",
    fontSize: "1rem",
    fontWeight: 700,
    color: "#FAFF00",
    letterSpacing: 1,
  },
}));
const Companies = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [companiesData, setCompaniesData] = React.useState([]);
  const [filteredCompanies, setFilteredCompanies] = React.useState([]);
  const [singleData, setSingleData] = useState("");
  const [totalPages, setTotalPages] = React.useState(1);
  const itemsPerPage = 5;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const data = useSelector((state) => state);
  const postData = () => {
    // setOpenDialog(true);
  };

  const user = useSelector((state) => state.auth.user);

  const handleDialogClose = (data) => {
    console.log(data);
    setOpenDialog(false);
    getCompaniesData()
    console.log("status", openDialog);
  };
  const getCompaniesData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}companies/read/all/${user.organization._id}`
      );
      const totalComp = res.data.data.length;
      setTotalPages(Math.ceil(totalComp / itemsPerPage));

      // Update both companiesData and filteredCompanies
      setCompaniesData(res.data.data);
      setFilteredCompanies(res.data.data.slice(startIndex, endIndex));
    } catch (error) {
      console.error("Error fetching users data:", error);
    }
  };
  React.useEffect(() => {
    getCompaniesData();
  }, [currentPage]);
  const navigate = useNavigate();
  // const postData = () => {
  //   console.log("Updation");
  //   navigate(`/dashboard/update-company`);
  // };

  const handleChange = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    const filteredData = companiesData.filter((data) => {
      // Check if companyName is defined before accessing its property
      if (
        data.companyName &&
        data.companyName.toLowerCase().includes(searchTerm)
      ) {
        return true;
      }
      return false;
    });
    setFilteredCompanies(filteredData);
  };
  const handleSubmit = (data) => {
    setSingleData(data);
    setOpenDialog(true);
  };
  const classes = useStyles();
  return (
    <div style={{ height: "95vh" }}>
      <Card
        className={classes.card}
        sx={{ height: "600px", position: "relative" }}
      >
        <Searchbar search={classes.search} onChange={handleChange} />
        <StyledTableContainer>
          <Table>
            <TableHeader sx={{ background: Gradients.cApU }}>
              <TableRow sx={{ borderTopLeftRadius: "10px" }}>
                <TableCell className={classes.headCell}>Company Name</TableCell>
                <TableCell className={classes.headCell}>Category</TableCell>
                <TableCell className={classes.headCell}>
                  Last Contacted
                </TableCell>
                <TableCell className={classes.cell}></TableCell>
              </TableRow>
            </TableHeader>
            <TableBody sx={{}}>
              {filteredCompanies.map((data, index) => (
                <TableRow
                className={index % 2 === 0 ? classes.evenRow : classes.oddRow}
                  sx={{ cursor: "pointer", textDecoration: "none" }}
                  onClick={() => postData()}
                >
                  <TableCell
                    sx={{ display: "flex", alignItems: "center" }}
                    className={classes.cell}
                  >
                    <Avatar src="/assets/images/2.png" />
                    <Typography sx={{ fontSize: "1.2rem" }}>
                      {data.companyName}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {data.industry}{" "}
                  </TableCell>
                  <TableCell className={classes.cell}>
               
                  <DateRangeIcon sx={{ margin: "-7px 5px" }} />
                    {data.lastContacted
                      ? moment(data.lastContacted).format("MM-DD-YYYY")
                      : moment(data.initialContactDate).format("MM-DD-YYYY")}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {" "}
                    <Avatar
                      sx={{
                        color: "#000",
                        background: "#E3A700",
                        boxShadow: "5px 5px 10px #000",
                        border: "1px solid #fff",
                      }}
                      onClick={() => handleSubmit(data)}
                    >
                      <CreateIcon sx={{ color: "#000" }} />
                    </Avatar>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
        <Dialog
          fullWidth
          maxWidth="lg"
          PaperProps={{
            style: {
              background: " linear-gradient(#4F3B03, #E3A700,#503D06)",
              border: "3px solid #fff",
              borderRadius: "24px",
            },
          }}
          open={openDialog}
          onClose={handleDialogClose}
        >
          <DialogTitle
            sx={{
              color: "#FAFF00",
              textShadow:
                "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
            }}
          >
            Update Company
          </DialogTitle>
          <DialogContent>
            <CreateCompany state={singleData} closeDialog={handleDialogClose} />
          </DialogContent>
        </Dialog>
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Pagination
            count={totalPages}
            // count={10}
            page={currentPage}
            onChange={(event, page) => setCurrentPage(page)}
            color="primary"
            sx={{
              position: "absolute",
              bottom: 20,
              "& .MuiPaginationItem-root": {
                background: "#A4A4A4 !important",
                border: "1px solid #fff !important",
                boxShadow: "5px 5px 10px #000 !important",
                color: "#000",
                "&:hover": {
                  color: "#000",
                  background: "#E1A800 !important ",
                },
                "&.Mui-selected": {
                  background: "#E1A800 !important",
                },
              },

              "& .MuiPaginationItem-icon": {
                color: "#FFF !important", // Set color of the pagination icon to white
              },
            }}
          />
        </Box>
      </Card>
    </div>
  );
};

export default Companies;
