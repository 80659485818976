import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import {theme} from './Theme'
import Router from './routes';
import Library from './views/library/Library';
import CreateInvoice2 from './views/CreateInvoice/components/CreateInvoice2';


function App() {
  return (
    
    <div >
      
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
       
        
        {/* <Router />  */}
        <CreateInvoice2/>
        
        </StyledEngineProvider>
      </ThemeProvider>
      
    </div>
  );
  
}

export default App;
