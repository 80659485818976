import React, { useState,useRef,useEffect } from "react";
import SendIcon from "@mui/icons-material/Send";
import axios from "axios";
import "./gptstyle.css";
import {Card, TextField} from "@mui/material"
import { makeStyles } from "@mui/styles";
import {  InputAdornment, Button } from '@mui/material';
// import SendIcon from '@mui/icons-material/Send';
const useStyles = makeStyles((theme) => ({
  input: {
    border: "1px solid #fff",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
    borderRadius: "10px",
    "& .MuiInputBase-root": {
      backgroundColor: "#C9975C",
      borderRadius: "10px",
      color: "#fff",
    },
    "& fieldset": {
      border: "none",
    },
  },
  card: {
    padding: "25px",
    border: "2px solid #fff",
    background: "#9A9A9A",
    borderRadius: "16px",
    color: "#fff",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
  },
  button:{
    border:"1px solid #fff",
    background:"#0F2983",
    borderRadius:'8px'
  }
 
}));
const Gpt = () => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const chatContainerRef = useRef(null);


  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      sendInputMessage();
    }
  };

  const sendInputMessage = async () => {
    const userMessage = { role: 'user', content: inputMessage };
    setMessages(prevMessages => [...prevMessages, userMessage]);
  
    try {
      const body = { 
        prompt: inputMessage,
      };
      const response = await axios.post(`${process.env.REACT_APP_URL}chatgpt`, body);
  
      const aiMessage = { role: 'ai', content: response.data.response };
      setMessages(prevMessages => [...prevMessages, aiMessage]);
    } catch (error) {
      console.error('Error calling the API:', error.message);
    }
  
    setInputMessage('');
  };
  
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <div className="chats" ref={chatContainerRef}>
        {messages.map((message, index) => (
          <div key={index} className={`sender ${message.role}`} 
          style={{ textAlign: message.role === 'ai' ? 'left' : 'right' ,
          backgroundColor: message.role === 'user' ? 'transparent' : '#046593',
          color: message.role === 'user' ? 'grey' : 'white',
        }}
          >
            <div className="msgDate">{message.role === 'user' ? 'You' : <img src="/assets/images/gpt.svg" alt="AI" />}</div>
            <div className="msg">
              <p>{message.content}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="searchContainerMain">
      <TextField
        type="text"
        fullWidth
        className={classes.input}
        value={inputMessage}
        onChange={(e) => setInputMessage(e.target.value)}
        placeholder="Send Message"
        onKeyDown={handleKeyPress}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button className={classes.button} onClick={sendInputMessage} disabled={!inputMessage.trim()}>
                <SendIcon sx={{ color: 'white' }} />
              </Button>
            </InputAdornment>
          ),
        }}
      />
    </div>
    </Card>
  );
};

export default Gpt;