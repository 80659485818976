import {
  Alert,
  AppBar,
  Box,
  Button,
  Toolbar,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  TextField,
  Grid,
} from "@mui/material";
import categoryData from "../../utils/CountryCode";
import { makeStyles } from "@mui/styles";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { Link, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import axios from "axios";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Favorite from "@mui/icons-material/Favorite";

const useStyles = makeStyles((theme) => ({
  typo: {
    fontFamily: "Porter Sans Block",
    color: "#3CAFE4",
    fontSize: "96px",
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "normal",
    textAlign: "center",
  },
  card: {
    marginTop:'40px',
    width: "700px",
      border: '5px solid #fff',
      borderRadius: '16px',
      color: '#fff',
      boxShadow: '0 0 50px rgba(0, 0, 0, 0.5)',
      backgroundImage: 'url("/assets/images/bgLogin.png")',
      backgroundPosition: 'center', // Center the background image
      // display: 'flex',
      padding:"15px",
      justifyContent: 'center', 
      alignItems: 'center', // Center content vertically and horizontally
    },
  input: {
    border: "1px solid #fff",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
    borderRadius: "10px",
    backgroundColor: "#000",
    color: "#fff",
    "& .MuiInputBase-root": {
      backgroundColor: "#000",
      borderRadius: "10px",
      color: "#fff",
    },
    "& fieldset": {
      border: "none",
    },
    "& .MuiSvgIcon-root": {
      color: "#fff",
    },
  },
  label: {
    mb: 1,
    fontSize: "14px",
    fontWeight: 700,
    color: "#fff",
    textShadow: "5px 5px 10px #000",
    marginBlock: "10px",
  },
  btn: {
    height: "40px",
    // width:"230px",
    fontWeight: "700",
    borderRadius: "10px",
    marginTop: "10px",
    fontFamily: "Nunito Sans",
    marginRight: "20px",
    color: "#000",
    fontVariant: "small-caps",
    background: "#E3A700",
    border: "2px solid #fff",
    fontSize: "14px",
    boxShadow: "5px 5px 10px #000",
    fontVariant: "small-caps",
    marginBottom: "10px",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#E3A700",
      cursor: "pointer",
    },
  },
}));
const initialValues = {
  name: "",
  email: "",
  contact: "",
  // timeZone: '',
  organizationId: "",
  role: "user",
  password: "",
  phoneCode: null,
};

// const isValidContactNumber = (contactNumber) => {
//   return /^\d{11}$/.test(contactNumber);
// };

const Signup = () => {
  const [error, setError] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [confirmPassword, setConfrimPassword] = React.useState("");

  const navigate = useNavigate();
  const [formValues, setFormValues] = React.useState(initialValues);
  console.log("code", formValues);
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("code", name, value);
    setError(false);
    if (name == "contact") {
      if (!isNaN(value)) {
        setFormValues({ ...formValues, [name]: value });
      }
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };
  console.log("formValues", formValues);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(false);
    try {
      // Check if any form field is empty
      // if (Object.values(formValues).some((value) => value.trim() === "")) {
      //   setError(true);
      //   setMessage("Please fill all the  data fields.");
      //   return;
      // }

      if (formValues.password.length < 6) {
        setStatus("error");
        setError(true);
        setMessage("Password should be at least 6 characters long.");
        return;
      }

      // if (!isValidContactNumber(formValues.contact)) {
      //   setError(true);
      //   setMessage("Please enter a valid Contact number.");
      //   return;
      // }
      // console.log('formValues',formValues.contact)
      if (formValues.contact.length > 13) {
        setStatus("error");
        setError(true);
        setMessage("Please Enter Correct Number");
        return;
      }

      if (formValues.password !== confirmPassword) {
        setStatus("error");
        setError(true);

        setMessage("Passwords do not match.");
        return;
      }

      // Clear any existing error messages
      setError(false);
      setMessage("");

      // Call the API to register the user
      const res = await axios.post(
        `${process.env.REACT_APP_URL}users/register`,
        formValues
      );
      setFormValues(initialValues);
      setConfrimPassword("");
      // console.log("Alert", res.data);
      // alert("Alert", res.data.message);

      if (res.status == 200) {
        setMessage(res.data.message);
        setStatus("success");
        setError(true);
      } else {
        setMessage(res.data.message);
        setStatus("error");
        setError(true);
      }
    } catch (error) {
      console.log(error);
      setError(true);
      setStatus("error");
      setMessage("Internal Server Error. Please try again later.");
      setFormValues(initialValues);
      setConfrimPassword("");
    }
  };
  const [org, setOrg] = React.useState([""]);
  console.log("dataorg", org);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL}api/organizations/names`)
      .then((response) => {
        setOrg(response.data);
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  }, []);
  console.log("org", org, org.length);
  const handlePasswordVisibilityToggle = () => {
    setShowPassword(!showPassword);
  };

  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardContent>
        <Box
          sx={{ display: "flex", justifyContent: "center", marginLeft: "50px" }}
        >
          <Typography
            sx={{
              fontSize: "30.92px",
              marginBottom: "-40px",
              textAlign: "center",
              fontWeight: "600",
              marginRight: "-165px",
              fontFamily: "Nunito Sans",
              color: "#FAFF00",
              textShadow:
                "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
            }}
          >
            KUDU
          </Typography>
          <img
            style={{}}
            src="/assets/images/logo1.png"
            height="288.62px"
            width="201px"
          />
        </Box>

        <Box sx={{ textAlign: "center" }}>
          <Typography
            // component={Link}
            // to="/login"
            sx={{
              fontSize: "30.92px",
              fontWeight: "600",
              fontFamily: "Nunito Sans",
              color: "#FAFF00",
              textShadow:
                "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
            }}
          >
            Create an Account
          </Typography>
        </Box>

        {/* <TextField
                  fullWidth
                  className={classes.input}
                  name="Phone"
                  placeholder="Type here..."
                  value={phone}
                  onChange={handlePhoneChange}
                /> */}

        <form onSubmit={handleSubmit}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 2 }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography className={classes.label}> Full name</Typography>
              <TextField
                fullWidth
                type="text"
                required
                className={classes.input}
                name="name"
                onChange={handleChange}
                value={formValues.name}
                placeholder="Stevel Stallion"
                autoComplete="off"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography className={classes.label}> Contact</Typography>
              <Box display={"flex"}>
          
                <TextField
                  fullWidth
                  type="text"
                  required
                  className={classes.input}
                  name="contact"
                  onChange={handleChange}
                  value={formValues.contact}
                  placeholder="xxxxxxxxx"
                  autoComplete="off"
                />
              </Box>
            </Grid>
          </Grid>
          <Typography className={classes.label}> Email address</Typography>
          <TextField
            fullWidth
            type="email"
            required
            className={classes.input}
            name="email"
            onChange={handleChange}
            value={formValues.email}
            placeholder="Example@gmail.com"
            autoComplete="off"
          />
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 2 }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box sx={{ position: "relative", width: "100%" }}>
                <Typography className={classes.label}> Password</Typography>
                <TextField
                  fullWidth
                  required
                  type={showPassword ? "text" : "password"}
                  className={classes.input}
                  name="password"
                  onChange={handleChange}
                  value={formValues.password}
                  placeholder="******"
                  autoComplete="off"
                />
                <Box
                  sx={{ position: "absolute", bottom: 10, right: 20 }}
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? (
                    <VisibilityIcon sx={{ color: "#fff" }} />
                  ) : (
                    <VisibilityOffIcon sx={{ color: "#fff" }} />
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box sx={{ position: "relative", width: "100%" }}>
                <Typography className={classes.label}>
                  {" "}
                  Confirm Password
                </Typography>
                <TextField
                  fullWidth
                  required
                  type={showPassword ? "text" : "password"}
                  className={classes.input}
                  onChange={(e) => setConfrimPassword(e.target.value)}
                  placeholder="******"
                  value={confirmPassword}
                  autoComplete="off"
                />
                <Box
                  sx={{ position: "absolute", bottom: 10, right: 20 }}
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? (
                    <VisibilityIcon sx={{ color: "#fff" }} />
                  ) : (
                    <VisibilityOffIcon sx={{ color: "#fff" }} />
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Typography className={classes.label}> Organization</Typography>

          <Select
            required
            className={classes.input}
            sx={{ mb: 5 }}
            fullWidth
            id="demo-simple-select"
            value={formValues.organizationId}
            name="organizationId"
            // label="organization"
            onChange={handleChange}
          >
            {org.length < 1
              ? " "
              : org.map((categoryItem) => (
                  <MenuItem key={categoryItem._id} value={categoryItem._id}>
                    {categoryItem.name}
                  </MenuItem>
                ))}
          </Select>

          {/* <VisibilityIcon onClick={handlePasswordVisibility}>
      {showPassword ? <VisibilityOff /> : <Visibility />}
    </VisibilityIcon> */}

          {error && <Alert severity={status}>{message}</Alert>}
          <Box sx={{ mx: 15 }}>
            <Button
              variant="contained"
              fullWidth
              className={classes.btn}
              type="submit"
            >
              Create Account
            </Button>
          </Box>
          <Box display={"flex"} justifyContent={"center"} mt={"5px"}>
            <Typography
              component={Link}
              to="/login"
              sx={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "600",
                fontFamily: "Nunito Sans",
                color: "#FFBC00",
                textDecoration: "none",
              }}
            >
              Back to login
            </Typography>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
};

export default Signup;
