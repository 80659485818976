import React, { useEffect } from "react";
import {
  Box,
  Button,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  Typography,
  Card,
  Grid,
  CardContent,
} from "@mui/material";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { useNavigate } from "react-router";
import MyDialog from "../../components/Dialog/MyDialog";
import Snack from "../../components/snackbar/Snack";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { userUpdate } from "../../store/actions/authActions";
import { useDispatch } from "react-redux";
import { Label } from "@mui/icons-material";
import categoryData from "../../utils/CountryCode";
// const imgpro = require()
const useStyles = makeStyles((theme) => ({
  card: {
    padding: "15px",
    border: "5px solid #fff",
    background: "#746E6E",
    borderRadius: "16px",
    color: "#fff",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
  },
  innerCard: {
    border: "3px solid #F3F3F3",
    padding: "13px, 16px, 13px, 16px",
    background: " linear-gradient(#30344A, #9EA6DD,#34374D)",
    borderRadius: "16px",
    boxShadow: "inset 0px 1px 5px #fff, 0px 0px 5px black",
    color: "#fff",
  },
  input: {
    border: "1px solid yellow",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
    borderRadius: "10px",
    backgroundColor: "#000",
    color: "#fff",
    "& .MuiInputBase-root": {
      backgroundColor: "#000",
      borderRadius: "10px",
      color: "#fff",
    },
    "& fieldset": {
      border: "none",
    },
    "& .MuiSvgIcon-root": {
      color: "#fff",
    }
  },
  label: {
    mb: 1,
    fontSize: "14px",
    fontWeight: 700,
    color: "#fff",
    textShadow: "5px 5px 10px #000",
    marginBlock: "10px",
  },
  btn: {
    height: "45px",
    // width:"511px",
    fontWeight: "700",
    borderRadius: "10px",
    marginTop: "40px",
    color: "#000",
    fontVariant: "small-caps",
    background: "#E3A700",
    border: "2px solid #fff",
    fontSize: "14px",
    boxShadow: "5px 5px 10px #000",
    fontVariant: "small-caps",
    marginBottom: "10px",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#E3A700",
      cursor: "pointer",
    },
  },
  headerText: {
    fontSize: "24px",
    fontWeight: "900",
    fontFamily: "Nunito Sans",
    color: "#FAFF00",
    textShadow:
      "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
  },
  txt: {
    fontSize: "20px",
    fontWeight: "600",
    fontFamily: "Nunito Sans",
    color: "#FAFF00",
    textShadow:
      "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
  },
}));

const isValidContactNumber = (contactNumber) => {
  return /^\d{11}$/.test(contactNumber);
};
const Profile = () => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [dataRes, setDataRes] = useState(null);
  const initialPasswordValues = {
    Oldpassword: "",
    newpassword: "",
    confirmPassword: "",
  };
  console.log("+++++++", user);
  const initialProfileValues = {
    name: user.name,
    email: user ? user.email : "",
    contact: user ? user.contact : "",
    timeZone: user ? user.timeZone : "",
    phoneCode: null,
    role: "admin",
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const fileInputRef = React.useRef(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleBoxClick = () => {
    fileInputRef.current.click();
  };
  const [error, setError] = React.useState(false);
  const [errorPassword, setPasswordError] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [confirmPassword, setConfrimPassword] = React.useState("");
  // snack
  const [response, setResponse] = React.useState("");
  const [severity, setSeverity] = React.useState("");
  const [notifyOpen, setNotifyOpen] = React.useState(false);
  const handleClosePop = () => {
    setNotifyOpen(false);
  };
  const navigate = useNavigate();
  const [formValues, setFormValues] = React.useState(initialProfileValues);
  const [formPasswordValues, setFormPasswordValues] = React.useState(
    initialPasswordValues
  );
  console.log("formPasswordValues", initialPasswordValues);
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("resssss", name);
    setFormValues({ ...formValues, [name]: value });
  };
  const handlePassword_Change = (e) => {
    const { name, value } = e.target;
    console.log("passowd=>", name, "=>", value);
    setFormPasswordValues({ ...formPasswordValues, [name]: value });
  };
  const [showPassword, setShowPassword] = React.useState(false);

  const handleProfileSubmit = async (e) => {
    e.preventDefault();
    // for (const key in formValues) {
    //   if (!formValues[key].trim()) {
    //     setError(true);
    //     return setMessage(`Please fill ${key} data`);
    //   }
    // }

    if (!isValidContactNumber(formValues.contact)) {
      setError(true);
      setMessage("Please enter a valid 11-digit contact number.");
      return;
    }
    setError(false);
    setMessage("");

    // if (formValues.password !== formValues.confirmPassword) {
    //   setError(true);
    //   return setMessage("Passwords do not match.");
    // }

    const body = formValues;

    await axios
      .put(`${process.env.REACT_APP_URL}users/update/${user._id}`, body)
      .then((res) => {
        // console.log(res.data.message);
        dispatch(userUpdate(res.data));
        setResponse(res.data.message);
        // alert(res.data.message)
        setSeverity("success");
        setNotifyOpen(true);
        setFormValues(initialProfileValues);
      })
      .catch((err) => {
        console.log(err);
        setSeverity("danger");
      });
  };
  const handlePasswordChange = async (e) => {
    e.preventDefault();

    // for (const key in formPasswordValues) {
    //   if (!formPasswordValues[key].trim()) {
    // console.log('valiation in trim')
    //     setPasswordError(true);
    //     return setMessage(`Please fill ${key} data`);
    //   }
    // }
    if (formPasswordValues.newpassword?.length < 6) {
      // console.log("valiation in short");
      setPasswordError(true);
      return setMessage("Password should be at least 6 characters long.");
    }
    // console.log(formPasswordValues.newpassword , confirmPassword)
    if (formPasswordValues.newpassword !== confirmPassword) {
      setPasswordError(true);
      setMessage("Passwords do not match.");
      return;
    }

    setPasswordError(false);
    setMessage("");

    // if (formValues.password !== formValues.confirmPassword) {
    //   setError(true);
    //   return setMessage("Passwords do not match.");
    // }
    const body = {
      userId: user._id,
      currentPassword: formPasswordValues.Oldpassword,
      newPassword: formPasswordValues.newpassword,
    };
    console.log(body);
    await axios
      .post(`${process.env.REACT_APP_URL}users/changePassword`, body)
      .then((res) => {
        console.log(res.data.message);
        setResponse(res.data.message);
        // alert(res.data.message)
        setSeverity("success");
        setNotifyOpen(true);
        setFormValues(initialPasswordValues);
      })
      .catch((err) => {
        console.log(err);
        setSeverity("danger");
      });
  };
  const [role, setRole] = React.useState("");
  const classes = useStyles();
  return (
    <div style={{ height: "90vh" }}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 1 }}>
        <Grid item xs={12} lg={6}>
          <Card className={classes.card} sx={{ height: "100%" }}>
            <CardContent>
              <Typography className={classes.headerText} sx={{ mb: 1 }}>
                Edit Profile
              </Typography>

              <Card className={classes.innerCard}>
                <CardContent
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography className={classes.txt}>
                    {formValues.name}
                  </Typography>
                  <Typography className={classes.txt}>
                    {formValues.email}
                  </Typography>
                </CardContent>
              </Card>

              <form onSubmit={handleProfileSubmit}>
                <Stack sx={{ mt: "15px" }}>
                  <InputLabel className={classes.label}>
                    Contact Number
                  </InputLabel>
                  <Box display={"flex"}>
              {/* <FormControl className={classes.input} sx={{marginRight:"10px", width:'120px'}}>
                <Select
                   
                  id="demo-simple-select"
                  value={formValues.phoneCode}
                  name="phoneCode"
                  required
                  // label="Age"
                  onChange={handleChange}
                >
                  {categoryData.map((categoryItem) => (
                    <MenuItem key={categoryItem.dial_code} value={categoryItem.dial_code}>
                      {categoryItem.dial_code}
                    </MenuItem>
                  ))}
                </Select>
             
              </FormControl> */}
                  <TextField
                    // label="Contact Number"
                    name="contact"
                    value={formValues.contact}
                    onChange={handleChange}
                    // required={true}
                    placeholder="03xxxxxxxxx"
                    className={classes.input}
                    sx={{width:'100%'}}
                  />
                  </Box>
                  <InputLabel className={classes.label}>Time Zone</InputLabel>
                  <TextField
                    // label="Time Zone"
                    name="timeZone"
                    value={formValues.timeZone}
                    onChange={handleChange}
                    // required={true}
                    placeholder="US 00:00 UTC"
                    className={classes.input}
                  />
                  <FormControl fullWidth></FormControl>

                  {error && <Alert severity="error">{message}</Alert>}

                  <Button
                    variant="contained"
                    type="submit"
                    className={classes.btn}
                  >
                    {" "}
                    Edit Profile{" "}
                  </Button>
                </Stack>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card className={classes.card} sx={{ height: "100%" }}>
            <CardContent>
              <form onSubmit={handlePasswordChange}>
                <Stack>
                  <Typography className={classes.headerText}>
                    Change Password
                  </Typography>
                  <InputLabel className={classes.label}>
                    Old Password
                  </InputLabel>
                  <TextField
                    // label="Old Password"
                    name="Oldpassword"
                    value={formPasswordValues.Oldpassword}
                    onChange={handlePassword_Change}
                    required={true}
                    placeholder="* * * * * * *"
                    className={classes.input}
                  />
                  <Box sx={{position:'relative',width:'100%' }} >
                  <InputLabel className={classes.label}>New Password</InputLabel>
                  <TextField
                    name="newpassword"
                    fullWidth
                    value={formPasswordValues.newpassword}
                    onChange={handlePassword_Change}
                    required={true}
                    placeholder="* * * * * * *"
                    className={classes.input}
                    type={showPassword ? "text" : "password"}
                
                  />
                    <Box
                    sx={{position:'absolute',bottom:10,right:20}}
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityIcon sx={{ color: "#fff" }} />
                      ) : (
                        <VisibilityOffIcon sx={{ color: "#fff" }} />
                      )}
                    </Box>
                  </Box>
                  <Box sx={{position:'relative',width:'100%' }} >
                  <InputLabel className={classes.label}>
                    Retype New Password
                  </InputLabel>
                  <TextField
                  fullWidth
                    onChange={(e) => {
                      setConfrimPassword(e.target.value);
                    }}
                    required={true}
                    placeholder="* * * * * * *"
                    className={classes.input}
                    type={showPassword ? "text" : "password"}
            
                  />
                  <Box
                    sx={{position:'absolute',bottom:10,right:20}}
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityIcon sx={{ color: "#fff" }} />
                      ) : (
                        <VisibilityOffIcon sx={{ color: "#fff" }} />
                      )}
                    </Box>
                    </Box>

                  {errorPassword && <Alert severity="error">{message}</Alert>}

                  <Button
                    variant="contained"
                    type="submit"
                    className={classes.btn}
                  >
                    {" "}
                    Change Password{" "}
                  </Button>
                </Stack>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Snack
        open={notifyOpen}
        message={response}
        severity={severity}
        handleClose={handleClosePop}
      />
    </div>
  );
};

export default Profile;
