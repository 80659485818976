import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
  Typography,
  Box,
  TableContainer,
  Card,
  Pagination,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";

import Gradients from "../../utils/Gradients";
import { useNavigate } from "react-router";
import axios from "axios";
import MyDialog from "../../components/Dialog/MyDialog";
import Snack from "../../components/snackbar/Snack";
import { useDispatch, useSelector } from "react-redux";
import Searchbar from "../../components/SearchBar/Searchbar";
import CreateIcon from "@mui/icons-material/Create";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import UpdateContract from "./components/UpdateContract";
import CreateContracts from "./components/CreateContract";

const TableHeader = styled(TableHead)(({ theme }) => ({
  "& th": {
    marginButtom: "10px",
    borderBottom: "0px",
    border: "none",
    "&:first-child": {
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
    },
    "&:last-child": {
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "10px",
    },
  },
}));
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  // borderRadius: "10px",
  overflow: "hidden",
}));

const useStyles = makeStyles((theme) => ({
  evenRow: {
    color: "#ffffff",
    backgroundColor: "#787676",
    "&:hover": {
      transform: "scale(1.01)", 
      boxShadow: " 0 0 10px rgba(0, 0, 0, 0.5)", // Add shadow on hover
      backgroundColor: "#6B6B6B",

    },
  },
  oddRow: {
    // backgroundColor: "#6B6B6B",
    backgroundColor: "#787676",
    borderRadius: "20px",
    color: "#ffffff",
    // Odd row color
    "&:hover": {
      transform: "scale(1.01)", 
      boxShadow: " 0 0 10px rgba(0, 0, 0, 0.5)", // Add shadow on hover
      backgroundColor: "#6B6B6B",

    },
  },
  tableCell: {
    color: "#ffffff",
  },
  row: {
    // color: "#ffffff",
    // transition: "transform 0.2s ease-in-out",
    // "&:hover": {
    //   transform: "scale(1.01)", 
    //   boxShadow: " 0 0 10px rgba(0, 0, 0, 0.5)", // Add shadow on hover

    // },
  },
  // Other styles...
  // "@keyframes bounce": {
  //   "0%": {
  //     transform: "scale(1)",
  //   },
  //   "50%": {
  //     transform: "scale(1.1)",
  //   },
  //   "100%": {
  //     transform: "scale(1)",
  //   },
  // },
  cell: {
    fontSize: "1.2rem",
    fontWeight: 500,
    letterSpacing: 1,
    color: "#FFF",
    border: "none",
  },
  search: {
    border: "1px solid #fff",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
    borderRadius: "10px",
    "& .MuiInputBase-root": {
      backgroundColor: "#C9975C",
      borderRadius: "10px",
      color: "#fff",
    },
    "& fieldset": {
      border: "none",
    },
  },
  card: {
    padding: "25px",
    border: "2px solid #fff",
    background: "#787676",
    borderRadius: "16px",
    color: "#fff",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
  },
  btn: {
    borderRadius: "10px",
    marginTop: "10px",
    marginRight: "20px",
    color: "#000",
    fontWeight: "bold",
    fontVariant: "small-caps",
    background: "#E3A700",
    border: "1px solid #fff",
    fontSize: "14px",
    boxShadow: "5px 5px 10px #000",
    fontVariant: "small-caps",
    marginBottom: "10px",

    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#E3A700",
      cursor: "pointer",
    },
  },
  headCell: {
    textShadow: "5px 5px 10px #fff",
    fontSize: "1rem",
    fontWeight: 700,
    color: "#FAFF00",
    letterSpacing: 1,
  },
}));
const Contracts = () => {
  const user = useSelector((state) => state.auth.user);
  const [open, setOpen] = React.useState(false);
  const [delId, setDelId] = React.useState(""); // Store user ID to delete
  const [notifyOpen, setNotifyOpen] = React.useState(false);
  const [singleData, setSingleData] = useState("");
 
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const itemsPerPage = 5;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const [filteredContract, setFilteredContract] = React.useState([]);
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  const handleClickOpenPop = (id) => {
    setDelId(id);
    console.log(id);
    setOpen(true);
  };
  const handleClosePop = () => {
    setOpen(false);
  };

  const navigate = useNavigate();
  const [contracts, setContracts] = useState([]);
  const classes = useStyles();
  const handleDelete = async (id) => {
    setOpen(false);
    console.log("Contract Deleted", id);
    try {
      setNotifyOpen(true);
      const response = await axios.post(
        `${process.env.REACT_APP_URL}contracts/${delId}`
      );

      if (response.status === 200) {
        fetchContracts();
        setTimeout(() => {
          setNotifyOpen(false);
        }, 2000);
      } else {
        console.error("Failed to delete contracts");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpdate = (data) => {
    setSingleData(data);
    console.log("dataaaa",data)
    setUpdateOpenDialog(true);
  };

  async function fetchContracts() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}contracts/all/${user.organization._id}`
      );
      console.log("response", response);
      if (response.status === 200) {
        const totalUsers = response.data.length;
        setTotalPages(Math.ceil(totalUsers / itemsPerPage));

        // Slice the data based on pagination
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;

        setContracts(response.data);
        setFilteredContract(response.data.slice(startIndex, endIndex));
      } else {
        console.error("Failed to fetch contracts");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }
  useEffect(() => {
    // Fetch contracts when the component mounts

    fetchContracts();
  }, [currentPage]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openUpdateDialog, setUpdateOpenDialog] = useState(false);
  const handleDialogOpen = () => {
    setOpenDialog(true);
  };
  const handleDialogClose = (data) => {
    setOpenDialog(data);
    fetchContracts();
  };
  const handleUpdateDialogClose = (data) => {
    setUpdateOpenDialog(data);
    fetchContracts();
  };

  const handleChange = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    const filteredData = contracts.filter((data) => {
      // Check if companyName is defined before accessing its property
      if (data.client && data.client.toLowerCase().includes(searchTerm)) {
        return true;
      }
      return false;
    });
    setFilteredContract(filteredData);
  };
  // if (contracts.length === 0) {
  //   return (
  //     <div className={classes.maindiv}>
  //       <Typography className={classes.maintypo}>Contracts</Typography>
  //       <Typography>No data found</Typography>
  //     </div>
  //   );
  // }
  return (
    <Box sx={{ height: "100vh" }}>
      <Card
        className={classes.card}
        sx={{ height: "600px", position: "relative" }}
      >
        <Searchbar search={classes.search} onChange={handleChange} />

        <StyledTableContainer>
          <Table>
            <TableHeader sx={{ background: Gradients.cApU }}>
              <TableRow>
                <TableCell className={classes.headCell}>Name</TableCell>
                <TableCell className={classes.headCell}>Services</TableCell>
                <TableCell className={classes.headCell}>CCA</TableCell>
                <TableCell className={classes.headCell}>CP</TableCell>
                <TableCell className={classes.headCell}>PA</TableCell>
                <TableCell className={classes.headCell}>REF</TableCell>
                <TableCell className={classes.headCell}>Update</TableCell>
              </TableRow>
            </TableHeader>
            <TableBody sx={{ color: "#fff" }}>
              {filteredContract.map((data, index) => (
                <TableRow
                  key={index}
                  className={`${classes.row} ${index % 2 === 0 ? classes.evenRow : classes.oddRow}`}
                >
                  <TableCell className={classes.cell}>
                    <Typography>{data.client}</Typography>
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {data.services}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {data.campaign === "CCA" ? (
                      <Avatar
                        src="/assets/images/tt.png"
                        sx={{
                          width: "30px",
                          height: "30px",
                          border: "1px solid #1392FF",
                          boxShadow: "-1px -2px 15px #1392FF",
                        }}
                      />
                    ) : (
                      <Box
                        sizes="sm"
                        sx={{
                          width: "30px",
                          height: "30px",
                          border: "3px solid #000",
                          borderRadius: "50px",
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {data.campaign === "CP" ? (
                      <Avatar
                        src="/assets/images/tt.png"
                        sx={{
                          width: "30px",
                          height: "30px",
                          border: "1px solid #1392FF",
                          boxShadow: "-1px -2px 15px #1392FF",
                        }}
                      />
                    ) : (
                      <Box
                        sizes="sm"
                        sx={{
                          width: "30px",
                          height: "30px",
                          border: "3px solid #000",
                          borderRadius: "50px",
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {data.campaign === "PA" ? (
                      <Avatar
                        src="/assets/images/tt.png"
                        sx={{
                          width: "30px",
                          height: "30px",
                          border: "1px solid #1392FF",
                          boxShadow: "-1px -2px 15px #1392FF",
                        }}
                      />
                    ) : (
                      <Box
                        sizes="sm"
                        sx={{
                          width: "30px",
                          height: "30px",
                          border: "3px solid #000",
                          borderRadius: "50px",
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {data.campaign === "REF" ? (
                      <Avatar
                        src="/assets/images/tt.png"
                        sx={{
                          width: "30px",
                          height: "30px",
                          border: "1px solid #1392FF",
                          boxShadow: "-1px -2px 15px #1392FF",
                        }}
                      />
                    ) : (
                      <Box
                        sizes="sm"
                        sx={{
                          width: "30px",
                          height: "30px",
                          border: "3px solid #000",
                          borderRadius: "50px",
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    <Box sx={{ display: "flex" }}>
                      <Avatar
                        sx={{
                          color: "#000",
                          background: "#E3A700",
                          boxShadow: "5px 5px 10px #000",
                          border: "1px solid #fff",
                        }}
                        onClick={() => handleUpdate(data)}
                      >
                        <CreateIcon sx={{ color: "#000" }} />
                      </Avatar>

                      <Avatar
                        sizes="sm"
                        sx={{
                          color: "#E3A700",
                          background: "#404040",
                          marginLeft: "10px",
                          boxShadow: "5px 5px 10px #000 ",
                          border: "1px solid #fff",
                        }}
                        onClick={() => handleClickOpenPop(data._id)}
                      >
                        <DeleteForeverIcon fontSize="sm" />
                      </Avatar>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        
        </StyledTableContainer>
        <Dialog
            fullWidth
            maxWidth="lg"
            PaperProps={{
              style: {
                background: " linear-gradient(#4F3B03, #E3A700,#503D06)",
                border: "3px solid #fff",
                borderRadius: "24px",
              },
            }}
            open={openUpdateDialog}
            onClose={handleUpdateDialogClose}
          >
            <DialogTitle
              sx={{
                color: "#FAFF00",
                textShadow:
                  "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
              }}
            >
              Update Contract
            </DialogTitle>
            <DialogContent>
              <UpdateContract
                closeDialog={handleUpdateDialogClose}
                state={singleData}
              />
            </DialogContent>
          </Dialog>
        <Dialog
          fullWidth
          maxWidth="lg"
          PaperProps={{
            style: {
              background: " linear-gradient(#4F3B03, #E3A700,#503D06)",
              border: "3px solid #fff",
              borderRadius: "24px",
            },
          }}
          open={openDialog}
          onClose={handleDialogClose}
        >
          <DialogTitle
            sx={{
              color: "#FAFF00",
              textShadow:
                "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
            }}
          >
            Upload Contract
          </DialogTitle>
          <DialogContent>
            <CreateContracts closeDialog={handleDialogClose} />
          </DialogContent>
        </Dialog>
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Pagination
            count={totalPages}
            // count={10}
            page={currentPage}
            onChange={(event, page) => setCurrentPage(page)}
            color="primary"
            sx={{
              position: "absolute",
              bottom: 20,
              "& .MuiPaginationItem-root": {
                background: "#A4A4A4",
                border: "1px solid #fff",
                boxShadow: "5px 5px 10px #000",
                color: "#000",
                "&:hover": {
                  color: "#FFF",
                  background: "#E1A800",
                },
                "&.Mui-selected": {
                  background: "#E1A800",
                },
              },

              "& .MuiPaginationItem-icon": {
                color: "#000", // Set color of the pagination icon to white
              },
            }}
          />
        </Box>
        <MyDialog
          open={open}
          handleClose={handleClosePop}
          handleDelete={handleDelete}
        />

        <Snack
          open={notifyOpen}
          message={"Contract Deleted Successfully"}
          severity="success"
          handleClose={handleClosePop}
        />
      </Card>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button className={classes.btn} onClick={handleDialogOpen}>
          + Upload Contract
        </Button>
      </Box>
    </Box>
  );
};

export default Contracts;
