import React, { useState, useRef ,useEffect} from "react";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import Files from "./components/Files";
import UploadFile from "./components/UploadFile";
import { useSelector } from "react-redux/es/hooks/useSelector";
const useStyles = makeStyles((theme) => ({
  maintypo: {
    display: "flex",
    justifyContent: "center",
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
    color: "White",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    letterSpacing: "-0.114px",
    textDecoration: "none !important",
    marginBottom: "15px",
    letterSpacing: "1.5px",
  },
  Card: {
    border: "2px solid #E3A700",
    borderRadius: "16px",
    background: "#000",
    textAlign: "center",
  },
  typoHead: { fontSize: "20px", fontFamily: "Nunito Sans", color: "#fff" },
  typoHead2: {
    fontSize: "28px",
    fontFamily: "Nunito Sans",
    color: "#fff",
    marginTop: "20px",
  },
  typoBody: { fontSize: "14px", fontFamily: "Nunito Sans", color: "#787676" },
  btn: {
    border: "2px solid #fff",
    borderRadius: "8px",
    color: "#E3A700",
    width: "100%",
    height: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none",
  },
  img: {
    marginTop: "30px",
    width: "77px",
    height: "77px",
  },
  cardmain: {
    padding: "25px",
    border: "2px solid #fff",
    background: "#9A9A9A",
    borderRadius: "16px",
    color: "#fff",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
  },
  createbtn: {
    borderRadius: "10px",
    marginTop: "10px",
    marginRight: "20px",
    color: "#000",
    fontWeight: "bold",
    fontVariant: "small-caps",
    background: "#E3A700",
    border: "1px solid #fff",
    fontSize: "14px",
    boxShadow: "5px 5px 10px #000",
    fontVariant: "small-caps",
    marginBottom: "10px",

    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#E3A700",
      cursor: "pointer",
    },
  },
}));

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      // style={{ background: "#208ABB" }}
      style={{
        backgroundColor: "#208ABB",
        borderRadius: "50px",
        border: "none",
        scale: "1.5",
      }}
      onClick={onClick}
    >
      {/* <ArrowForwardIcon sx={{color:'#208ABB', fontSize:'2rem'}}/> */}N
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        backgroundColor: "#208ABB",
        borderRadius: "50px",
        border: "none",
        scale: "1.5",
      }}
      onClick={onClick}
    >
      P{/* <ArrowBackIcon sx={{color:'#208ABB', fontSize:'2rem'}}/> */}
    </div>
  );
}

function Library() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    afterChange: (current) => {
      setCurrentSlide(current);
    },
  };
  const user = useSelector((state) => state.auth.user);
  const [lib ,setLib] =useState([])
  const getData = async ()=>{
    axios
    .post(
      `${process.env.REACT_APP_URL}api/files/stats/${user.organization._id}`
    )
    .then((res) => {
      setLib(res.data)

      // getData(formData.companyName,formData.date)
    });
  }
  useEffect(() => {
    getData()
  }, []);

  const slideLinks = [
    "images", // Link for the first slide
    "videos", // Link for the second slide
    "files", // Link for the third slide
  ];
  const [openDialog, setOpenDialog] = useState(false);
  const handleDialogClose = (data) => {
    setOpenDialog(data);
    getData()

  };
  const handleDialogOpen = () => {
    setOpenDialog(true);
  };
  let links = slideLinks[currentSlide];
  console.log(links.toString());
  const classes = useStyles();
  return (
    <div style={{height:'100vh'}} >
      <Card className={classes.cardmain}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 1 }}>
          <Grid item xs={12} md={4} lg={4}>
            <Card className={classes.Card}>
              <CardContent>
                <img className={classes.img} src="/assets/images/file.png" />
                <Typography className={classes.typoHead}>Images</Typography>
                <Typography className={classes.typoBody}>{lib.totalImages} Images</Typography>
              </CardContent>
              <CardActions>
                <Link to={slideLinks[0]} className={classes.btn}>
                  View
                </Link>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Card className={classes.Card}>
              <CardContent>
                <img className={classes.img} src="/assets/images/file.png" />
                <Typography className={classes.typoHead}>Videos</Typography>
                <Typography className={classes.typoBody}>{lib.totalVideos} Videos</Typography>
              </CardContent>
              <CardActions>
                <Link to={slideLinks[1]} className={classes.btn}>
                  View
                </Link>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Card className={classes.Card}>
              <CardContent>
                <img className={classes.img} src="/assets/images/file.png" />
                <Typography className={classes.typoHead}>Files</Typography>
                <Typography className={classes.typoBody}>{lib.totalFiles} files</Typography>
              </CardContent>
              <CardActions>
                <Link to={slideLinks[2]} className={classes.btn}>
                  View
                </Link>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
        <Typography className={classes.typoHead2}>Recent Files</Typography>
      </Card>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button className={classes.createbtn} onClick={handleDialogOpen}>
          + Upload File
        </Button>
      </Box>
      <Dialog
        fullWidth
        maxWidth="lg"
        PaperProps={{
          style: {
            background: " linear-gradient(#4F3B03, #E3A700,#503D06)",
            border: "3px solid #fff",
            borderRadius: "24px",
          },
        }}
        open={openDialog}
        onClose={handleDialogClose}
      >
        <DialogTitle
          sx={{
            color: "#FAFF00",
            textShadow:
              "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
          }}
        >
          Add file
        </DialogTitle>
        <DialogContent>
          <UploadFile closeDialog={handleDialogClose} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Library;
